/**
 * @generated SignedSource<<09d3470b5f6c45d5b8f10e70ce479b8c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LoadMutationProgressQuery$variables = {
  block: number;
  profileId: string;
  skipInvoiceTotals: boolean;
  startingYear: number;
};
export type LoadMutationProgressQuery$data = {
  readonly profile: {
    readonly id?: string;
    readonly profileGroup: {
      readonly bulkUploadNewRegistersProgress: number | null;
      readonly generateDraftsProgress: number | null;
      readonly generateGoogleRegistersProgress: number | null;
      readonly generateRegistersForNewBlockProgress: number | null;
      readonly id: string;
      readonly matchPaymentsProgress: number | null;
      readonly readInRegistersProgress: number | null;
      readonly sendBulkCommunicationProgress: number | null;
      readonly sendDraftsProgress: number | null;
      readonly sendRemindersProgress: number | null;
      readonly sendTeacherBulkCommunicationProgress: number | null;
    } | null;
    readonly totalAmountPaid?: number | null;
    readonly totalInvoicesSent?: number | null;
    readonly totalInvoicesUnpaid: number | null;
  } | null;
};
export type LoadMutationProgressQuery = {
  response: LoadMutationProgressQuery$data;
  variables: LoadMutationProgressQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "block"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "profileId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipInvoiceTotals"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startingYear"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "profileId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "block",
    "variableName": "block"
  },
  {
    "kind": "Variable",
    "name": "startingYear",
    "variableName": "startingYear"
  }
],
v7 = {
  "alias": null,
  "args": (v6/*: any*/),
  "kind": "ScalarField",
  "name": "totalInvoicesSent",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": (v6/*: any*/),
  "kind": "ScalarField",
  "name": "totalAmountPaid",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": (v6/*: any*/),
  "kind": "ScalarField",
  "name": "totalInvoicesUnpaid",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "ProfileGroupNode",
  "kind": "LinkedField",
  "name": "profileGroup",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "generateDraftsProgress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sendDraftsProgress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sendRemindersProgress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "matchPaymentsProgress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "generateGoogleRegistersProgress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "readInRegistersProgress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bulkUploadNewRegistersProgress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "generateRegistersForNewBlockProgress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sendBulkCommunicationProgress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sendTeacherBulkCommunicationProgress",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoadMutationProgressQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ProfileNode",
        "kind": "LinkedField",
        "name": "profile",
        "plural": false,
        "selections": [
          {
            "condition": "skipInvoiceTotals",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              (v5/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ]
          },
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "LoadMutationProgressQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ProfileNode",
        "kind": "LinkedField",
        "name": "profile",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v10/*: any*/),
          (v5/*: any*/),
          {
            "condition": "skipInvoiceTotals",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/)
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "74fe05004a0baf6e5b05e2485dd65030",
    "id": null,
    "metadata": {},
    "name": "LoadMutationProgressQuery",
    "operationKind": "query",
    "text": "query LoadMutationProgressQuery(\n  $profileId: ID!\n  $startingYear: Int!\n  $block: Int!\n  $skipInvoiceTotals: Boolean!\n) {\n  profile(id: $profileId) {\n    id @skip(if: $skipInvoiceTotals)\n    totalInvoicesSent(startingYear: $startingYear, block: $block) @skip(if: $skipInvoiceTotals)\n    totalAmountPaid(startingYear: $startingYear, block: $block) @skip(if: $skipInvoiceTotals)\n    totalInvoicesUnpaid(startingYear: $startingYear, block: $block)\n    profileGroup {\n      id\n      generateDraftsProgress\n      sendDraftsProgress\n      sendRemindersProgress\n      matchPaymentsProgress\n      generateGoogleRegistersProgress\n      readInRegistersProgress\n      bulkUploadNewRegistersProgress\n      generateRegistersForNewBlockProgress\n      sendBulkCommunicationProgress\n      sendTeacherBulkCommunicationProgress\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "cb208f1f5be6093dbcba45cf9cc4fa79";

export default node;
