import { graphql } from "react-relay";

export const load_invoice = graphql`
    query LoadInvoiceQuery(
        $invoiceId: ID
        $profileId: ID
        $startingYear: Int
        $block: Int
        $skip: Boolean!
    ) {
        invoice(
            invoiceId: $invoiceId
            profileId: $profileId
            startingYear: $startingYear
            block: $block
        ) @skip(if: $skip) {
            id
            invoiceNumber
            createdOn
            lastModified
            totalAmount
            isUpdate
            amountPaid
            status
            notes
            staffNotes
            startingYear
            block
            assignedToFullName
            assignedToEmail
            assignedToPhoneNumber
            assignedToAccountNumber
            invoiceEmailSubject
            invoiceMessageString
            invoicePdfSignedUrl
            initialSentTimestamp
            reminder1SentTimestamp
            reminder2SentTimestamp
            reminder3SentTimestamp
            assignedTo {
                id
                accountNumber
                user {
                    id
                    email
                    firstName
                    lastName
                }
            }
            createdBy {
                user {
                    id
                    email
                    firstName
                    lastName
                }
            }
            lineItems {
                __id
                edges {
                    node {
                        id
                        itemType
                        quantity
                        unitAmount
                        timePeriodDescription
                        pupilDescription
                        pupilDescriptionId
                        instrumentDescription
                        typeDescription
                        pupil {
                            id
                        }
                    }
                }
            }
            payments {
                __id
                edges {
                    node {
                        id
                        amount
                        paymentType
                        paymentReference
                        paymentDate
                        paymentNote
                    }
                }
            }
            otherInvoicesForBlock {
                __id
                edges {
                    node {
                        id
                        invoiceNumber
                        status
                        totalAmount
                        createdOn
                    }
                }
            }
            lessonBlocks {
                edges {
                    node {
                        id
                        instrument {
                            name
                        }
                        pupil {
                            id
                            user {
                                firstName
                                lastName
                            }
                        }
                        lessons {
                            totalCount
                        }
                        examFee
                        lessonStage {
                            lessonType
                            lessonDuration
                            costPerLesson
                        }
                    }
                }
            }
        }
        accountFeeOrCredit(
            profileId: $profileId
            startingYear: $startingYear
            block: $block
        ) @skip(if: $skip) {
            id
            amount
        }
    }
`;
