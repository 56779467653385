/**
 * @generated SignedSource<<9f393b9ffa6e7ebb21092e7cb3f7253b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ProfileModelAccountType = "HEAD_TEACHER" | "PARENT" | "PUPIL" | "SCHOOL_CONTACT" | "SCHOOL_TEACHER" | "STAFF" | "TEACHER" | "%future added value";
export type MeQuery$variables = {};
export type MeQuery$data = {
  readonly me: {
    readonly dateJoined: string;
    readonly email: string;
    readonly firstName: string;
    readonly hasPassword: boolean | null;
    readonly id: string;
    readonly lastName: string;
    readonly profile: {
      readonly accountType: ProfileModelAccountType;
      readonly currentBlock: number | null;
      readonly id: string;
      readonly profileGroup: {
        readonly bulkUploadNewRegistersProgress: number | null;
        readonly currentStartingYear: number | null;
        readonly estimatedTotalAmountUnpaid: number | null;
        readonly generateDraftsProgress: number | null;
        readonly generateGoogleRegistersProgress: number | null;
        readonly generateRegistersForNewBlockProgress: number | null;
        readonly generatedEstimatedPayoutTotalFromDate: string | null;
        readonly generatedEstimatedPayoutTotalToDate: string | null;
        readonly id: string;
        readonly matchPaymentsProgress: number | null;
        readonly readInRegistersProgress: number | null;
        readonly sendBulkCommunicationProgress: number | null;
        readonly sendDraftsProgress: number | null;
        readonly sendRemindersProgress: number | null;
        readonly sendTeacherBulkCommunicationProgress: number | null;
      } | null;
      readonly school: {
        readonly id: string;
        readonly name: string;
      } | null;
      readonly schoolYear: number | null;
      readonly schools: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      } | null> | null;
    } | null;
    readonly verified: boolean | null;
  } | null;
};
export type MeQuery = {
  response: MeQuery$data;
  variables: MeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DjangoUserNode",
    "kind": "LinkedField",
    "name": "me",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "verified",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasPassword",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dateJoined",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProfileNode",
        "kind": "LinkedField",
        "name": "profile",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "schoolYear",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentBlock",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SchoolNode",
            "kind": "LinkedField",
            "name": "schools",
            "plural": true,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SchoolNode",
            "kind": "LinkedField",
            "name": "school",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileGroupNode",
            "kind": "LinkedField",
            "name": "profileGroup",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currentStartingYear",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "generateDraftsProgress",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sendDraftsProgress",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sendRemindersProgress",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "matchPaymentsProgress",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "generateGoogleRegistersProgress",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "readInRegistersProgress",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bulkUploadNewRegistersProgress",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "generateRegistersForNewBlockProgress",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sendBulkCommunicationProgress",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sendTeacherBulkCommunicationProgress",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "estimatedTotalAmountUnpaid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "generatedEstimatedPayoutTotalFromDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "generatedEstimatedPayoutTotalToDate",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MeQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MeQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "f05c4eaba6a13ce3990ee461803c252c",
    "id": null,
    "metadata": {},
    "name": "MeQuery",
    "operationKind": "query",
    "text": "query MeQuery {\n  me {\n    id\n    firstName\n    lastName\n    email\n    verified\n    hasPassword\n    dateJoined\n    profile {\n      id\n      accountType\n      schoolYear\n      currentBlock\n      schools {\n        id\n        name\n      }\n      school {\n        id\n        name\n      }\n      profileGroup {\n        id\n        currentStartingYear\n        generateDraftsProgress\n        sendDraftsProgress\n        sendRemindersProgress\n        matchPaymentsProgress\n        generateGoogleRegistersProgress\n        readInRegistersProgress\n        bulkUploadNewRegistersProgress\n        generateRegistersForNewBlockProgress\n        sendBulkCommunicationProgress\n        sendTeacherBulkCommunicationProgress\n        estimatedTotalAmountUnpaid\n        generatedEstimatedPayoutTotalFromDate\n        generatedEstimatedPayoutTotalToDate\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ee2150dfbace73c7d6c68e23e301e156";

export default node;
