import React, {
    useMemo,
    useReducer,
    useEffect,
    useCallback,
    useRef,
    Suspense,
} from "react";
import type { FC, Dispatch, ReactElement } from "react";

import type { NavigationAction } from "@react-navigation/native";
import { format } from "date-fns";
import emoji from "emoji-dictionary";
import {
    Box,
    ScrollView,
    Text,
    VStack,
    Button,
    Avatar,
    Pressable,
    useToast,
    useDisclose,
    HStack,
    CloseIcon,
    Center,
    Divider,
} from "native-base";
import { useBeforeunload } from "react-beforeunload";
import { DatePickerModal } from "react-native-paper-dates";
import {
    usePreloadedQuery,
    useMutation,
    useRelayEnvironment,
} from "react-relay";
import type {
    PreloadedQuery,
    UseQueryLoaderLoadQueryOptions,
} from "react-relay";
import { fetchQuery } from "relay-runtime";
import type { Subscription, RecordSourceSelectorProxy } from "relay-runtime";

import type {
    ReducerValues as AccountScreenReducerValues,
    ReducerTypes as AccountScreenReducerTypes,
    NavigationProps as AccountScreenNavigationProps,
    RouteProps as AccountScreenRouteProps,
} from "../../screens/accounts/AccountScreen";
import type {
    NavigationProps as InvoiceScreenNavigationProps,
    RouteProps as InvoiceScreenRouteProps,
} from "../../screens/invoicing/InvoiceScreen";
import SideBar from "../Drawers/SideBar";
import InvoiceStatusActionsheet from "../Modals/InvoiceStatusActionsheet";
import UpdateOrCreatePaymentModal from "../Modals/UpdateOrCreatePaymentModal";
import type { PaymentModalDetails } from "../Modals/UpdateOrCreatePaymentModal";
import LoadingBlobs from "pianofunclub-shared/components/Animations/LoadingBlobs";
import ProblemSplash from "pianofunclub-shared/components/Base/ProblemSplash";
import ButtonDebounced from "pianofunclub-shared/components/Buttons/ButtonDebounced";
import TopTabBar from "pianofunclub-shared/components/Buttons/TopTabBar";
import CurrencyInput from "pianofunclub-shared/components/Inputs/CurrencyInput";
import TextInput from "pianofunclub-shared/components/Inputs/TextInput";
import ListEmptyBanner from "pianofunclub-shared/components/ListItems/ListEmptyBanner";
import AlertPopup from "pianofunclub-shared/components/NativeBaseExtended/AlertPopup";
import Select from "pianofunclub-shared/components/NativeBaseExtended/Select";
import ToastAlert from "pianofunclub-shared/components/NativeBaseExtended/ToastAlert";
import {
    DraftFilledIcon,
    InvoiceIcon,
    RestartIcon,
} from "pianofunclub-shared/components/Other/Icons";

import type {
    DeleteDraftInvoiceMutation,
    DeleteDraftInvoiceMutation$data,
} from "pianofunclub-shared/relay/graphql/invoicing/__generated__/DeleteDraftInvoiceMutation.graphql";
import type {
    GenerateOrUpdateDraftInvoiceMutation,
    GenerateOrUpdateDraftInvoiceMutation$data,
} from "pianofunclub-shared/relay/graphql/invoicing/__generated__/GenerateOrUpdateDraftInvoiceMutation.graphql";
import type {
    LoadInvoiceQuery,
    LoadInvoiceQuery$data,
    LoadInvoiceQuery$variables,
} from "pianofunclub-shared/relay/graphql/invoicing/__generated__/LoadInvoiceQuery.graphql";
import type {
    RemovePaymentMutation,
    RemovePaymentMutation$data,
} from "pianofunclub-shared/relay/graphql/invoicing/__generated__/RemovePaymentMutation.graphql";
import type {
    SendReminderForUnpaidInvoiceMutation,
    SendReminderForUnpaidInvoiceMutation$data,
} from "pianofunclub-shared/relay/graphql/invoicing/__generated__/SendReminderForUnpaidInvoiceMutation.graphql";
import type {
    UpdateAccountFeeOrCreditMutation,
    UpdateAccountFeeOrCreditMutation$data,
} from "pianofunclub-shared/relay/graphql/invoicing/__generated__/UpdateAccountFeeOrCreditMutation.graphql";
import type {
    UpdateInvoiceMessageMutation,
    UpdateInvoiceMessageMutation$data,
} from "pianofunclub-shared/relay/graphql/invoicing/__generated__/UpdateInvoiceMessageMutation.graphql";
import type {
    UpdateInvoiceStatusMutation,
    UpdateInvoiceStatusMutation$data,
} from "pianofunclub-shared/relay/graphql/invoicing/__generated__/UpdateInvoiceStatusMutation.graphql";
import { delete_draft_invoice } from "pianofunclub-shared/relay/graphql/invoicing/DeleteDraftInvoice";
import { generate_or_update_draft_invoice } from "pianofunclub-shared/relay/graphql/invoicing/GenerateOrUpdateDraftInvoice";
import { load_invoice } from "pianofunclub-shared/relay/graphql/invoicing/LoadInvoice";
import { remove_payment } from "pianofunclub-shared/relay/graphql/invoicing/RemovePayment";
import { send_reminder_for_unpaid_invoice } from "pianofunclub-shared/relay/graphql/invoicing/SendReminderForUnpaidInvoice";
import { update_account_fee_or_credit } from "pianofunclub-shared/relay/graphql/invoicing/UpdateAccountFeeOrCredit";
import { update_invoice_message } from "pianofunclub-shared/relay/graphql/invoicing/UpdateInvoiceMessage";
import { update_invoice_status } from "pianofunclub-shared/relay/graphql/invoicing/UpdateInvoiceStatus";

import { isNavigationAction } from "pianofunclub-shared/types/guards";
import { BLOCKS } from "pianofunclub-shared/utils/constants";
import {
    getInitials,
    isNumeric,
    titleCaseConverter,
} from "pianofunclub-shared/utils/converters";
import { getFullName } from "pianofunclub-shared/utils/extractors";
import { createReducer } from "pianofunclub-shared/utils/reducers";
import type { Action, State } from "pianofunclub-shared/utils/reducers";

import Invoice from "./Invoice";
import InvoiceStatus from "./InvoiceStatus";

type NavigationProps = AccountScreenNavigationProps &
    InvoiceScreenNavigationProps;
type RouteProps = AccountScreenRouteProps | InvoiceScreenRouteProps;

interface Props {
    accountScreenState?: State<AccountScreenReducerValues>;
    dispatchAccountScreenState?: Dispatch<
        Action<AccountScreenReducerValues, AccountScreenReducerTypes>
    >;
    firstName?: string;
    isAccountScreen?: boolean;
    isEditable: boolean;
    lastName?: string;
    loadInvoiceQuery: (
        variables: LoadInvoiceQuery$variables,
        options?: UseQueryLoaderLoadQueryOptions | undefined,
    ) => void;
    loadInvoiceQueryReference:
        | PreloadedQuery<LoadInvoiceQuery, Record<string, unknown>>
        | undefined
        | null;
    navigation: NavigationProps;
    previousOtherInvoicesConnectionIds?: string[];
    profileId?: string;
    route: RouteProps;
    startingYears?: {
        label: string;
        value: number;
    }[];
}

interface ContentProps extends Props {
    dispatchState: Dispatch<Action<ReducerValues, ReducerTypes>>;
    loadInvoiceQueryReference: PreloadedQuery<
        LoadInvoiceQuery,
        Record<string, unknown>
    >;
    state: State<ReducerValues>;
}

type OtherInvoiceAlertOptions = {
    draftInvoiceEmail?: boolean;
    otherInvoiceId?: string;
    sendInvoice?: boolean;
};

export type ReducerValues = {
    activeLineItemId?: string;
    cancelAlertIsOpen: boolean;
    clickedPaymentId?: string;
    currentPageIndex: number;
    datePickerIsOpen: boolean;
    datePickerType?: "PAYMENT_DATE" | "SENT_DATE";
    draftAlertInvoiceId?: string;
    draftAlertIsOpen: boolean;
    invoiceData?: LoadInvoiceQuery$data;
    invoiceIsEdited: boolean;
    isInvoiceSendButtonPress: boolean;
    isSideBarPress: boolean;
    leaveAlertAction?: NavigationAction | (() => void);
    otherInvoiceAlertIsOpen: boolean;
    otherInvoiceAlertOptions?: OtherInvoiceAlertOptions;
    paymentModalDetails?: PaymentModalDetails;
    searchTerm: string;
    sendAlertIsOpen: boolean;
    sendReminderAlertIsOpen: boolean;
    showPaymentModal: boolean;
    sideBarIsCollapsed: boolean;
};

export type ReducerTypes =
    | string
    | boolean
    | number
    | PaymentModalDetails
    | OtherInvoiceAlertOptions
    | NavigationAction
    | (() => void)
    | LoadInvoiceQuery$data
    | undefined;

export const SIDE_BAR_EXPANDED_WIDTH = 400;
export const SIDE_BAR_COLLAPSED_WIDTH = 12;

const InvoiceAndEmailContent: FC<ContentProps> = (props) => {
    const {
        accountScreenState,
        dispatchAccountScreenState,
        dispatchState,
        firstName,
        isAccountScreen,
        isEditable,
        lastName,
        loadInvoiceQuery,
        loadInvoiceQueryReference,
        navigation,
        previousOtherInvoicesConnectionIds,
        profileId,
        state,
    } = props;

    const invoiceData = usePreloadedQuery(
        load_invoice,
        loadInvoiceQueryReference,
    );

    useEffect(() => {
        dispatchState({ input: "invoiceData", value: invoiceData });
    }, [dispatchState, invoiceData]);

    const refreshHandler = useCallback(
        (input?: { invoiceId: string }) => {
            if (input?.invoiceId || accountScreenState?.values.invoiceId) {
                loadInvoiceQuery(
                    {
                        invoiceId:
                            input?.invoiceId ??
                            (accountScreenState?.values.invoiceId as string),
                        skip: false,
                    },
                    { fetchPolicy: "network-only" },
                );
            } else if (
                accountScreenState?.values.startingYear &&
                accountScreenState?.values.block &&
                profileId
            )
                loadInvoiceQuery(
                    {
                        profileId: profileId,
                        startingYear: accountScreenState?.values.startingYear,
                        block: accountScreenState?.values.block,
                        skip: false,
                    },
                    { fetchPolicy: "network-only" },
                );
        },
        [
            accountScreenState?.values.block,
            accountScreenState?.values.invoiceId,
            accountScreenState?.values.startingYear,
            loadInvoiceQuery,
            profileId,
        ],
    );

    const userHasEmail = useMemo(() => {
        return Boolean(invoiceData.invoice?.assignedTo?.user.email);
    }, [invoiceData.invoice?.assignedTo?.user.email]);

    const paymentsConnectionId = useMemo(() => {
        return invoiceData.invoice?.payments.__id;
    }, [invoiceData.invoice?.payments.__id]);

    const otherInvoicesConnectionIds = useMemo(() => {
        if (invoiceData.invoice?.otherInvoicesForBlock.__id) {
            return (previousOtherInvoicesConnectionIds ?? []).concat(
                invoiceData.invoice?.otherInvoicesForBlock.__id,
            );
        } else {
            return previousOtherInvoicesConnectionIds ?? [];
        }
    }, [
        invoiceData.invoice?.otherInvoicesForBlock.__id,
        previousOtherInvoicesConnectionIds,
    ]);

    const otherInvoicesForBlock = useMemo(() => {
        return (
            invoiceData.invoice?.otherInvoicesForBlock.edges.filter(
                (item) => item?.node?.id !== invoiceData.invoice?.id,
            ) ?? []
        );
    }, [
        invoiceData.invoice?.id,
        invoiceData.invoice?.otherInvoicesForBlock.edges,
    ]);

    // stop refetch useEffects firing on first render
    const renderCount = useRef(0);
    const environment = useRelayEnvironment();
    const invoiceCreditInputRef = useRef<{
        setValue: (value: number, isValid: boolean) => void;
    }>(null);

    const refetchInvoice = useCallback(
        (variables: {
            block: number;
            startingYear: number;
        }): Subscription | undefined => {
            if (dispatchAccountScreenState) {
                dispatchAccountScreenState({
                    input: "isRefetching",
                    value: true,
                });

                const fullVariables = {
                    ...variables,
                    profileId: profileId,
                    skip: false,
                };

                const subscription = fetchQuery(
                    environment,
                    load_invoice,
                    fullVariables,
                    { fetchPolicy: "store-or-network" },
                ).subscribe({
                    complete: () => {
                        dispatchAccountScreenState({
                            input: "isRefetching",
                            value: false,
                        });
                        loadInvoiceQuery(fullVariables, {
                            // @ts-expect-error relay typing error
                            fetchPolicy: "store-only",
                        });
                    },
                    error: () => {
                        dispatchAccountScreenState({
                            input: "isRefetching",
                            value: false,
                        });
                    },
                });
                return subscription;
            }
        },
        [dispatchAccountScreenState, environment, loadInvoiceQuery, profileId],
    );

    const refetchAccountFeeOrCreditHandler = useCallback(() => {
        if (
            dispatchAccountScreenState &&
            accountScreenState?.values.startingYear &&
            accountScreenState?.values.block &&
            profileId
        ) {
            const variables = {
                profileId: profileId,
                startingYear: accountScreenState?.values.startingYear,
                block: accountScreenState?.values.block,
                skip: false,
            };

            // unable tor refetch only the account fee or credit (when used as a fragment)
            // so just refetch the whole thin
            fetchQuery<LoadInvoiceQuery>(environment, load_invoice, variables, {
                fetchPolicy: "network-only",
            }).subscribe({
                complete: () => {
                    loadInvoiceQuery(variables, {
                        // @ts-expect-error relay typing error
                        fetchPolicy: "store-only",
                    });
                },
            });
        }
    }, [
        accountScreenState?.values.block,
        accountScreenState?.values.startingYear,
        dispatchAccountScreenState,
        environment,
        loadInvoiceQuery,
        profileId,
    ]);

    useEffect(() => {
        if (renderCount.current < 2) {
            renderCount.current += 1;
            return;
        }

        if (dispatchAccountScreenState && accountScreenState) {
            dispatchAccountScreenState({ input: "isRefetching", value: false });
            accountScreenState.values.subscription?.unsubscribe();

            dispatchAccountScreenState({
                input: "subscription",
                value: refetchInvoice({
                    startingYear: accountScreenState.values.startingYear,
                    block: accountScreenState.values.block,
                }),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        accountScreenState?.values.startingYear,
        accountScreenState?.values.block,
    ]);

    useEffect(() => {
        if (renderCount.current < 2) {
            renderCount.current += 1;
            return;
        }

        invoiceCreditInputRef.current?.setValue(
            invoiceData.accountFeeOrCredit?.amount
                ? -Number(invoiceData.accountFeeOrCredit.amount)
                : 0,
            true,
        );
    }, [invoiceData.accountFeeOrCredit?.amount]);

    const toast = useToast();
    const {
        isOpen: changeStatusActionsheetIsOpen,
        onClose: changeStatusActionsheetOnClose,
        onOpen: changeStatusActionsheetOnOpen,
    } = useDisclose();
    const paymentDateInputRef = useRef<{
        blur: () => void;
        setValue: (text: string, isValid: boolean) => void;
    }>(null);
    const invoiceEmailSubjectRef = useRef<{
        setValue: (text: string, isValid: boolean) => void;
    }>(null);
    const invoiceMessageStringRef = useRef<{
        setValue: (text: string, isValid: boolean) => void;
    }>(null);
    // this is to force inputs on modal to re-render
    const paymentModalKey = useRef(0);
    const safeDraftAlertRef = useRef(null);
    const safeCancelAlertRef = useRef(null);
    const safeOtherInvoiceAlertRef = useRef(null);
    const safeSendAlertRef = useRef(null);
    const safeLeaveRef = useRef(null);

    useEffect(() => {
        invoiceEmailSubjectRef.current?.setValue(
            invoiceData.invoice?.invoiceEmailSubject ?? "",
            true,
        );
        invoiceMessageStringRef.current?.setValue(
            invoiceData.invoice?.invoiceMessageString ?? "",
            true,
        );
    }, [
        invoiceData.invoice?.invoiceMessageString,
        invoiceData.invoice?.invoiceEmailSubject,
    ]);

    // if unsaved updates have been made to invoice, show an alert
    useEffect(() => {
        const unsubscribe = navigation.addListener("beforeRemove", (e) => {
            if (!state.values.invoiceIsEdited) {
                return;
            }

            e.preventDefault();
            dispatchState({
                input: "leaveAlertAction",
                value: e.data.action,
            });
        });

        return () => unsubscribe();
    }, [dispatchState, navigation, state.values.invoiceIsEdited]);

    // trigger a popup if the CRM is unloaded
    useBeforeunload((e) => {
        if (!state.values.invoiceIsEdited) {
            return;
        }

        e.preventDefault();
    });

    const [commitRemovePayment, removePaymentInFlight] =
        useMutation<RemovePaymentMutation>(remove_payment);

    const removePayment = useCallback(
        (paymentId: string | undefined) => {
            if (typeof paymentId === "string") {
                const removePaymentConfig = {
                    variables: {
                        connections: paymentsConnectionId
                            ? [paymentsConnectionId]
                            : [],
                        input: {
                            paymentId: paymentId,
                        },
                    },
                    onCompleted: (response: RemovePaymentMutation$data) => {
                        if (response?.removePayment?.success) {
                            toast.show({
                                render: ({ id }: { id: string }) => (
                                    <ToastAlert
                                        id={id}
                                        status="error"
                                        title={"Payment removed"}
                                        toast={toast}
                                    />
                                ),
                            });
                        } else {
                            toast.show({
                                render: ({ id }: { id: string }) => (
                                    <ToastAlert
                                        description={
                                            "Please get in touch with one of the team"
                                        }
                                        id={id}
                                        status="error"
                                        title={"Couldn't remove payment"}
                                        toast={toast}
                                    />
                                ),
                            });
                        }
                        dispatchState({
                            input: "clickedPaymentId",
                            value: undefined,
                        });
                    },
                };
                commitRemovePayment(removePaymentConfig);
            }
        },
        [commitRemovePayment, dispatchState, paymentsConnectionId, toast],
    );

    const [
        commitGenerateOrUpdateDraftInvoice,
        generateOrUpdateDraftInvoiceInFlight,
    ] = useMutation<GenerateOrUpdateDraftInvoiceMutation>(
        generate_or_update_draft_invoice,
    );

    const generateOrUpdateDraftInvoice = useCallback(
        (
            variables: {
                block: number;
                isUpdate?: boolean;
                parentId: string;
                startingYear: number;
            },
            onComplete?: () => void,
        ) => {
            const generateOrUpdateDraftInvoiceConfig = {
                variables: {
                    connections: otherInvoicesConnectionIds,
                    input: variables,
                },
                onCompleted: (
                    response: GenerateOrUpdateDraftInvoiceMutation$data,
                ) => {
                    if (response?.generateOrUpdateDraftInvoice?.success) {
                        if (response?.generateOrUpdateDraftInvoice?.invoice) {
                            toast.show({
                                render: ({ id }: { id: string }) => (
                                    <ToastAlert
                                        id={id}
                                        status="success"
                                        title={
                                            !variables.isUpdate
                                                ? "New draft created"
                                                : "Draft updated"
                                        }
                                        toast={toast}
                                    />
                                ),
                            });
                            dispatchState({
                                input: "invoiceIsEdited",
                                value: false,
                            });
                            if (
                                !variables.isUpdate &&
                                response.generateOrUpdateDraftInvoice.invoice
                                    ?.id
                            ) {
                                if (isAccountScreen) {
                                    navigation.setParams({
                                        previousOtherInvoicesConnectionIds:
                                            otherInvoicesConnectionIds,
                                    });
                                    refreshHandler();
                                } else {
                                    navigation.push("Invoice", {
                                        invoiceId:
                                            response
                                                .generateOrUpdateDraftInvoice
                                                .invoice.id,
                                        previousOtherInvoicesConnectionIds:
                                            otherInvoicesConnectionIds,
                                    });
                                }
                            }
                        } else if (
                            response?.generateOrUpdateDraftInvoice
                                .deletedInvoiceId
                        ) {
                            toast.show({
                                render: ({ id }: { id: string }) => (
                                    <ToastAlert
                                        description={
                                            "There are no longer any paid lesson blocks associated\nwith this account for this block"
                                        }
                                        id={id}
                                        status="error"
                                        title={"Draft deleted"}
                                        toast={toast}
                                    />
                                ),
                            });
                            if (isAccountScreen) {
                                refreshHandler();
                            } else if (navigation.canGoBack()) {
                                navigation.goBack();
                            }
                        } else {
                            toast.show({
                                render: ({ id }: { id: string }) => (
                                    <ToastAlert
                                        description="There are no paid lesson blocks associated to this parent, so no invoice was created."
                                        id={id}
                                        status="info"
                                        title={
                                            !variables.isUpdate
                                                ? "No new draft created"
                                                : "Draft not updated"
                                        }
                                        toast={toast}
                                    />
                                ),
                            });
                        }
                        onComplete?.();
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={
                                        !variables.isUpdate
                                            ? "Couldn't create draft"
                                            : "Couldn't update draft"
                                    }
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };
            commitGenerateOrUpdateDraftInvoice(
                generateOrUpdateDraftInvoiceConfig,
            );
        },
        [
            commitGenerateOrUpdateDraftInvoice,
            dispatchState,
            isAccountScreen,
            navigation,
            otherInvoicesConnectionIds,
            refreshHandler,
            toast,
        ],
    );

    const [commitDeleteDraftInvoice, deleteDraftInvoiceInFlight] =
        useMutation<DeleteDraftInvoiceMutation>(delete_draft_invoice);

    const deleteDraftInvoice = useCallback(
        (invoiceId: string) => {
            const deleteDraftInvoiceConfig = {
                variables: {
                    connections: otherInvoicesConnectionIds,
                    input: { invoiceId: invoiceId },
                },
                updater: (store: RecordSourceSelectorProxy) => {
                    const invoiceRecord = store.get(invoiceId);
                    invoiceRecord?.invalidateRecord();
                },
                onCompleted: (response: DeleteDraftInvoiceMutation$data) => {
                    changeStatusActionsheetOnClose();
                    if (response?.deleteDraftInvoice?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="error"
                                    title={"Draft deleted"}
                                    toast={toast}
                                />
                            ),
                        });
                        if (navigation.canGoBack()) {
                            navigation.goBack();
                        }
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't delete draft"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };
            commitDeleteDraftInvoice(deleteDraftInvoiceConfig);
        },
        [
            changeStatusActionsheetOnClose,
            commitDeleteDraftInvoice,
            navigation,
            otherInvoicesConnectionIds,
            toast,
        ],
    );

    const [commitUpdateInvoiceStatus, updateInvoiceStatusInFlight] =
        useMutation<UpdateInvoiceStatusMutation>(update_invoice_status);

    const updateInvoiceStatus = useCallback(
        (
            variables: {
                draftInvoiceEmail?: boolean;
                invoiceId: string;
                preferText?: boolean;
                relatedInvoiceId?: string;
                sendInvoice?: boolean;
                sentDate?: string;
                status: "CANCELLED" | "SENT";
            },
            onComplete?: () => void,
        ) => {
            const updateInvoiceStatusConfig = {
                variables: {
                    input: variables,
                },
                onCompleted: (response: UpdateInvoiceStatusMutation$data) => {
                    if (response?.updateInvoiceStatus?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status={
                                        variables.status === "CANCELLED"
                                            ? "error"
                                            : "success"
                                    }
                                    title={
                                        variables.status === "CANCELLED"
                                            ? "Invoice cancelled"
                                            : variables.sendInvoice === true
                                              ? "Invoice sent"
                                              : variables.draftInvoiceEmail
                                                ? "Gmail draft created"
                                                : "Invoice marked as sent"
                                    }
                                    toast={toast}
                                />
                            ),
                        });
                        onComplete?.();
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={
                                        variables.status === "CANCELLED"
                                            ? "Coudn't cancel invoice"
                                            : variables.sendInvoice === true
                                              ? "Coudn't send invoice"
                                              : variables.draftInvoiceEmail
                                                ? "Couldn't create Gmail draft"
                                                : "Coudn't mark as sent"
                                    }
                                    toast={toast}
                                />
                            ),
                        });
                    }
                    dispatchState({
                        input: "isInvoiceSendButtonPress",
                        value: true,
                    });
                },
            };
            commitUpdateInvoiceStatus(updateInvoiceStatusConfig);
        },
        [commitUpdateInvoiceStatus, dispatchState, toast],
    );

    const commitUpdateInvoiceMessage =
        useMutation<UpdateInvoiceMessageMutation>(update_invoice_message)[0];

    const updateInvoiceMessage = useCallback(
        (variables: {
            invoiceEmailSubject?: string;
            invoiceId: string;
            invoiceMessageString?: string;
        }) => {
            const updateInvoiceMessageConfig = {
                variables: {
                    input: variables,
                },
                onCompleted: (response: UpdateInvoiceMessageMutation$data) => {
                    if (response?.updateInvoiceMessage?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={
                                        typeof variables.invoiceEmailSubject ==
                                        "string"
                                            ? "Updated email subject"
                                            : "Updated email message"
                                    }
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't update invoice message"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };
            commitUpdateInvoiceMessage(updateInvoiceMessageConfig);
        },
        [commitUpdateInvoiceMessage, toast],
    );

    const [
        commitSendReminderForUnpaidInvoice,
        sendReminderForUnpaidInvoiceInFlight,
    ] = useMutation<SendReminderForUnpaidInvoiceMutation>(
        send_reminder_for_unpaid_invoice,
    );

    const sendReminderForUnpaidInvoice = useCallback(
        (variables: { invoiceId: string; sendByEmail?: boolean }) => {
            const sendReminderForUnpaidInvoiceConfig = {
                variables: {
                    input: variables,
                },
                onCompleted: (
                    response: SendReminderForUnpaidInvoiceMutation$data,
                ) => {
                    if (response?.sendReminderForUnpaidInvoice?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={"Sent invoice reminder"}
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't send invoice reminder"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };
            commitSendReminderForUnpaidInvoice(
                sendReminderForUnpaidInvoiceConfig,
            );
        },
        [commitSendReminderForUnpaidInvoice, toast],
    );

    const commitUpdateAccountFeeOrCredit =
        useMutation<UpdateAccountFeeOrCreditMutation>(
            update_account_fee_or_credit,
        )[0];

    const updateAccountFeeOrCredit = useCallback(
        (variables: {
            amount: number;
            block: number;
            profileId: string;
            startingYear: number;
        }) => {
            const updateAccountFeeOrCreditConfig = {
                variables: {
                    input: variables,
                },
                onCompleted: (
                    response: UpdateAccountFeeOrCreditMutation$data,
                ) => {
                    if (response?.updateAccountFeeOrCredit?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={"Updated account credit for block"}
                                    toast={toast}
                                />
                            ),
                        });
                        dispatchState({
                            input: "invoiceIsEdited",
                            value: true,
                        });
                        // used to add the relay item to the store
                        refetchAccountFeeOrCreditHandler();
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't update account credit"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };
            commitUpdateAccountFeeOrCredit(updateAccountFeeOrCreditConfig);
        },
        [
            commitUpdateAccountFeeOrCredit,
            dispatchState,
            refetchAccountFeeOrCreditHandler,
            toast,
        ],
    );

    const updateInvoiceStatusHandler = useCallback(
        (
            invoiceId: string | undefined,
            status: string,
            options?: {
                draftInvoiceEmail?: boolean;
                ignoreActiveInvoiceCheck?: boolean;
                ignorePaymentsCheck?: boolean;
                preferText?: boolean;
                sendInvoice?: boolean;
                sentDate?: string;
            },
            onComplete?: () => void,
        ) => {
            changeStatusActionsheetOnClose();
            if (invoiceId && (status === "CANCELLED" || status === "SENT")) {
                // show an alert if cancelling an invoice with existing payments
                if (
                    !options?.ignorePaymentsCheck &&
                    status === "CANCELLED" &&
                    (invoiceData.invoice?.amountPaid ?? 0) > 0
                ) {
                    dispatchState({ input: "cancelAlertIsOpen", value: true });
                    return;
                    // show an alert if sending an invoice when there are already other active invoice(s)
                } else if (
                    !options?.ignoreActiveInvoiceCheck &&
                    status === "SENT" &&
                    (invoiceData.invoice?.otherInvoicesForBlock.edges.filter(
                        (item) =>
                            (item?.node?.status === "SENT" ||
                                item?.node?.status === "PAID" ||
                                item?.node?.status === "PENDING" ||
                                item?.node?.status === "OVERPAID") &&
                            item?.node?.id != invoiceData.invoice?.id,
                    ).length ?? 0) > 0
                ) {
                    dispatchState({
                        input: "otherInvoiceAlertIsOpen",
                        value: true,
                    });
                    dispatchState({
                        input: "otherInvoiceAlertOptions",
                        value: {
                            sendInvoice: options?.sendInvoice,
                            draftInvoiceEmail: options?.draftInvoiceEmail,
                            otherInvoiceId:
                                invoiceData.invoice?.otherInvoicesForBlock.edges.filter(
                                    (item) =>
                                        item?.node?.status === "SENT" ||
                                        item?.node?.status === "PAID" ||
                                        item?.node?.status === "PENDING" ||
                                        item?.node?.status === "OVERPAID",
                                )[0]?.node?.id,
                        },
                    });
                    return;
                }

                updateInvoiceStatus(
                    {
                        invoiceId: invoiceId,
                        relatedInvoiceId:
                            invoiceData.invoice?.id &&
                            invoiceId !== invoiceData.invoice.id
                                ? invoiceData.invoice.id
                                : undefined,
                        status: status,
                        sentDate: options?.sentDate,
                        sendInvoice: options?.sendInvoice,
                        draftInvoiceEmail: options?.draftInvoiceEmail,
                        preferText: options?.preferText,
                    },
                    onComplete,
                );
            }
        },
        [
            changeStatusActionsheetOnClose,
            dispatchState,
            invoiceData.invoice?.amountPaid,
            invoiceData.invoice?.id,
            invoiceData.invoice?.otherInvoicesForBlock.edges,
            updateInvoiceStatus,
        ],
    );

    const confirmPaymentDateHandler = useCallback(
        (params: { date?: Date }) => {
            if (params.date) {
                paymentDateInputRef.current?.setValue(
                    format(params.date, "dd/MM/yyyy"),
                    true,
                );
                dispatchState({
                    input: "paymentModalDetails",
                    value: {
                        ...state.values.paymentModalDetails,
                        paymentDate: params.date.toDateString(),
                    },
                });
                dispatchState({
                    input: "showPaymentModal",
                    value: true,
                });
            } else {
                dispatchState({
                    input: "showPaymentModal",
                    value: true,
                });
            }
            dispatchState({
                input: "datePickerIsOpen",
                value: false,
            });
        },
        [dispatchState, state.values.paymentModalDetails],
    );

    const confirmSentDateHandler = useCallback(
        (params: { date?: Date }) => {
            if (params.date) {
                updateInvoiceStatusHandler(invoiceData.invoice?.id, "SENT", {
                    sentDate: format(params.date, "yyyy-MM-dd"),
                });
            }
            dispatchState({
                input: "datePickerIsOpen",
                value: false,
            });
        },
        [dispatchState, invoiceData.invoice?.id, updateInvoiceStatusHandler],
    );

    const generateDraftHandler = useCallback(
        (
            {
                isSideBarPress,
                isUpdate,
                overwriteOtherDraft,
            }: {
                isSideBarPress?: boolean;
                isUpdate?: boolean;
                overwriteOtherDraft?: boolean;
            },
            onComplete?: () => void,
        ) => {
            dispatchState({
                input: "isSideBarPress",
                value: isSideBarPress,
            });
            const existingDraftId =
                invoiceData.invoice?.otherInvoicesForBlock.edges.filter(
                    (item) => item?.node?.status === "DRAFT",
                )[0]?.node?.id;
            if (!isUpdate && !overwriteOtherDraft && existingDraftId) {
                dispatchState({ input: "draftAlertIsOpen", value: true });
                dispatchState({
                    input: "draftAlertInvoiceId",
                    value: existingDraftId,
                });
            } else if (
                invoiceData.invoice?.assignedTo?.id &&
                invoiceData.invoice.startingYear &&
                invoiceData.invoice.block
            ) {
                generateOrUpdateDraftInvoice(
                    {
                        parentId: invoiceData.invoice.assignedTo.id,
                        startingYear: invoiceData.invoice.startingYear,
                        block: invoiceData.invoice.block,
                        isUpdate: isUpdate,
                    },
                    onComplete,
                );
            } else if (
                profileId &&
                accountScreenState?.values.startingYear &&
                accountScreenState?.values.block
            ) {
                generateOrUpdateDraftInvoice(
                    {
                        parentId: profileId,
                        startingYear: accountScreenState.values.startingYear,
                        block: accountScreenState.values.block,
                        isUpdate: isUpdate,
                    },
                    onComplete,
                );
            }
        },
        [
            accountScreenState?.values.block,
            accountScreenState?.values.startingYear,
            dispatchState,
            generateOrUpdateDraftInvoice,
            invoiceData.invoice?.assignedTo?.id,
            invoiceData.invoice?.block,
            invoiceData.invoice?.otherInvoicesForBlock.edges,
            invoiceData.invoice?.startingYear,
            profileId,
        ],
    );

    const sendReminderHandler = useCallback(
        (sendByEmail?: boolean) => {
            if (invoiceData.invoice?.id) {
                sendReminderForUnpaidInvoice({
                    invoiceId: invoiceData.invoice.id,
                    sendByEmail: sendByEmail,
                });
            }
        },
        [invoiceData.invoice?.id, sendReminderForUnpaidInvoice],
    );

    const invoiceMessageFinishEditingHandler = useCallback(
        (
            inputIdentifier?: string | number,
            inputValue?: string,
            isValid?: boolean,
        ) => {
            if (inputIdentifier && invoiceData.invoice?.id && isValid) {
                updateInvoiceMessage({
                    invoiceId: invoiceData.invoice.id,
                    [inputIdentifier]: inputValue,
                });
            }
        },
        [invoiceData.invoice?.id, updateInvoiceMessage],
    );

    const accountCreditFinishEditingHandler = useCallback(
        (_: string | number, inputValue?: string | undefined) => {
            if (
                typeof inputValue !== "undefined" &&
                isNumeric(inputValue) &&
                profileId &&
                accountScreenState?.values.startingYear &&
                accountScreenState?.values.block
            ) {
                let parsedInput = parseFloat(inputValue);
                // multiply by -1 as credits are always stored as negative on CRM
                if (parsedInput != 0) {
                    parsedInput = parsedInput * -1;
                }
                if (
                    parsedInput !=
                    (typeof invoiceData.accountFeeOrCredit?.amount === "string"
                        ? parseFloat(invoiceData.accountFeeOrCredit?.amount)
                        : null)
                )
                    updateAccountFeeOrCredit({
                        profileId: profileId,
                        startingYear: accountScreenState?.values.startingYear,
                        block: accountScreenState?.values.block,
                        amount: parsedInput,
                    });
            }
        },
        [
            profileId,
            accountScreenState?.values.startingYear,
            accountScreenState?.values.block,
            invoiceData.accountFeeOrCredit?.amount,
            updateAccountFeeOrCredit,
        ],
    );

    const renderInvoiceStatusPressable = useCallback(() => {
        return (
            <Pressable
                alignItems="center"
                isDisabled={invoiceData.invoice?.status === "CANCELLED"}
                onPress={() => changeStatusActionsheetOnOpen()}
                pointerEvents={
                    invoiceData.invoice?.status === "CANCELLED"
                        ? "none"
                        : undefined
                }
            >
                <InvoiceStatus
                    isLoading={
                        (updateInvoiceStatusInFlight ||
                            sendReminderForUnpaidInvoiceInFlight) &&
                        !state.values.isInvoiceSendButtonPress
                    }
                    isUpdatedDraft={invoiceData.invoice?.isUpdate}
                    showEditArrow={invoiceData.invoice?.status !== "CANCELLED"}
                    status={invoiceData.invoice?.status}
                />
            </Pressable>
        );
    }, [
        changeStatusActionsheetOnOpen,
        invoiceData.invoice?.isUpdate,
        invoiceData.invoice?.status,
        sendReminderForUnpaidInvoiceInFlight,
        state.values.isInvoiceSendButtonPress,
        updateInvoiceStatusInFlight,
    ]);

    const renderInvoiceSendLog = useCallback(() => {
        return (
            <VStack alignItems="center" space="2">
                <Text color="surface.700">Invoice Send Log</Text>
                {!invoiceData.invoice?.initialSentTimestamp &&
                !invoiceData.invoice?.reminder1SentTimestamp &&
                !invoiceData.invoice?.reminder2SentTimestamp &&
                !invoiceData.invoice?.reminder3SentTimestamp ? (
                    <Text fontSize="md">Invoice Not Sent</Text>
                ) : null}
                {invoiceData.invoice?.initialSentTimestamp ? (
                    <Text fontSize="md">{`Sent on: ${format(
                        new Date(invoiceData.invoice.initialSentTimestamp),
                        "dd/MM/yyyy",
                    )}`}</Text>
                ) : null}
                {invoiceData.invoice?.reminder1SentTimestamp ? (
                    <Text fontSize="md">{`1st reminder: ${format(
                        new Date(invoiceData.invoice.reminder1SentTimestamp),
                        "dd/MM/yyyy",
                    )}`}</Text>
                ) : null}
                {invoiceData.invoice?.reminder2SentTimestamp ? (
                    <Text fontSize="md">{`2nd reminder: ${format(
                        new Date(invoiceData.invoice.reminder2SentTimestamp),
                        "dd/MM/yyyy",
                    )}`}</Text>
                ) : null}
                {invoiceData.invoice?.reminder3SentTimestamp ? (
                    <Text fontSize="md">{`3rd reminder: ${format(
                        new Date(invoiceData.invoice.reminder3SentTimestamp),
                        "dd/MM/yyyy",
                    )}`}</Text>
                ) : null}
            </VStack>
        );
    }, [
        invoiceData.invoice?.initialSentTimestamp,
        invoiceData.invoice?.reminder1SentTimestamp,
        invoiceData.invoice?.reminder2SentTimestamp,
        invoiceData.invoice?.reminder3SentTimestamp,
    ]);

    const renderInvoicePayments = useCallback(() => {
        return (
            <VStack alignItems="center" space="2">
                <Text color="surface.700">Payments</Text>
                {invoiceData.invoice?.payments.edges.map((item, index) => {
                    if (!item?.node?.amount) {
                        return null;
                    }
                    return (
                        <HStack key={index}>
                            <Pressable
                                _hover={{
                                    opacity: 0.8,
                                    bg: "transparent",
                                }}
                                _pressed={{
                                    opacity: 0.7,
                                    bg: "transparent",
                                }}
                                onPress={() => {
                                    paymentModalKey.current += 1;
                                    dispatchState({
                                        input: "paymentModalDetails",
                                        value: {
                                            isNewPayment: false,
                                            paymentId: item.node?.id,
                                            paymentType:
                                                // graphene converts the / into an underscore
                                                item.node?.paymentType ===
                                                "CREDIT_DEBIT_CARD"
                                                    ? "CREDIT/DEBIT_CARD"
                                                    : item.node?.paymentType,
                                            paymentReference:
                                                item.node?.paymentReference ??
                                                undefined,
                                            paymentDate:
                                                item.node?.paymentDate ??
                                                undefined,
                                            amount:
                                                typeof item.node?.amount ===
                                                "string"
                                                    ? parseFloat(
                                                          item.node.amount,
                                                      )
                                                    : undefined,
                                            paymentNote:
                                                item.node?.paymentNote ??
                                                undefined,
                                        },
                                    });
                                    dispatchState({
                                        input: "showPaymentModal",
                                        value: true,
                                    });
                                }}
                                px="2"
                            >
                                <Text
                                    fontSize="md"
                                    fontWeight="600"
                                    textDecorationLine="underline"
                                >
                                    {`£${parseFloat(
                                        item.node.amount as unknown as string,
                                    )?.toFixed(2)} (${
                                        item.node.paymentDate
                                            ? format(
                                                  new Date(
                                                      item.node.paymentDate,
                                                  ),
                                                  "dd/MM/yyyy",
                                              )
                                            : "Undated"
                                    })`}
                                </Text>
                            </Pressable>
                            <Button
                                _hover={{
                                    bg: "transparent",
                                    opacity: 0.8,
                                }}
                                _pressed={{
                                    bg: "transparent",
                                    opacity: 0.7,
                                }}
                                _spinner={{
                                    color: "surface.700",
                                }}
                                isLoading={
                                    removePaymentInFlight &&
                                    state.values.clickedPaymentId ===
                                        item.node?.id
                                }
                                leftIcon={
                                    <CloseIcon color="surface.700" size="4" />
                                }
                                onPress={() => {
                                    dispatchState({
                                        input: "clickedPaymentId",
                                        value: item.node?.id,
                                    });
                                    removePayment(item.node?.id);
                                }}
                                p="1"
                                position="absolute"
                                right="-22"
                                variant="ghost"
                            />
                        </HStack>
                    );
                })}
                {invoiceData.invoice?.status !== "CANCELLED" ? (
                    <Button
                        bg="primary.400"
                        leftIcon={<InvoiceIcon size="5" />}
                        mt="1"
                        onPress={() => {
                            paymentModalKey.current += 1;
                            dispatchState({
                                input: "showPaymentModal",
                                value: true,
                            });
                            dispatchState({
                                input: "paymentModalDetails",
                                value: {
                                    isNewPayment: true,
                                },
                            });
                        }}
                        shadow={1}
                    >
                        Add Payment
                    </Button>
                ) : (
                    <Text fontSize="md">No Payments</Text>
                )}
            </VStack>
        );
    }, [
        dispatchState,
        invoiceData.invoice?.payments.edges,
        invoiceData.invoice?.status,
        removePayment,
        removePaymentInFlight,
        state.values.clickedPaymentId,
    ]);

    const renderOtherInvoicesForBlock = useCallback(() => {
        return (
            <VStack alignItems="center" space="2">
                <Text color="surface.700">Other Invoices For Block</Text>
                {otherInvoicesForBlock.map((item, index) => {
                    if (item?.node?.id) {
                        return (
                            <Pressable
                                key={index}
                                _hover={{
                                    bg: "surface.400",
                                }}
                                _pressed={{
                                    bg: "surface.500",
                                }}
                                borderRadius="2xl"
                                onPress={() => {
                                    if (
                                        isAccountScreen &&
                                        dispatchAccountScreenState
                                    ) {
                                        dispatchAccountScreenState({
                                            input: "invoiceId",
                                            value: item.node?.id as string,
                                        });
                                        navigation.setParams({
                                            previousOtherInvoicesConnectionIds:
                                                otherInvoicesConnectionIds,
                                        });
                                        refreshHandler({
                                            invoiceId: item.node?.id as string,
                                        });
                                    } else {
                                        navigation.push("Invoice", {
                                            invoiceId: item.node?.id as string,
                                            previousOtherInvoicesConnectionIds:
                                                otherInvoicesConnectionIds,
                                        });
                                    }
                                }}
                                px="2"
                            >
                                <Text
                                    fontSize="md"
                                    fontWeight="600"
                                    textDecorationLine="underline"
                                >
                                    {`#${
                                        item.node.invoiceNumber
                                    } (${titleCaseConverter(
                                        item.node.status,
                                    )})`}
                                </Text>
                            </Pressable>
                        );
                    }
                })}
                {invoiceData.invoice?.status !== "DRAFT" ? (
                    <Button
                        bg="primary.400"
                        isLoading={
                            generateOrUpdateDraftInvoiceInFlight &&
                            state.values.isSideBarPress
                        }
                        isLoadingText="Create New Draft"
                        leftIcon={<DraftFilledIcon size="5" />}
                        mt="1"
                        onPress={() =>
                            generateDraftHandler({
                                isSideBarPress: true,
                            })
                        }
                        shadow={1}
                    >
                        Create New Draft
                    </Button>
                ) : otherInvoicesForBlock.length === 0 ? (
                    <Text fontSize="md">No Other Invoices</Text>
                ) : null}
            </VStack>
        );
    }, [
        dispatchAccountScreenState,
        generateDraftHandler,
        generateOrUpdateDraftInvoiceInFlight,
        invoiceData.invoice?.status,
        isAccountScreen,
        navigation,
        otherInvoicesConnectionIds,
        otherInvoicesForBlock,
        refreshHandler,
        state.values.isSideBarPress,
    ]);

    const renderSideBar = useCallback(() => {
        if (!isAccountScreen) {
            return (
                <SideBar
                    isCollapsed={state.values.sideBarIsCollapsed}
                    navigation={navigation}
                    setIsCollapsed={() =>
                        dispatchState({
                            input: "sideBarIsCollapsed",
                            value: !state.values.sideBarIsCollapsed,
                        })
                    }
                    width={
                        state.values.sideBarIsCollapsed
                            ? SIDE_BAR_COLLAPSED_WIDTH
                            : SIDE_BAR_EXPANDED_WIDTH
                    }
                >
                    <VStack
                        alignItems="center"
                        mt="20"
                        pb="6"
                        space="4"
                        width="100%"
                    >
                        <VStack alignItems="center">
                            <Text fontSize="xl" mt="2">
                                Invoice
                            </Text>
                            <Text fontSize="lg">
                                {invoiceData.invoice?.invoiceNumber}
                            </Text>
                        </VStack>
                        <Pressable
                            onPress={() => {
                                if (invoiceData.invoice?.assignedTo?.id) {
                                    navigation.push("Account", {
                                        profileId:
                                            invoiceData.invoice.assignedTo.id,
                                        accountType: "PARENT",
                                        startingYear:
                                            invoiceData.invoice.startingYear ??
                                            undefined,
                                        block:
                                            invoiceData.invoice.block ??
                                            undefined,
                                    });
                                }
                            }}
                        >
                            <VStack alignItems="center">
                                <Avatar
                                    _text={{ color: "surface.200" }}
                                    bg="primary.600"
                                    size="md"
                                    source={{
                                        uri: undefined,
                                    }}
                                >
                                    {getInitials(
                                        invoiceData.invoice?.assignedTo?.user
                                            .firstName,
                                        invoiceData.invoice?.assignedTo?.user
                                            .lastName,
                                    )}
                                </Avatar>
                                {invoiceData.invoice?.assignedTo ? (
                                    <>
                                        <Text fontSize="lg" mt="2">
                                            {getFullName(
                                                invoiceData.invoice?.assignedTo
                                                    ?.user.firstName,
                                                invoiceData.invoice?.assignedTo
                                                    ?.user.lastName,
                                            )}
                                        </Text>
                                        <Text fontSize="lg">
                                            #
                                            {
                                                invoiceData.invoice?.assignedTo
                                                    ?.accountNumber
                                            }
                                        </Text>
                                    </>
                                ) : (
                                    <Text fontSize="lg" mt="2">
                                        Parent No Longer Exists
                                    </Text>
                                )}
                            </VStack>
                        </Pressable>
                        <VStack alignItems="center" space="4">
                            {renderInvoiceStatusPressable()}
                            {renderInvoiceSendLog()}
                        </VStack>
                        {invoiceData.invoice?.status !== "CANCELLED"
                            ? renderInvoicePayments()
                            : null}
                        {renderOtherInvoicesForBlock()}
                        {invoiceData.invoice?.status === "DRAFT" ? (
                            <Button
                                _spinner={{ mr: 1 }}
                                bg="primary.400"
                                isLoading={
                                    generateOrUpdateDraftInvoiceInFlight &&
                                    state.values.isSideBarPress
                                }
                                isLoadingText="Update Draft with Latest Data"
                                leftIcon={<RestartIcon mr="1" size="5" />}
                                mt="1"
                                onPress={() =>
                                    generateDraftHandler({
                                        isUpdate: true,
                                        isSideBarPress: true,
                                    })
                                }
                                shadow={1}
                            >
                                Update Draft with Latest Data
                            </Button>
                        ) : null}
                    </VStack>
                </SideBar>
            );
        } else {
            return null;
        }
    }, [
        isAccountScreen,
        state.values.sideBarIsCollapsed,
        state.values.isSideBarPress,
        navigation,
        invoiceData.invoice?.invoiceNumber,
        invoiceData.invoice?.assignedTo,
        invoiceData.invoice?.status,
        invoiceData.invoice?.startingYear,
        invoiceData.invoice?.block,
        renderInvoiceSendLog,
        renderInvoiceStatusPressable,
        renderInvoicePayments,
        renderOtherInvoicesForBlock,
        generateOrUpdateDraftInvoiceInFlight,
        dispatchState,
        generateDraftHandler,
    ]);

    const renderAccountFeeOrCredit = useCallback(() => {
        return (
            <HStack space="4">
                <Avatar
                    _text={{ color: "surface.200" }}
                    bg="primary.600"
                    size="md"
                    source={{
                        uri: undefined,
                    }}
                >
                    {getInitials(
                        invoiceData.invoice?.assignedTo?.user.firstName ??
                            firstName,
                        invoiceData.invoice?.assignedTo?.user.lastName ??
                            lastName,
                    )}
                </Avatar>
                <VStack>
                    <Text fontSize="md">
                        {getFullName(
                            invoiceData.invoice?.assignedTo?.user.firstName ??
                                firstName,
                            invoiceData.invoice?.assignedTo?.user.lastName ??
                                lastName,
                        )}
                    </Text>
                    <HStack>
                        <Text fontFamily="Poppins-Regular" fontSize="md" mr="1">
                            Credit for block:
                        </Text>
                        <Box width="70px">
                            <CurrencyInput
                                ref={invoiceCreditInputRef}
                                _focus={{
                                    borderWidth: 0,
                                    borderBottomWidth: 1,
                                    bg: "surface.100",
                                    borderColor: "surface.300",
                                    borderRadius: 10,
                                }}
                                borderBottomWidth={1}
                                borderRadius={0}
                                borderWidth={0}
                                focusOutlineColor="surface.300"
                                id="invoiceCredit"
                                initiallyValid
                                initialValue={
                                    invoiceData.accountFeeOrCredit?.amount &&
                                    Number(
                                        invoiceData.accountFeeOrCredit?.amount,
                                    ) != 0
                                        ? Number(
                                              invoiceData.accountFeeOrCredit
                                                  .amount,
                                          ) * -1
                                        : 0
                                }
                                onFinishEditing={
                                    accountCreditFinishEditingHandler
                                }
                                p="0"
                                size="lg"
                                textAlign="center"
                            />
                        </Box>
                    </HStack>
                </VStack>
            </HStack>
        );
    }, [
        accountCreditFinishEditingHandler,
        firstName,
        invoiceData.accountFeeOrCredit?.amount,
        invoiceData.invoice,
        lastName,
    ]);

    const renderInvoiceHeader = useCallback(() => {
        return (
            <VStack space="2">
                <HStack
                    alignItems="center"
                    justifyContent="space-between"
                    mb="2"
                    px="2"
                    width="100%"
                >
                    <HStack space="8">
                        {invoiceData.invoice?.id ? (
                            <Pressable
                                onPress={() => {
                                    if (invoiceData.invoice?.id) {
                                        navigation.push("Invoice", {
                                            invoiceId: invoiceData.invoice.id,
                                        });
                                    }
                                }}
                            >
                                <Text fontSize="xl">Invoice</Text>
                                <Text fontSize="md">
                                    {invoiceData.invoice.invoiceNumber}
                                </Text>
                            </Pressable>
                        ) : null}
                        <HStack space="6">
                            {renderInvoiceStatusPressable()}
                            {invoiceData.invoice?.status === "DRAFT" ? (
                                <Button
                                    _spinner={{ mr: 1 }}
                                    bg="primary.400"
                                    isLoading={
                                        generateOrUpdateDraftInvoiceInFlight &&
                                        state.values.isSideBarPress
                                    }
                                    isLoadingText="Update Draft"
                                    leftIcon={<RestartIcon mr="1" size="5" />}
                                    onPress={() =>
                                        generateDraftHandler({
                                            isUpdate: true,
                                            isSideBarPress: true,
                                        })
                                    }
                                    pr="4"
                                    py="2"
                                    shadow={1}
                                >
                                    Update Draft
                                </Button>
                            ) : null}
                        </HStack>
                    </HStack>
                    {renderAccountFeeOrCredit()}
                </HStack>
                <Divider
                    alignSelf="center"
                    bg="surface.300"
                    height="0.1px"
                    my="2"
                    orientation="horizontal"
                    width="100%"
                />
                <HStack
                    alignItems="flex-start"
                    justifyContent="space-evenly"
                    width="100%"
                >
                    {renderInvoiceSendLog()}
                    <Divider
                        alignSelf="center"
                        bg="surface.300"
                        mx="5"
                        orientation="vertical"
                    />
                    {renderInvoicePayments()}
                    <Divider
                        alignSelf="center"
                        bg="surface.300"
                        mx="5"
                        orientation="vertical"
                    />
                    {renderOtherInvoicesForBlock()}
                </HStack>
                {(invoiceData?.invoice?.block ?? 0) > 2 ||
                (invoiceData?.invoice?.startingYear ?? 0) > 2022 ? (
                    // don't show this for legacy invoices (email wasn't stored)
                    <Center mt="4">
                        <TopTabBar
                            bg="primary.100"
                            borderRadius="lg"
                            buttonStyle={{
                                bg: "primary.50",
                                alignItems: "center",
                                pt: 2,
                                pb: 2,
                                _hover: { bg: "primary.200" },
                                _pressed: { bg: "primary.300" },
                            }}
                            containerProps={{ pt: 4 }}
                            currentPageIndex={state.values.currentPageIndex}
                            hideBottomBar
                            overflow="hidden"
                            pages={[
                                "Invoice",
                                invoiceData?.invoice?.assignedTo?.user.email
                                    ? "Email"
                                    : "Text Message",
                            ]}
                            selectedButtonStyle={{
                                bg: "primary.100",
                            }}
                            selectedTextStyle={{
                                color: "primary.600",
                            }}
                            setCurrentPageIndex={(index) => {
                                dispatchState({
                                    input: "currentPageIndex",
                                    value: index,
                                });
                                navigation.setParams({
                                    currentPageIndex: index,
                                });
                            }}
                            shadow={1}
                            textStyle={{
                                color: "primary.800",
                                fontSize: "md",
                            }}
                        />
                    </Center>
                ) : null}
            </VStack>
        );
    }, [
        invoiceData.invoice?.id,
        invoiceData.invoice?.invoiceNumber,
        invoiceData.invoice?.status,
        invoiceData.invoice?.block,
        invoiceData.invoice?.startingYear,
        invoiceData.invoice?.assignedTo?.user.email,
        renderInvoiceStatusPressable,
        generateOrUpdateDraftInvoiceInFlight,
        state.values.isSideBarPress,
        state.values.currentPageIndex,
        renderAccountFeeOrCredit,
        renderInvoiceSendLog,
        renderInvoicePayments,
        renderOtherInvoicesForBlock,
        navigation,
        generateDraftHandler,
        dispatchState,
    ]);

    const renderMessage = useCallback(() => {
        if (invoiceData.invoice) {
            return (
                <VStack
                    alignItems="center"
                    bg="white"
                    borderRadius="2xl"
                    p="20mm"
                    shadow={1}
                    space="4"
                    width="100%"
                >
                    {invoiceData.invoice.status === "DRAFT" ? (
                        <Button
                            _spinner={{ mr: 1, ml: 1 }}
                            _text={{ fontSize: "20" }}
                            isLoading={
                                generateOrUpdateDraftInvoiceInFlight &&
                                !state.values.isSideBarPress
                            }
                            isLoadingText={"Update Draft"}
                            leftIcon={<RestartIcon mr="1" size="lg" />}
                            onPress={() =>
                                generateDraftHandler?.({
                                    overwriteOtherDraft: true,
                                    isUpdate: true,
                                })
                            }
                            position="absolute"
                            pr="4"
                            right="10"
                            shadow={1}
                            top="10"
                        >
                            Update Draft
                        </Button>
                    ) : null}
                    {(invoiceData.invoice?.totalAmount ?? 1) > 0 ? (
                        <>
                            <TextInput
                                bg="white"
                                borderWidth={0}
                                id="toAddress"
                                initiallyValid
                                initialValue={
                                    invoiceData.invoice.assignedToEmail
                                        ? invoiceData.invoice.assignedToEmail
                                        : (invoiceData.invoice
                                              .assignedToPhoneNumber ??
                                          undefined)
                                }
                                isDisabled
                                label="To"
                                labelStyle={{
                                    color: "surface.700",
                                    fontSize: "sm",
                                }}
                                mx="-2"
                                pointerEvents="none"
                                py="1.5"
                                size="lg"
                            />
                            <TextInput
                                ref={invoiceEmailSubjectRef}
                                autoCapitalize="none"
                                bg="white"
                                borderRadius="xl"
                                id="invoiceEmailSubject"
                                initiallyValid
                                initialValue={
                                    invoiceData.invoice.invoiceEmailSubject ??
                                    undefined
                                }
                                invalidIndicator
                                isDisabled={
                                    invoiceData.invoice.status !== "DRAFT"
                                }
                                keyboardType="default"
                                label="Subject"
                                labelStyle={{
                                    color: "surface.700",
                                    fontSize: "sm",
                                }}
                                mx="-2"
                                onFinishEditing={
                                    invoiceMessageFinishEditingHandler
                                }
                                pointerEvents={
                                    invoiceData.invoice.status !== "DRAFT"
                                        ? "none"
                                        : undefined
                                }
                                py="1.5"
                                size="lg"
                            />
                            <TextInput
                                ref={invoiceMessageStringRef}
                                _disabled={{
                                    opacity: 1,
                                    borderWidth: 1,
                                    _hover: { opacity: 1 },
                                }}
                                autoCapitalize="sentences"
                                bg="white"
                                borderRadius="xl"
                                fontFamily="Poppins-Regular"
                                id="invoiceMessageString"
                                initiallyValid
                                initialValue={
                                    invoiceData.invoice.invoiceMessageString ??
                                    undefined
                                }
                                invalidIndicator
                                isDisabled={
                                    invoiceData.invoice.status !== "DRAFT"
                                }
                                keyboardType="default"
                                maxLength={3000}
                                mt="2"
                                multiline
                                mx="-2"
                                numberOfLines={20}
                                onFinishEditing={
                                    invoiceMessageFinishEditingHandler
                                }
                                pointerEvents={
                                    invoiceData.invoice.status !== "DRAFT"
                                        ? "auto"
                                        : undefined
                                }
                                py="1.5"
                                size="lg"
                            />
                        </>
                    ) : (
                        <>
                            <Text fontSize="6xl" textAlign="center">
                                {"😴"}
                            </Text>
                            <Text fontSize="lg" textAlign="center">
                                {`As the total amount of this invoice is zero, no email ${
                                    invoiceData.invoice.status !== "DRAFT"
                                        ? "was"
                                        : "will be"
                                } sent to the customer.`}
                            </Text>
                        </>
                    )}
                </VStack>
            );
        } else {
            return null;
        }
    }, [
        generateDraftHandler,
        generateOrUpdateDraftInvoiceInFlight,
        invoiceData.invoice,
        invoiceMessageFinishEditingHandler,
        state.values.isSideBarPress,
    ]);

    const renderAlertPopups = useCallback(() => {
        return (
            <>
                <AlertPopup
                    alertIsOpen={state.values.draftAlertIsOpen}
                    body="Are you sure you want to overwrite it?"
                    header="There is already an existing draft invoice for this parent"
                    safeButtonRef={safeDraftAlertRef}
                    setAlertIsOpen={() => {
                        dispatchState({
                            input: "draftAlertIsOpen",
                            value: false,
                        });
                        dispatchState({
                            input: "draftAlertInvoiceId",
                            value: undefined,
                        });
                    }}
                >
                    <Button
                        ref={safeDraftAlertRef}
                        _text={{ fontSize: "lg" }}
                        colorScheme="surface"
                        height="50"
                        minWidth="56"
                        onPress={() => {
                            dispatchState({
                                input: "draftAlertIsOpen",
                                value: false,
                            });
                            if (state.values.draftAlertInvoiceId) {
                                if (
                                    isAccountScreen &&
                                    dispatchAccountScreenState
                                ) {
                                    dispatchAccountScreenState({
                                        input: "invoiceId",
                                        value: state.values.draftAlertInvoiceId,
                                    });
                                    navigation.setParams({
                                        previousOtherInvoicesConnectionIds:
                                            otherInvoicesConnectionIds,
                                    });
                                    refreshHandler({
                                        invoiceId:
                                            state.values.draftAlertInvoiceId,
                                    });
                                } else {
                                    navigation.push("Invoice", {
                                        invoiceId:
                                            state.values.draftAlertInvoiceId,
                                        previousOtherInvoicesConnectionIds:
                                            otherInvoicesConnectionIds,
                                    });
                                }
                                dispatchState({
                                    input: "draftAlertInvoiceId",
                                    value: undefined,
                                });
                            }
                        }}
                        width="25%"
                    >
                        No, show me the draft
                    </Button>
                    <ButtonDebounced
                        _loading={{ opacity: 1 }}
                        _spinner={{ size: "lg" }}
                        _text={{ fontSize: "lg" }}
                        colorScheme="red"
                        height="50"
                        minWidth="56"
                        onPress={() => {
                            dispatchState({
                                input: "draftAlertIsOpen",
                                value: false,
                            });
                            dispatchState({
                                input: "draftAlertInvoiceId",
                                value: undefined,
                            });
                            generateDraftHandler({ overwriteOtherDraft: true });
                        }}
                        width="25%"
                    >
                        Yes, overwrite it
                    </ButtonDebounced>
                </AlertPopup>
                <AlertPopup
                    alertIsOpen={state.values.cancelAlertIsOpen}
                    body={
                        "The payments need to be removed to cancel the invoice.\n\nAre you sure you want to cancel it?"
                    }
                    header="There are existing payments on this invoice"
                    safeButtonRef={safeCancelAlertRef}
                    setAlertIsOpen={() => {
                        dispatchState({
                            input: "cancelAlertIsOpen",
                            value: false,
                        });
                    }}
                >
                    <Button
                        ref={safeCancelAlertRef}
                        _text={{ fontSize: "lg" }}
                        colorScheme="surface"
                        height="80px"
                        minWidth="56"
                        onPress={() => {
                            dispatchState({
                                input: "cancelAlertIsOpen",
                                value: false,
                            });
                        }}
                        width="25%"
                    >
                        {"No, don't cancel\nthe invoice"}
                    </Button>
                    <ButtonDebounced
                        _loading={{ opacity: 1 }}
                        _spinner={{ size: "lg" }}
                        _text={{ fontSize: "lg" }}
                        colorScheme="red"
                        height="80px"
                        minWidth="56"
                        onPress={() => {
                            dispatchState({
                                input: "cancelAlertIsOpen",
                                value: false,
                            });
                            updateInvoiceStatusHandler(
                                invoiceData.invoice?.id,
                                "CANCELLED",
                                {
                                    ignorePaymentsCheck: true,
                                },
                            );
                        }}
                        width="25%"
                    >
                        {"Yes, cancel it and\nremove payments"}
                    </ButtonDebounced>
                </AlertPopup>
                <AlertPopup
                    alertIsOpen={state.values.otherInvoiceAlertIsOpen}
                    body={
                        "It needs to be cancelled before you can send this invoice. If it has existing payments, these will be deleted when it is cancelled.\n\nAre you sure you want to mark this invoice as sent?"
                    }
                    header="There is already an active invoice for this customer"
                    safeButtonRef={safeOtherInvoiceAlertRef}
                    setAlertIsOpen={() => {
                        dispatchState({
                            input: "otherInvoiceAlertIsOpen",
                            value: false,
                        });
                        dispatchState({
                            input: "otherInvoiceAlertOptions",
                            value: undefined,
                        });
                    }}
                >
                    <Button
                        ref={safeOtherInvoiceAlertRef}
                        _text={{ fontSize: "lg", flexWrap: "wrap" }}
                        colorScheme="surface"
                        height="75"
                        minWidth="56"
                        onPress={() => {
                            dispatchState({
                                input: "otherInvoiceAlertIsOpen",
                                value: false,
                            });
                            dispatchState({
                                input: "otherInvoiceAlertOptions",
                                value: undefined,
                            });
                        }}
                        width="25%"
                    >
                        {"No, go back"}
                    </Button>
                    <ButtonDebounced
                        _loading={{ opacity: 1 }}
                        _spinner={{ size: "lg" }}
                        _text={{ fontSize: "lg", flexWrap: "wrap", flex: 1 }}
                        height="75"
                        minWidth="56"
                        onPress={() => {
                            dispatchState({
                                input: "otherInvoiceAlertIsOpen",
                                value: false,
                            });
                            // cancel other invoice
                            updateInvoiceStatusHandler(
                                state.values.otherInvoiceAlertOptions
                                    ?.otherInvoiceId,
                                "CANCELLED",
                                undefined,
                                // then mark this one as sent
                                () =>
                                    updateInvoiceStatusHandler(
                                        invoiceData.invoice?.id,
                                        "SENT",
                                        {
                                            ignoreActiveInvoiceCheck: true,
                                            sendInvoice:
                                                state.values
                                                    .otherInvoiceAlertOptions
                                                    ?.sendInvoice,
                                            draftInvoiceEmail:
                                                state.values
                                                    .otherInvoiceAlertOptions
                                                    ?.draftInvoiceEmail,
                                        },
                                    ),
                            );
                            dispatchState({
                                input: "otherInvoiceAlertOptions",
                                value: undefined,
                            });
                        }}
                        width="25%"
                    >
                        {"Yes, cancel other\ninvoice and continue"}
                    </ButtonDebounced>
                </AlertPopup>
                <AlertPopup
                    alertIsOpen={state.values.sendAlertIsOpen}
                    body={
                        userHasEmail
                            ? "Note that the invoice PDF cannot be sent by text."
                            : "This parent doesn't have an email address so the message will be sent by text. The PDF invoice cannot be sent by text."
                    }
                    header={
                        userHasEmail
                            ? "How do you want to send this invoice?"
                            : "Are you sure you want to send this invoice?"
                    }
                    safeButtonRef={safeSendAlertRef}
                    setAlertIsOpen={() => {
                        dispatchState({
                            input: "sendAlertIsOpen",
                            value: false,
                        });
                        dispatchState({
                            input: "sendAlertOptions",
                            value: undefined,
                        });
                    }}
                >
                    <Button
                        ref={safeSendAlertRef}
                        _text={{ fontSize: "lg" }}
                        colorScheme={userHasEmail ? "secondary" : "surface"}
                        height="50"
                        minWidth="56"
                        onPress={() => {
                            dispatchState({
                                input: "sendAlertIsOpen",
                                value: false,
                            });
                            if (userHasEmail) {
                                updateInvoiceStatusHandler(
                                    invoiceData.invoice?.id,
                                    "SENT",
                                    {
                                        sendInvoice: true,
                                        preferText: true,
                                    },
                                );
                            }
                        }}
                        width="25%"
                    >
                        {userHasEmail ? "Send by text  📱" : "No, go back"}
                    </Button>
                    <ButtonDebounced
                        _loading={{ opacity: 1 }}
                        _spinner={{ size: "lg" }}
                        _text={{ fontSize: "lg" }}
                        height="50"
                        minWidth="56"
                        onPress={() => {
                            dispatchState({
                                input: "sendAlertIsOpen",
                                value: false,
                            });
                            updateInvoiceStatusHandler(
                                invoiceData.invoice?.id,
                                "SENT",
                                {
                                    sendInvoice: true,
                                    preferText: userHasEmail
                                        ? false
                                        : undefined,
                                },
                            );
                        }}
                        width="25%"
                    >
                        {userHasEmail ? "Send by email  💌" : "Yes, send it"}
                    </ButtonDebounced>
                </AlertPopup>
                <AlertPopup
                    alertIsOpen={Boolean(state.values.sendReminderAlertIsOpen)}
                    header={
                        userHasEmail
                            ? "Do you want to send the reminder by text or email?"
                            : "Are you sure you want to send a reminder for this invoice?"
                    }
                    setAlertIsOpen={(isOpen: boolean) =>
                        dispatchState({
                            input: "sendReminderAlertIsOpen",
                            value: isOpen,
                        })
                    }
                >
                    <ButtonDebounced
                        _text={{ fontSize: "lg" }}
                        colorScheme={userHasEmail ? "green" : "surface"}
                        minWidth="230"
                        onPress={() => {
                            dispatchState({
                                input: "sendReminderAlertIsOpen",
                                value: undefined,
                            });
                            if (userHasEmail) {
                                sendReminderHandler(true);
                            }
                        }}
                        width="45%"
                    >
                        {userHasEmail ? "Send by email  💌" : "No, go back"}
                    </ButtonDebounced>
                    <ButtonDebounced
                        ref={safeLeaveRef}
                        _text={{ fontSize: "lg" }}
                        colorScheme="primary"
                        minWidth="230"
                        onPress={() => {
                            dispatchState({
                                input: "sendReminderAlertIsOpen",
                                value: undefined,
                            });
                            sendReminderHandler(false);
                        }}
                        width="45%"
                    >
                        {userHasEmail ? "Send by text  📱" : "Yes, send text"}
                    </ButtonDebounced>
                </AlertPopup>
                <AlertPopup
                    alertIsOpen={Boolean(state.values.leaveAlertAction)}
                    body={"You should do this to keep them up-to-date."}
                    header="Update invoice PDF and email with your changes?"
                    safeButtonRef={safeLeaveRef}
                    setAlertIsOpen={(isOpen: boolean) =>
                        dispatchState({
                            input: "leaveAlertAction",
                            value: isOpen,
                        })
                    }
                >
                    <Button
                        _text={{ fontSize: "lg" }}
                        colorScheme="red"
                        minWidth="230"
                        onPress={() => {
                            dispatchState({
                                input: "leaveAlertAction",
                                value: undefined,
                            });
                            if (
                                isNavigationAction(
                                    state.values.leaveAlertAction,
                                )
                            ) {
                                navigation.dispatch(
                                    state.values.leaveAlertAction,
                                );
                            } else {
                                state.values.leaveAlertAction?.();
                            }
                        }}
                        width="45%"
                    >
                        {"No, I'll update it later"}
                    </Button>
                    <ButtonDebounced
                        ref={safeLeaveRef}
                        _text={{ fontSize: "lg" }}
                        colorScheme="primary"
                        minWidth="230"
                        onPress={() => {
                            dispatchState({
                                input: "leaveAlertAction",
                                value: undefined,
                            });
                            generateDraftHandler(
                                { overwriteOtherDraft: true, isUpdate: true },
                                () => {
                                    if (
                                        isNavigationAction(
                                            state.values.leaveAlertAction,
                                        )
                                    ) {
                                        navigation.dispatch(
                                            state.values.leaveAlertAction,
                                        );
                                    } else {
                                        state.values.leaveAlertAction?.();
                                    }
                                },
                            );
                        }}
                        width="45%"
                    >
                        {"Yes, update PDF"}
                    </ButtonDebounced>
                </AlertPopup>
            </>
        );
    }, [
        state.values,
        userHasEmail,
        dispatchState,
        dispatchAccountScreenState,
        isAccountScreen,
        refreshHandler,
        navigation,
        otherInvoicesConnectionIds,
        generateDraftHandler,
        updateInvoiceStatusHandler,
        invoiceData.invoice?.id,
        sendReminderHandler,
    ]);

    const renderNoInvoiceBanner = useCallback(() => {
        return (
            <ListEmptyBanner
                actionButton={
                    <Button
                        _spinner={{ mr: 2 }}
                        _text={{ fontSize: "xl" }}
                        isLoading={generateOrUpdateDraftInvoiceInFlight}
                        isLoadingText={"Generate Invoice"}
                        leftIcon={<InvoiceIcon mr="2" size="5" />}
                        onPress={() =>
                            generateDraftHandler({ isUpdate: false })
                        }
                        px="6"
                        shadow={1}
                    >
                        Generate Invoice
                    </Button>
                }
                explainer={"No invoice for this block"}
            >
                <Text fontSize="6xl">{emoji.getUnicode("neutral_face")}</Text>
            </ListEmptyBanner>
        );
    }, [generateDraftHandler, generateOrUpdateDraftInvoiceInFlight]);

    return (
        <Box flex={1}>
            {invoiceData.invoice ? (
                <>
                    {renderSideBar()}
                    <ScrollView
                        contentContainerStyle={{
                            paddingVertical: 24,
                        }}
                        flex={1}
                        {...(isAccountScreen
                            ? { mx: -6, px: 6 }
                            : {
                                  mt: 70,
                                  px: 6,
                                  ml: invoiceData.invoice
                                      ? state.values.sideBarIsCollapsed
                                          ? SIDE_BAR_COLLAPSED_WIDTH
                                          : SIDE_BAR_EXPANDED_WIDTH
                                      : 0,
                              })}
                    >
                        {!isAccountScreen &&
                        ((invoiceData.invoice?.block ?? 0) > 2 ||
                            (invoiceData.invoice?.startingYear ?? 0) > 2022) ? (
                            // don't show this for legacy invoices (email wasn't stored)
                            <TopTabBar
                                bg="primary.100"
                                borderRadius="lg"
                                buttonStyle={{
                                    bg: "primary.50",
                                    alignItems: "center",
                                    pt: 2,
                                    pb: 2,
                                    _hover: { bg: "primary.200" },
                                    _pressed: { bg: "primary.300" },
                                }}
                                containerProps={{ pt: 4 }}
                                currentPageIndex={state.values.currentPageIndex}
                                hideBottomBar
                                mb="6"
                                overflow="hidden"
                                pages={[
                                    "Invoice",
                                    userHasEmail ? "Email" : "Text Message",
                                ]}
                                selectedButtonStyle={{ bg: "primary.100" }}
                                selectedTextStyle={{ color: "primary.600" }}
                                setCurrentPageIndex={(index) => {
                                    dispatchState({
                                        input: "currentPageIndex",
                                        value: index,
                                    });
                                    navigation.setParams({
                                        currentPageIndex: index,
                                    });
                                }}
                                shadow={1}
                                textStyle={{ color: "primary.800" }}
                                width="100%"
                            />
                        ) : isAccountScreen ? (
                            <Box mb="4" width="100%">
                                {renderInvoiceHeader()}
                            </Box>
                        ) : null}
                        {state.values.currentPageIndex == 0 ? (
                            <Invoice
                                dispatchOuterState={dispatchState}
                                generateDraftHandler={generateDraftHandler}
                                generateOrUpdateDraftInvoiceInFlight={
                                    generateOrUpdateDraftInvoiceInFlight
                                }
                                invoiceData={invoiceData.invoice}
                                isAccountScreen={isAccountScreen}
                                isEditable={isEditable}
                                navigation={navigation}
                                outerState={state}
                                updateInvoiceStatusInFlight={
                                    updateInvoiceStatusInFlight
                                }
                            />
                        ) : (
                            renderMessage()
                        )}
                    </ScrollView>
                </>
            ) : isAccountScreen ? (
                <Box flex={1} flexGrow={1} py="6">
                    <VStack mb="4" width="100%">
                        {renderAccountFeeOrCredit()}
                    </VStack>
                    {renderNoInvoiceBanner()}
                </Box>
            ) : (
                <ProblemSplash
                    bannerText="Sorry, it looks like this invoice no longer exists"
                    navigation={navigation}
                />
            )}
            <InvoiceStatusActionsheet
                changeStatusActionsheetIsOpen={changeStatusActionsheetIsOpen}
                changeStatusActionsheetOnClose={changeStatusActionsheetOnClose}
                deleteDraftInvoice={deleteDraftInvoice}
                deleteDraftInvoiceInFlight={deleteDraftInvoiceInFlight}
                dispatchState={dispatchState}
                generateDraftHandler={generateDraftHandler}
                invoiceData={invoiceData.invoice}
                paymentModalKey={paymentModalKey}
                state={state}
                updateInvoiceStatusHandler={updateInvoiceStatusHandler}
                updateInvoiceStatusInFlight={updateInvoiceStatusInFlight}
            />
            <UpdateOrCreatePaymentModal
                dispatchState={dispatchState}
                invoiceData={invoiceData.invoice}
                paymentDateInputRef={paymentDateInputRef}
                paymentModalKey={paymentModalKey}
                paymentsConnectionId={paymentsConnectionId}
                state={state}
            />
            <DatePickerModal
                animationType="fade"
                date={
                    state.values.paymentModalDetails?.paymentDate &&
                    state.values.datePickerType === "PAYMENT_DATE"
                        ? new Date(state.values.paymentModalDetails.paymentDate)
                        : new Date()
                }
                label={
                    state.values.datePickerType === "PAYMENT_DATE"
                        ? "Select Payment Date"
                        : "Select Sent Date"
                }
                locale="en"
                mode="single"
                onConfirm={(params) => {
                    if (state.values.datePickerType === "PAYMENT_DATE") {
                        confirmPaymentDateHandler(params);
                    } else {
                        confirmSentDateHandler(params);
                    }
                }}
                onDismiss={() => {
                    dispatchState({
                        input: "datePickerIsOpen",
                        value: false,
                    });
                    if (state.values.datePickerType === "PAYMENT_DATE") {
                        dispatchState({
                            input: "showPaymentModal",
                            value: true,
                        });
                    }
                }}
                saveLabel="Select"
                uppercase={false}
                visible={state.values.datePickerIsOpen}
            />
            {renderAlertPopups()}
        </Box>
    );
};

const InvoiceAndEmail = (props: Props): ReactElement => {
    const {
        accountScreenState,
        dispatchAccountScreenState,
        isAccountScreen,
        loadInvoiceQueryReference,
        route,
        startingYears,
    } = props;

    const initialState = useMemo(() => {
        return {
            values: {
                invoiceData: undefined,
                searchTerm: route.params?.searchTerm ?? "",
                sideBarIsCollapsed: false,
                currentPageIndex: Number(route.params?.currentPageIndex ?? 0),
                showPaymentModal: false,
                paymentModalDetails: undefined,
                datePickerIsOpen: false,
                datePickerType: undefined,
                clickedPaymentId: undefined,
                draftAlertIsOpen: false,
                draftAlertInvoiceId: undefined,
                cancelAlertIsOpen: false,
                otherInvoiceAlertIsOpen: false,
                otherInvoiceAlertOptions: undefined,
                sendAlertIsOpen: false,
                activeLineItemId: undefined,
                invoiceIsEdited: false,
                isSideBarPress: false,
                sendReminderAlertIsOpen: false,
                isInvoiceSendButtonPress: false,
            },
        };
    }, [route.params?.currentPageIndex, route.params?.searchTerm]);

    const reducer = createReducer<ReducerValues, ReducerTypes>(initialState);
    const [state, dispatchState] = useReducer(reducer, initialState);

    const renderHeader = useCallback(() => {
        if (
            isAccountScreen &&
            accountScreenState &&
            dispatchAccountScreenState &&
            startingYears
        ) {
            return (
                <VStack
                    borderBottomWidth={1}
                    borderColor="surface.300"
                    mx="-6"
                    pb="4"
                    px="6"
                    space="4"
                >
                    <HStack space="4">
                        <Select
                            borderRadius="2xl"
                            fontSize="md"
                            onValueChange={(itemValue) => {
                                const value = parseInt(itemValue);

                                dispatchAccountScreenState({
                                    input: "invoiceId",
                                    value: undefined,
                                });

                                if (value > accountScreenState.values.startingYear) {
                                    dispatchAccountScreenState({
                                        input: "block",
                                        value: 1,
                                    });
                                    props.navigation.setParams({
                                        block: 1,
                                    });
                                }

                                dispatchAccountScreenState({
                                    input: "startingYear",
                                    value: value,
                                });
                                props.navigation.setParams({
                                    startingYear: value,
                                });
                            }}
                            placeholder="Select year"
                            selectedValue={String(
                                accountScreenState.values.startingYear,
                            )}
                            width="40"
                        >
                            {startingYears.map((item) => {
                                return (
                                    <Select.Item
                                        key={item.value}
                                        actionSheetLabel={item.label}
                                        value={String(item.value)}
                                    />
                                );
                            })}
                        </Select>
                        <Select
                            borderRadius="2xl"
                            fontSize="md"
                            onValueChange={(itemValue) => {
                                dispatchAccountScreenState({
                                    input: "invoiceId",
                                    value: undefined,
                                });
                                dispatchAccountScreenState({
                                    input: "block",
                                    value: parseInt(itemValue),
                                });
                                props.navigation.setParams({
                                    block: parseInt(itemValue),
                                });
                            }}
                            placeholder="Select block"
                            selectedValue={String(
                                accountScreenState.values.block,
                            )}
                            width="40"
                        >
                            {BLOCKS.map((item) => {
                                return (
                                    <Select.Item
                                        key={item.value}
                                        actionSheetLabel={item.label}
                                        value={item.value}
                                    />
                                );
                            })}
                        </Select>
                    </HStack>
                </VStack>
            );
        } else {
            return null;
        }
    }, [
        isAccountScreen,
        accountScreenState,
        dispatchAccountScreenState,
        startingYears,
        props.navigation,
    ]);

    const renderLoadingFallback = useCallback(() => {
        if (isAccountScreen) {
            return <LoadingBlobs>Loading Invoice...</LoadingBlobs>;
        } else {
            return (
                <HStack flex={1}>
                    <SideBar
                        isCollapsed={state.values.sideBarIsCollapsed}
                        navigation={props.navigation}
                        position="relative"
                        setIsCollapsed={() =>
                            dispatchState({
                                input: "sideBarIsCollapsed",
                                value: !state.values.sideBarIsCollapsed,
                            })
                        }
                        top="0"
                        width={
                            state.values.sideBarIsCollapsed
                                ? SIDE_BAR_COLLAPSED_WIDTH
                                : SIDE_BAR_EXPANDED_WIDTH
                        }
                    />
                    <LoadingBlobs
                        ml={
                            state.values.sideBarIsCollapsed
                                ? SIDE_BAR_COLLAPSED_WIDTH + 1
                                : SIDE_BAR_EXPANDED_WIDTH + 1
                        }
                        pt="70"
                    >
                        Loading Invoice...
                    </LoadingBlobs>
                </HStack>
            );
        }
    }, [isAccountScreen, props.navigation, state.values.sideBarIsCollapsed]);

    return (
        <Box flex={1}>
            {renderHeader()}
            {loadInvoiceQueryReference != null ? (
                <Suspense fallback={renderLoadingFallback()}>
                    <InvoiceAndEmailContent
                        {...props}
                        dispatchState={dispatchState}
                        loadInvoiceQueryReference={loadInvoiceQueryReference}
                        state={state}
                    />
                </Suspense>
            ) : (
                renderLoadingFallback()
            )}
        </Box>
    );
};

export default InvoiceAndEmail;
