/**
 * @generated SignedSource<<8123ec9659d5c3a7ba40f8d0d68bfc69>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DismissMutationProgressIndicatorInput = {
  clientMutationId?: string | null;
  dismissBulkCommunicationProgressIndicator?: boolean | null;
  dismissBulkUploadNewRegistersIndicator?: boolean | null;
  dismissGenerateDraftIndicator?: boolean | null;
  dismissGenerateGoogleRegistersIndicator?: boolean | null;
  dismissGenerateRegistersForNewBlockIndicator?: boolean | null;
  dismissMatchPaymentsIndicator?: boolean | null;
  dismissReadInRegistersIndicator?: boolean | null;
  dismissSendDraftIndicator?: boolean | null;
  dismissSendRemindersIndicator?: boolean | null;
  dismissTeacherBulkCommunicationProgressIndicator?: boolean | null;
};
export type DismissMutationProgressIndicatorMutation$variables = {
  input: DismissMutationProgressIndicatorInput;
};
export type DismissMutationProgressIndicatorMutation$data = {
  readonly dismissMutationProgressIndicator: {
    readonly errors: any | null;
    readonly profileGroup: {
      readonly bulkUploadNewRegistersProgress: number | null;
      readonly generateDraftsProgress: number | null;
      readonly generateGoogleRegistersProgress: number | null;
      readonly generateRegistersForNewBlockProgress: number | null;
      readonly id: string;
      readonly matchPaymentsProgress: number | null;
      readonly readInRegistersProgress: number | null;
      readonly sendBulkCommunicationProgress: number | null;
      readonly sendDraftsProgress: number | null;
      readonly sendRemindersProgress: number | null;
      readonly sendTeacherBulkCommunicationProgress: number | null;
    } | null;
    readonly success: boolean | null;
  } | null;
};
export type DismissMutationProgressIndicatorMutation = {
  response: DismissMutationProgressIndicatorMutation$data;
  variables: DismissMutationProgressIndicatorMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DismissMutationProgressIndicatorPayload",
    "kind": "LinkedField",
    "name": "dismissMutationProgressIndicator",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProfileGroupNode",
        "kind": "LinkedField",
        "name": "profileGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "generateDraftsProgress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sendDraftsProgress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sendRemindersProgress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "matchPaymentsProgress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "generateGoogleRegistersProgress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "readInRegistersProgress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bulkUploadNewRegistersProgress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "generateRegistersForNewBlockProgress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sendBulkCommunicationProgress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sendTeacherBulkCommunicationProgress",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DismissMutationProgressIndicatorMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DismissMutationProgressIndicatorMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "84b35cfb839a68c17c9fbcb125fb8cd1",
    "id": null,
    "metadata": {},
    "name": "DismissMutationProgressIndicatorMutation",
    "operationKind": "mutation",
    "text": "mutation DismissMutationProgressIndicatorMutation(\n  $input: DismissMutationProgressIndicatorInput!\n) {\n  dismissMutationProgressIndicator(input: $input) {\n    success\n    errors\n    profileGroup {\n      id\n      generateDraftsProgress\n      sendDraftsProgress\n      sendRemindersProgress\n      matchPaymentsProgress\n      generateGoogleRegistersProgress\n      readInRegistersProgress\n      bulkUploadNewRegistersProgress\n      generateRegistersForNewBlockProgress\n      sendBulkCommunicationProgress\n      sendTeacherBulkCommunicationProgress\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d6efcc104f49cfb01ef63949f01e45e9";

export default node;
