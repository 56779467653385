import React, { useCallback, useMemo, useRef, Suspense } from "react";
import type {
    ReactElement,
    ComponentProps,
    Dispatch,
    MutableRefObject,
} from "react";

import emoji from "emoji-dictionary";
import {
    Text,
    VStack,
    HStack,
    Button,
    useToast,
    FlatList,
    Box,
} from "native-base";
import { usePreloadedQuery, useMutation, ConnectionHandler } from "react-relay";
import type { PreloadedQuery } from "react-relay";

import type {
    ReducerValues as SchoolScreenReducerValues,
    ReducerTypes as SchoolScreenReducerTypes,
} from "../../screens/schools/SchoolScreen";
import type { NavigationProps as SchoolScreenNavigationProps } from "../../screens/schools/SchoolScreen";
import LessonStage from "../ListItems/LessonStage";
import CreateLessonStageModal from "../Modals/CreateLessonStageModal";
import LoadingBlobs from "pianofunclub-shared/components/Animations/LoadingBlobs";
import ListEmptyBanner from "pianofunclub-shared/components/ListItems/ListEmptyBanner";
import Select from "pianofunclub-shared/components/NativeBaseExtended/Select";
import ToastAlert from "pianofunclub-shared/components/NativeBaseExtended/ToastAlert";
import {
    AddCircleIcon,
    RestartIcon,
} from "pianofunclub-shared/components/Other/Icons";

import type {
    LoadLessonBlocksQuery,
    LoadLessonBlocksQuery$data,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/LoadLessonBlocksQuery.graphql";
import { load_lesson_blocks } from "pianofunclub-shared/relay/graphql/registers/LoadLessonBlocks";
import type {
    CreateLessonStageMutation,
    CreateLessonStageMutation$data,
} from "pianofunclub-shared/relay/graphql/schools/__generated__/CreateLessonStageMutation.graphql";
import type {
    DeleteLessonStageMutation,
    DeleteLessonStageMutation$data,
} from "pianofunclub-shared/relay/graphql/schools/__generated__/DeleteLessonStageMutation.graphql";
import type {
    EditLessonStageMutation,
    EditLessonStageMutation$data,
} from "pianofunclub-shared/relay/graphql/schools/__generated__/EditLessonStageMutation.graphql";
import { create_lesson_stage } from "pianofunclub-shared/relay/graphql/schools/CreateLessonStage";
import { delete_lesson_stage } from "pianofunclub-shared/relay/graphql/schools/DeleteLessonStage";
import { edit_lesson_stage } from "pianofunclub-shared/relay/graphql/schools/EditLessonStage";

import type { Mutable } from "pianofunclub-shared/types";
import { BLOCKS } from "pianofunclub-shared/utils/constants";
import type { State, Action } from "pianofunclub-shared/utils/reducers";

type VStackProps = ComponentProps<typeof VStack>;

export type LessonStageData = NonNullable<
    NonNullable<LoadLessonBlocksQuery$data["lessonStages"]>
>["edges"];

interface Props extends VStackProps {
    dispatchState: Dispatch<
        Action<SchoolScreenReducerValues, SchoolScreenReducerTypes>
    >;
    loadLessonBlocksQueryReference:
        | PreloadedQuery<LoadLessonBlocksQuery, Record<string, unknown>>
        | undefined
        | null;
    navigation: SchoolScreenNavigationProps;
    refreshHandler: () => void;
    schoolName: string;
    startingYears: {
        label: string;
        value: number;
    }[];
    state: State<SchoolScreenReducerValues>;
}

interface ContentProps extends Props {
    createLessonStageModalKey: MutableRefObject<number>;
    loadLessonBlocksQueryReference: PreloadedQuery<
        LoadLessonBlocksQuery,
        Record<string, unknown>
    >;
}

const SchoolRatesContent = (props: ContentProps): ReactElement => {
    const {
        createLessonStageModalKey,
        dispatchState,
        loadLessonBlocksQueryReference,
        schoolName,
        state,
    } = props;

    const data = usePreloadedQuery(
        load_lesson_blocks,
        loadLessonBlocksQueryReference,
    );

    const lessonStages = useMemo(() => {
        // need to sort the lesson stages
        if (data?.lessonStages?.edges) {
            const unsortedLessonStages = data.lessonStages.edges as Mutable<
                typeof data.lessonStages.edges
            >;
            const sortedLessonStages = unsortedLessonStages
                .slice()
                .sort((a, b) => (a?.node?.stage ?? 0) - (b?.node?.stage ?? -1));
            return sortedLessonStages;
        } else {
            return [];
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.lessonStages?.edges]);

    const availableStages = useMemo(() => {
        const stagesArray = lessonStages.map(
            (item) => item?.node?.stage ?? null,
        );
        const availableStagesArray = Array.from(Array(20).keys());
        return availableStagesArray.filter(
            (item) => !stagesArray.includes(item),
        );
    }, [lessonStages]);

    const toast = useToast();

    const [commitCreateLessonStage, createLessonStageInFlight] =
        useMutation<CreateLessonStageMutation>(create_lesson_stage);

    const createLessonStage = useCallback(
        (
            variables: {
                costPerLesson: number;
                lessonDuration: number;
                lessonType: string;
                stage: number | undefined;
            },
            onComplete?: () => void,
        ) => {
            // update the lesson stage query connection if it exists
            const connections = [
                ConnectionHandler.getConnectionID(
                    "root",
                    "LoadLessonStages_query_lessonStages",
                    {
                        school: schoolName,
                        startingYear: state.values.startingYear,
                        block: Number(state.values.block),
                    },
                ),
            ];
            const createLessonStageConfig = {
                variables: {
                    connections: data.lessonStages?.__id
                        ? connections.concat(data.lessonStages?.__id)
                        : connections,
                    input: {
                        ...variables,
                        school: schoolName,
                        startingYear: state.values.startingYear,
                        block: state.values.block,
                    },
                },
                onCompleted: (response: CreateLessonStageMutation$data) => {
                    if (response?.createLessonStage?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={"Lesson stage created"}
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't create lesson stage"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                    dispatchState({
                        input: "showCreateLessonStageModal",
                        value: false,
                    });
                    onComplete?.();
                },
            };

            commitCreateLessonStage(createLessonStageConfig);
        },
        [
            commitCreateLessonStage,
            data?.lessonStages?.__id,
            dispatchState,
            schoolName,
            state.values.block,
            state.values.startingYear,
            toast,
        ],
    );

    const commitEditLessonStage =
        useMutation<EditLessonStageMutation>(edit_lesson_stage)[0];

    const editLessonStage = useCallback(
        (variables: {
            costPerLesson?: number;
            lessonDuration?: number;
            lessonStageId: string;
            lessonType?: string;
        }) => {
            const editLessonStageConfig = {
                variables: {
                    input: variables,
                },
                onCompleted: (response: EditLessonStageMutation$data) => {
                    if (response?.editLessonStage?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={"Lesson stage updated"}
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't update lesson stage"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitEditLessonStage(editLessonStageConfig);
        },
        [commitEditLessonStage, toast],
    );

    const [commitDeleteLessonStage, deleteLessonStageInFlight] =
        useMutation<DeleteLessonStageMutation>(delete_lesson_stage);

    const deleteLessonStage = useCallback(
        (variables: { lessonStageId: string }, onComplete?: () => void) => {
            // update the lesson stage query connection if it exists
            const connections = [
                ConnectionHandler.getConnectionID(
                    "root",
                    "LoadLessonStages_query_lessonStages",
                    {
                        school: schoolName,
                        startingYear: state.values.startingYear,
                        block: Number(state.values.block),
                    },
                ),
            ];
            const deleteLessonStageConfig = {
                variables: {
                    connections: data.lessonStages?.__id
                        ? connections.concat(data.lessonStages?.__id)
                        : connections,
                    input: variables,
                },
                onCompleted: (response: DeleteLessonStageMutation$data) => {
                    if (response?.deleteLessonStage?.success) {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="error"
                                    title={"Lesson stage deleted"}
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={"Couldn't deleted lesson stage"}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                    onComplete?.();
                },
            };

            commitDeleteLessonStage(deleteLessonStageConfig);
        },
        [
            commitDeleteLessonStage,
            data.lessonStages?.__id,
            schoolName,
            state.values.block,
            state.values.startingYear,
            toast,
        ],
    );

    const renderItem = useCallback(
        ({
            index,
            item,
        }: {
            index: number;
            item: LessonStageData[0];
        }): ReactElement | null => {
            if (item?.node) {
                return (
                    <LessonStage
                        data={item.node}
                        deleteLessonStage={deleteLessonStage}
                        deleteLessonStageInFlight={deleteLessonStageInFlight}
                        editLessonStage={editLessonStage}
                        mt={index === 0 ? 6 : 0}
                    />
                );
            } else {
                return null;
            }
        },
        [deleteLessonStage, deleteLessonStageInFlight, editLessonStage],
    );

    const renderListEmptyBanner = useCallback(() => {
        return (
            <ListEmptyBanner explainer={"No rates found for this block"}>
                <Text fontSize="6xl">{emoji.getUnicode("neutral_face")}</Text>
            </ListEmptyBanner>
        );
    }, []);

    return (
        <>
            <FlatList
                contentContainerStyle={{
                    flexGrow: 1,
                }}
                data={lessonStages}
                flex={1}
                keyExtractor={(item, index) =>
                    item?.node?.id?.toString() ?? index.toString()
                }
                ListEmptyComponent={renderListEmptyBanner}
                refreshing={false}
                renderItem={renderItem}
            />
            <CreateLessonStageModal
                key={createLessonStageModalKey.current}
                availableStages={availableStages}
                createLessonStage={createLessonStage}
                createLessonStageInFlight={createLessonStageInFlight}
                setShowModal={(isHidden) =>
                    dispatchState({
                        input: "showCreateLessonStageModal",
                        value: isHidden,
                    })
                }
                showModal={state.values.showCreateLessonStageModal}
            />
        </>
    );
};

const SchoolRates = (props: Props): ReactElement => {
    const {
        dispatchState,
        loadLessonBlocksQueryReference,
        navigation,
        refreshHandler,
        startingYears,
        state,
    } = props;

    const createLessonStageModalKey = useRef(Math.random());

    const renderHeader = useCallback(() => {
        return (
            <VStack bg="surface.100" mx="-5" px="5" space="4">
                <HStack justifyContent="space-between">
                    <HStack space="4">
                        <Select
                            borderRadius="2xl"
                            fontSize="md"
                            onValueChange={(itemValue) => {
                                const value = parseInt(itemValue);

                                if (value > state.values.startingYear) {
                                    dispatchState({
                                        input: "block",
                                        value: 1,
                                    });
                                    navigation.setParams({
                                        block: 1,
                                    });
                                }

                                dispatchState({
                                    input: "startingYear",
                                    value: value,
                                });
                                navigation.setParams({
                                    startingYear: value,
                                });
                            }}
                            placeholder="Select year"
                            selectedValue={String(state.values.startingYear)}
                            width="40"
                        >
                            {startingYears.map((item) => {
                                return (
                                    <Select.Item
                                        key={item.value}
                                        actionSheetLabel={item.label}
                                        value={String(item.value)}
                                    />
                                );
                            })}
                        </Select>
                        <Select
                            borderRadius="2xl"
                            fontSize="md"
                            onValueChange={(itemValue) => {
                                dispatchState({
                                    input: "block",
                                    value: parseInt(itemValue),
                                });
                                navigation.setParams({
                                    block: parseInt(itemValue),
                                });
                            }}
                            placeholder="Select block"
                            selectedValue={String(state.values.block)}
                            width="40"
                        >
                            {BLOCKS.map((item) => {
                                return (
                                    <Select.Item
                                        key={item.value}
                                        actionSheetLabel={item.label}
                                        value={item.value}
                                    />
                                );
                            })}
                        </Select>
                        <Button
                            bg="surface.400"
                            colorScheme="surface"
                            leftIcon={<RestartIcon size="5" />}
                            onPress={() => refreshHandler()}
                            p="3"
                        />
                    </HStack>
                    <Button
                        _hover={{ bg: "primary.500" }}
                        _pressed={{ bg: "primary.600" }}
                        _text={{ fontSize: "17" }}
                        bg="primary.400"
                        leftIcon={<AddCircleIcon size="md" />}
                        onPress={() => {
                            createLessonStageModalKey.current += 1;
                            dispatchState({
                                input: "showCreateLessonStageModal",
                                value: true,
                            });
                        }}
                        px="4"
                        shadow={1}
                    >
                        Add Lesson Stage
                    </Button>
                </HStack>
            </VStack>
        );
    }, [
        dispatchState,
        navigation,
        refreshHandler,
        startingYears,
        state.values.block,
        state.values.startingYear,
    ]);

    return (
        <Box flex={1}>
            {renderHeader()}
            {loadLessonBlocksQueryReference != null ? (
                <Suspense
                    fallback={<LoadingBlobs>Loading Rates...</LoadingBlobs>}
                >
                    <SchoolRatesContent
                        {...props}
                        createLessonStageModalKey={createLessonStageModalKey}
                        dispatchState={dispatchState}
                        loadLessonBlocksQueryReference={
                            loadLessonBlocksQueryReference
                        }
                        state={state}
                    />
                </Suspense>
            ) : (
                <LoadingBlobs>Loading Rates...</LoadingBlobs>
            )}
        </Box>
    );
};

export default React.memo(SchoolRates);
