import React, { Suspense, useEffect, useCallback } from "react";
import type { FC, ReactElement } from "react";

import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import type { BottomTabNavigationProp } from "@react-navigation/bottom-tabs";
import type { NavigatorScreenParams } from "@react-navigation/native";
import { useTheme, HStack } from "native-base";
import { usePreloadedQuery } from "react-relay";
import type { PreloadedQuery } from "react-relay";

import { useAuth } from "pianofunclub-shared/providers/AuthProvider";

import DevBanner from "../components/General/DevBanner";
import LoadingScreen from "pianofunclub-shared/screens/LoadingScreen";

import type { MeQuery } from "pianofunclub-shared/relay/graphql/general/__generated__/MeQuery.graphql";
import { me } from "pianofunclub-shared/relay/graphql/general/Me";

import { getTabIcon } from "pianofunclub-shared/utils/extractors";

import AccountsNavigator from "./AccountsNavigator";
import type { AccountsStackNavigatorParamList } from "./AccountsNavigator";
import InvoicingNavigator from "./InvoicingNavigator";
import type { InvoicingStackNavigatorParamList } from "./InvoicingNavigator";
import PayrollNavigator from "./PayrollNavigator";
import type { PayrollNavigatorParamList } from "./PayrollNavigator";
import ProfileNavigator from "./ProfileNavigator";
import type { ProfileStackNavigatorParamList } from "./ProfileNavigator";
import RegistersNavigator from "./RegistersNavigator";
import type { RegistersStackNavigatorParamList } from "./RegistersNavigator";
import SchoolsNavigator from "./SchoolsNavigator";
import type { SchoolsStackNavigatorParamList } from "./SchoolsNavigator";
import TeacherRegistersNavigator from "./TeacherRegistersNavigator";
import type { TeacherRegistersStackNavigatorParamList } from "./TeacherRegistersNavigator";
import TeacherTimetableNavigator from "./TeacherTimetableNavigator";
import type { TeacherTimetableStackNavigatorParamList } from "./TeacherTimetableNavigator";
import WaitingListNavigator from "./WaitingListNavigator";
import type { WaitingListNavigatorParamList } from "./WaitingListNavigator";

export type HubNavigatorParamList = {
    AccountsHubTab: NavigatorScreenParams<AccountsStackNavigatorParamList>;
    InvoicingHubTab: NavigatorScreenParams<InvoicingStackNavigatorParamList>;
    PayrollHubTab: NavigatorScreenParams<PayrollNavigatorParamList>;
    ProfileHubTab: NavigatorScreenParams<ProfileStackNavigatorParamList>;
    RegistersHubTab: NavigatorScreenParams<RegistersStackNavigatorParamList>;
    SchoolsHubTab: NavigatorScreenParams<SchoolsStackNavigatorParamList>;
    TeacherRegistersHubTab: NavigatorScreenParams<TeacherRegistersStackNavigatorParamList>;
    TeacherTimetableHubTab: NavigatorScreenParams<TeacherTimetableStackNavigatorParamList>;
    WaitingListHubTab: NavigatorScreenParams<WaitingListNavigatorParamList>;
};

export type HubTabNavigatorProps<T extends keyof HubNavigatorParamList> =
    BottomTabNavigationProp<HubNavigatorParamList, T>;

interface Props {
    meQueryReference: PreloadedQuery<MeQuery, Record<string, unknown>>;
}

const HubTabNavigator = createBottomTabNavigator<HubNavigatorParamList>();

const HubNavigator = (props: Props): ReactElement => {
    const { meQueryReference } = props;

    const { authState, setUser, user } = useAuth();
    const { colors } = useTheme();

    const my_user = usePreloadedQuery<MeQuery>(me, meQueryReference);

    useEffect(() => {
        setUser(my_user?.me);
    }, [my_user, setUser]);

    const getNavigators = useCallback(() => {
        switch (authState.accountType) {
            case "STAFF":
                return (
                    <>
                        <HubTabNavigator.Screen
                            component={AccountsNavigator}
                            name="AccountsHubTab"
                            options={{
                                title: "Accounts",
                                tabBarLabel: "Accounts",
                                tabBarIconStyle: {
                                    flex: 1,
                                    minWidth: 100,
                                },
                            }}
                        />
                        <HubTabNavigator.Screen
                            component={RegistersNavigator}
                            name="RegistersHubTab"
                            options={{
                                title: "Registers",
                                tabBarLabel: "Registers",
                            }}
                        />
                        <HubTabNavigator.Screen
                            component={InvoicingNavigator}
                            name="InvoicingHubTab"
                            options={{
                                title: "Invoicing",
                                tabBarLabel: "Invoicing",
                            }}
                        />
                        <HubTabNavigator.Screen
                            component={SchoolsNavigator}
                            name="SchoolsHubTab"
                            options={{
                                title: "Schools",
                                tabBarLabel: "Schools",
                            }}
                        />
                        <HubTabNavigator.Screen
                            component={WaitingListNavigator}
                            name="WaitingListHubTab"
                            options={{
                                title: "Waiting List",
                                tabBarLabel: "Waiting List",
                            }}
                        />
                        <HubTabNavigator.Screen
                            component={PayrollNavigator}
                            name="PayrollHubTab"
                            options={{
                                title: "Partner Payouts",
                                tabBarLabel: "Partner Payouts",
                            }}
                        />
                    </>
                );
            default:
                return (
                    <>
                        <HubTabNavigator.Screen
                            component={TeacherRegistersNavigator}
                            name="TeacherRegistersHubTab"
                            options={{
                                title: "Registers",
                                tabBarLabel: "Registers",
                                tabBarIconStyle: {
                                    flex: 1,
                                    minWidth: 60,
                                    marginLeft: 40,
                                },
                            }}
                        />
                        <HubTabNavigator.Screen
                            component={TeacherTimetableNavigator}
                            name="TeacherTimetableHubTab"
                            options={{
                                title: "Timetable",
                                tabBarLabel: "Timetable",
                            }}
                        />
                    </>
                );
        }
    }, [authState.accountType]);

    return (
        <HubTabNavigator.Navigator
            initialRouteName={
                user?.profile?.accountType === "TEACHER"
                    ? "TeacherRegistersHubTab"
                    : "AccountsHubTab"
            }
            screenOptions={({ route }) => ({
                tabBarIcon: ({ color }) => {
                    return (
                        <HStack space="10">
                            {getTabIcon(route.name, color)}
                        </HStack>
                    );
                },
                headerShown: false,
                tabBarLabelPosition: "beside-icon",
                tabBarActiveTintColor: colors.primary["400"],
                tabBarInactiveTintColor: colors.surface["300"],
                tabBarStyle: {
                    borderWidth: 0,
                    backgroundColor: colors.primary["800"],
                    borderBottomLeftRadius: 25,
                    borderBottomRightRadius: 25,
                    minHeight: 70,
                    position: "absolute",
                    top: 0,
                    justifyContent: "flex-start",
                    paddingRight: 50,
                },
                tabBarItemStyle: {
                    flex: 0,
                    marginLeft: 50,
                    alignItems: "center",
                },
                tabBarLabelStyle: {
                    fontSize: 18,
                    fontFamily: "Poppins-SemiBold",
                    paddingLeft:
                        route.name === "AccountsHubTab" ||
                        route.name === "SchoolsHubTab"
                            ? 8
                            : route.name === "RegistersHubTab"
                              ? 1
                              : 0,
                },
                cardStyle: { backgroundColor: "#f1f1f1" },
                upperCaseLabel: false,
                lazy: false,
            })}
        >
            {getNavigators()}
            <HubTabNavigator.Screen
                component={ProfileNavigator}
                name="ProfileHubTab"
                options={{
                    title: "Settings",
                    tabBarLabel: user?.firstName ?? "Settings",
                    tabBarItemStyle: {
                        flex: 0,
                        marginLeft: "auto",
                        alignItems: "center",
                        justifyContent: "flex-end",
                    },
                    tabBarLabelStyle: {
                        paddingLeft: 4,
                        fontSize: 18,
                        fontFamily: "Poppins-SemiBold",
                    },
                }}
            />
        </HubTabNavigator.Navigator>
    );
};

const HubNavigatorWrapper: FC = () => {
    const { loadMeQuery, meQueryReference } = useAuth();

    useEffect(() => {
        loadMeQuery({}, { fetchPolicy: "store-and-network" });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!meQueryReference) {
        return <LoadingScreen />;
    }

    return (
        <Suspense fallback={<LoadingScreen />}>
            {process.env.REACT_APP_ENV !== "prod" ? <DevBanner /> : null}
            <HubNavigator meQueryReference={meQueryReference} />
        </Suspense>
    );
};

export default HubNavigatorWrapper;
