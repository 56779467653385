/* eslint-disable no-case-declarations */
import { format } from "date-fns";

import type { LessonDataType } from "pianofunclub-shared/components/Registers/TeacherRegisters";

import type { LoadAccounts_query_profiles$data } from "pianofunclub-shared/relay/graphql/accounts/__generated__/LoadAccounts_query_profiles.graphql";
import type { LoadEnrolments_query_enrolments$data } from "pianofunclub-shared/relay/graphql/accounts/__generated__/LoadEnrolments_query_enrolments.graphql";
import type { InvoicingHeadlineDataFragment_query$data } from "pianofunclub-shared/relay/graphql/fragments/__generated__/InvoicingHeadlineDataFragment_query.graphql";
import type { LoadInvoices_query_invoices$data } from "pianofunclub-shared/relay/graphql/invoicing/__generated__/LoadInvoices_query_invoices.graphql";
import type { LoadPayslips_query_payslips$data } from "pianofunclub-shared/relay/graphql/payslips/__generated__/LoadPayslips_query_payslips.graphql";
import type { LoadLessonBlocks_query_lessonBlocks$data } from "pianofunclub-shared/relay/graphql/registers/__generated__/LoadLessonBlocks_query_lessonBlocks.graphql";
import type { LoadSchools_query_schools$data } from "pianofunclub-shared/relay/graphql/schools/__generated__/LoadSchools_query_schools.graphql";

import {
    PAYSLIP_STATUS,
    TEACHING_DAYS,
} from "pianofunclub-shared/utils/constants";
import {
    discountTextConverter,
    schoolTypeConverter,
    titleCaseConverter,
} from "pianofunclub-shared/utils/converters";
import {
    formattedDayMonthYear,
    listWithAndConverter,
    schoolYearConverter,
    combineWaitingListNotes,
} from "pianofunclub-shared/utils/converters";
import { getFullName } from "pianofunclub-shared/utils/extractors";

export const invoicingHubTableCsvConverter = (
    data: NonNullable<LoadInvoices_query_invoices$data["invoices"]>["edges"],
): string => {
    const headers =
        "Account #,Parent Name,Parent Email,Parent Phone #,Child(s) Name,Amount,Status,Nudge 1, Nudge 2, Nudge 3";

    const rows = data.map((invoice) => {
        if (invoice?.node) {
            const {
                node: {
                    assignedTo,
                    reminder1SentTimestamp,
                    reminder2SentTimestamp,
                    reminder3SentTimestamp,
                    status,
                    totalAmount,
                },
            } = invoice;

            const childrenFirstNames = assignedTo?.children.edges
                .map((item) => {
                    if (!item?.node?.user) {
                        return;
                    }

                    return item.node.user.firstName;
                })
                .filter((x): x is NonNullable<typeof x> => Boolean(x));

            return `''${assignedTo?.accountNumber ?? ""},${getFullName(
                assignedTo?.user.firstName,
                assignedTo?.user.lastName,
            )},${assignedTo?.user.email ?? ""},${
                assignedTo?.phoneNumber ?? ""
            },${
                childrenFirstNames?.length
                    ? listWithAndConverter(childrenFirstNames.join(", "))
                    : ""
            },£${totalAmount ?? ""},${titleCaseConverter(status)},${
                reminder1SentTimestamp ? "Sent" : ""
            },${reminder2SentTimestamp ? "Sent" : ""},${
                reminder3SentTimestamp ? "Sent" : ""
            }`;
        }
    });

    return headers + "\n" + rows.join("\n");
};

export const accountsHubTableCsvConverter = (
    data: NonNullable<LoadAccounts_query_profiles$data["profiles"]>["edges"],
    accountType: string,
): string => {
    switch (accountType) {
        case "PARENT":
            const parentHeaders = "Account #,Name,Email,Phone #,Status";
            const parentRows = data.map((profile) => {
                if (profile?.node) {
                    const {
                        node: {
                            accountNumber,
                            phoneNumber,
                            status,
                            user: { email, firstName, lastName },
                        },
                    } = profile;
                    return `''${accountNumber ?? ""},${getFullName(
                        firstName,
                        lastName,
                    )},${email},'${phoneNumber ?? ""},${titleCaseConverter(
                        status,
                    )}`;
                }
            });
            return parentHeaders + "\n" + parentRows.join("\n");
        case "PUPIL":
            const pupilHeaders =
                "Parent Account #,Pupil Name,Parent Name,School,School Year,Discount,Status";
            const pupilRows = data.map((profile) => {
                if (profile?.node) {
                    const {
                        node: {
                            discountCategory,
                            discountPercentage,
                            primaryParent,
                            school,
                            schoolYear,
                            status,
                            user: { firstName, lastName },
                        },
                    } = profile;
                    return `''${
                        primaryParent?.accountNumber ?? ""
                    },${getFullName(firstName, lastName)},${getFullName(
                        primaryParent?.user.firstName,
                        primaryParent?.user.lastName,
                    )},${
                        school?.name ? titleCaseConverter(school?.name) : ""
                    },${schoolYearConverter(
                        schoolYear,
                    )},${discountTextConverter(
                        discountCategory,
                        discountPercentage,
                    )},${titleCaseConverter(status)}`;
                }
            });
            return pupilHeaders + "\n" + pupilRows.join("\n");
        case "TEACHER":
            const teacherHeaders =
                "Account #,Name,Email,Phone #,DBS Status,Status";
            const teacherRows = data.map((profile) => {
                if (profile?.node) {
                    const {
                        node: {
                            accountNumber,
                            dbsNumber,
                            phoneNumber,
                            status,
                            user: { email, firstName, lastName },
                        },
                    } = profile;
                    return `''${accountNumber ?? ""},${getFullName(
                        firstName,
                        lastName,
                    )},${email},'${phoneNumber ?? ""},'${
                        dbsNumber ?? ""
                    },${titleCaseConverter(status)}`;
                }
            });
            return teacherHeaders + "\n" + teacherRows.join("\n");
        default:
            const staffHeaders =
                "Account #,Name,Email,Phone #,Admin Privileges";
            const staffRows = data.map((profile) => {
                if (profile?.node) {
                    const {
                        node: {
                            accountNumber,
                            phoneNumber,
                            user: { email, firstName, isSuperuser, lastName },
                        },
                    } = profile;
                    return `''${accountNumber ?? ""},${getFullName(
                        firstName,
                        lastName,
                    )},${email},'${phoneNumber ?? ""},${
                        isSuperuser ? "Maximum" : "Normal"
                    }`;
                }
            });
            return staffHeaders + "\n" + staffRows.join("\n");
    }
};

export const schoolsHubTableCsvConverter = (
    data: NonNullable<LoadSchools_query_schools$data["schools"]>["edges"],
): string => {
    const headers =
        "Name,Type,Head Teacher,Office Contact,Active Teachers,Filled Slots,Available Slots,Pupils on Waiting List,Status";
    const rows = data.map((school) => {
        if (school?.node) {
            const {
                node: {
                    archived,
                    headTeacher,
                    mainOfficeContact,
                    name,
                    numberOfActiveTeachers,
                    numberOfFilledLessonSlots,
                    numberOfFreeLessonSlots,
                    numberOfPupilsOnWaitingList,
                    schoolType,
                },
            } = school;
            return `${titleCaseConverter(name)},${schoolTypeConverter(schoolType)},${getFullName(
                headTeacher?.user.firstName,
                headTeacher?.user.lastName,
            )},${getFullName(
                mainOfficeContact?.user.firstName,
                mainOfficeContact?.user.lastName,
            )},${numberOfActiveTeachers ?? 0},${
                numberOfFilledLessonSlots ?? 0
            },${numberOfFreeLessonSlots ?? 0},${
                numberOfPupilsOnWaitingList ?? 0
            },${archived ? "Archived" : "Active"}`;
        }
    });
    return headers + "\n" + rows.join("\n");
};

export const waitingListHubTableCsvConverter = (
    data: NonNullable<
        LoadEnrolments_query_enrolments$data["enrolments"]
    >["edges"],
): string => {
    const headers =
        "Pupil Name,Parent Name,Parent Email,Parent Phone #,School Name,Position,Year Group,Class,Instrument,Stage,Notes";
    const rows = data.map((enrolment) => {
        if (enrolment?.node) {
            const {
                node: {
                    adminNotes,
                    instrument,
                    lessonBlockNotes,
                    lessonStage,
                    parentEmail,
                    parentName,
                    parentPhoneNumber,
                    positionInWaitingList,
                    pupilFirstName,
                    pupilLastName,
                    pupilNotes,
                    schoolClass,
                    schoolName,
                    schoolYear,
                },
            } = enrolment;

            return `${getFullName(pupilFirstName, pupilLastName)},${parentName},${parentEmail},${parentPhoneNumber},${schoolName ?? ""},${positionInWaitingList ?? ""},${schoolYearConverter(schoolYear)},${schoolClass ?? ""},${instrument ?? ""},${lessonStage == "-1" ? "Any Stage" : (lessonStage ?? "")},${combineWaitingListNotes(
                pupilNotes,
                adminNotes,
                lessonBlockNotes,
            ).replace(/\n/g, " ")}`;
        }
    });
    return headers + "\n" + rows.join("\n");
};

export const schoolLessonsTableCsvConverter = (
    data: NonNullable<
        LoadLessonBlocks_query_lessonBlocks$data["lessonBlocks"]
    >["edges"],
): string => {
    const headers = "Time,Teacher,Type,Stage,Instrument,Pupil,Year";
    const rows = data.map((lessonBlock) => {
        if (lessonBlock?.node) {
            const {
                node: {
                    instrument,
                    lessonDay,
                    lessonStage,
                    lessonTime: lessonTimestamp,
                    pupil,
                    teacher,
                },
            } = lessonBlock;
            const lessonDayString =
                lessonDay !== null
                    ? TEACHING_DAYS.find(
                          (item) => Number(item.value) === lessonDay,
                      )?.label.slice(0, 3)
                    : undefined;
            let lessonTime: Date | undefined;
            if (lessonTimestamp) {
                const [hours, minutes, seconds] = lessonTimestamp.split(":");
                lessonTime = new Date(
                    1970,
                    0,
                    1,
                    parseInt(hours),
                    parseInt(minutes),
                    parseInt(seconds),
                );
            }
            return `'${lessonDayString ? lessonDayString + " " : ""}${
                lessonTime ? format(lessonTime, "H:mm") : ""
            }${
                lessonTime && lessonStage?.lessonDuration
                    ? "-" +
                      format(
                          new Date(
                              lessonTime.getTime() +
                                  lessonStage.lessonDuration * 60000,
                          ),
                          "H:mm",
                      )
                    : ""
            },${getFullName(teacher?.user.firstName, teacher?.user.lastName)},${
                lessonStage
                    ? lessonStage?.lessonType === "INDIVIDUAL"
                        ? "1-on-1"
                        : lessonStage?.lessonType === "PAIRED"
                          ? "Paired"
                          : "Group"
                    : ""
            },${
                typeof lessonStage?.stage === "number"
                    ? lessonStage?.stage?.toString()
                    : ""
            },${
                instrument ? (titleCaseConverter(instrument.name) ?? "") : ""
            },${getFullName(
                pupil?.user.firstName,
                pupil?.user.lastName,
            )},${schoolYearConverter(pupil?.schoolYear)}`;
        }
    });
    return headers + "\n" + rows.join("\n");
};

export const payrollTableCsvConverter = (
    data: NonNullable<LoadPayslips_query_payslips$data["payslips"]>["edges"],
): string => {
    const headers =
        "Account #,Teacher Name,Total Amount Due,Amount Adjusted,Paid From,Paid To,Status";

    const rows = data.map((payslip) => {
        if (payslip?.node) {
            const {
                node: {
                    amountAdjusted,
                    amountDue,
                    paidFromDate,
                    paidToDate,
                    status,
                    teacher,
                },
            } = payslip;

            return `${teacher?.accountNumber},${getFullName(
                teacher?.user.firstName,
                teacher?.user.lastName,
            )},${amountDue},${amountAdjusted},${formattedDayMonthYear(
                new Date(paidFromDate ?? ""),
            )},${formattedDayMonthYear(new Date(paidToDate ?? ""))},${
                PAYSLIP_STATUS.find((s) => s.value === status)?.label
            }`;
        }
    });
    return headers + "\n" + rows.join("\n");
};

export const teacherRegistersTableCsvConverter = (
    data: NonNullable<
        LoadLessonBlocks_query_lessonBlocks$data["lessonBlocks"]
    >["edges"],
    datesToRender: (LessonDataType | undefined)[],
): string => {
    const headers = `Time,School,Type,Stage,Instrument,Pupil,Year,${datesToRender
        .map((lesson) => {
            if (lesson?.isReplacementLesson && !lesson?.actualDate)
                return "Replacement (No Date)";

            if (!lesson?.actualDate) return "(No Date)";

            if (lesson?.isReplacementLesson) {
                return `Replacement ${format(lesson.actualDate, "dd/MM")}`;
            } else {
                return format(lesson.actualDate, "dd/MM");
            }
        })
        .join(",")}`;
    const rows = data.map((lessonBlock) => {
        if (lessonBlock?.node) {
            const {
                node: {
                    instrument,
                    lessons,
                    lessonStage,
                    lessonTime: lessonTimestamp,
                    pupil,
                    school,
                },
            } = lessonBlock;
            let lessonTime: Date | undefined;
            if (lessonTimestamp) {
                const [hours, minutes, seconds] = lessonTimestamp.split(":");
                lessonTime = new Date(
                    1970,
                    0,
                    1,
                    parseInt(hours),
                    parseInt(minutes),
                    parseInt(seconds),
                );
            }
            return `'${lessonTime ? format(lessonTime, "H:mm") : ""}${
                lessonTime && lessonStage?.lessonDuration
                    ? "-" +
                      format(
                          new Date(
                              lessonTime.getTime() +
                                  lessonStage.lessonDuration * 60000,
                          ),
                          "H:mm",
                      )
                    : ""
            },${titleCaseConverter(school?.name) ?? ""},${
                lessonStage
                    ? lessonStage?.lessonType === "INDIVIDUAL"
                        ? "1-on-1"
                        : lessonStage?.lessonType === "PAIRED"
                          ? "Paired"
                          : "Group"
                    : ""
            },${
                typeof lessonStage?.stage === "number"
                    ? lessonStage?.stage?.toString()
                    : ""
            },${
                instrument ? (titleCaseConverter(instrument.name) ?? "") : ""
            },${getFullName(
                pupil?.user.firstName,
                pupil?.user.lastName,
            )},${schoolYearConverter(pupil?.schoolYear)},${
                lessons.edges
                    ?.map((lesson) => {
                        return lesson?.node?.status
                            ? lesson?.node?.status === "BLANK"
                                ? ""
                                : (titleCaseConverter(
                                      lesson?.node?.status.replace("_", " "),
                                  ) ?? "")
                            : "";
                    })
                    ?.join(",") ?? ""
            }`;
        }
    });
    return headers + "\n" + rows.join("\n");
};

export const missingPupilsCsvConverter = (
    data: NonNullable<
        NonNullable<
            NonNullable<
                InvoicingHeadlineDataFragment_query$data["me"]
            >["profile"]
        >["lessonBlocksNotInvoicedFor"]
    >,
): string => {
    const headers = "Pupil Name,School,Year Group";

    const rows = data.map((lessonBlock) => {
        if (lessonBlock) {
            return `${getFullName(
                lessonBlock.pupil?.user.firstName,
                lessonBlock.pupil?.user.lastName,
            )},${lessonBlock.school?.name},${schoolYearConverter(lessonBlock.pupil?.schoolYear)}`;
        }
    });
    return headers + "\n" + rows.join("\n");
};
