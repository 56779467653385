import React, {
    useReducer,
    useCallback,
    useMemo,
    useRef,
    useState,
} from "react";
import type { ReactElement } from "react";

import { format } from "date-fns";
import { Modal, VStack, Text, ScrollView, Button, useToast } from "native-base";
import { useMutation } from "react-relay";

import ButtonDebounced from "pianofunclub-shared/components/Buttons/ButtonDebounced";
import TextInput from "pianofunclub-shared/components/Inputs/TextInput";
import TeacherMultiSelectModal from "pianofunclub-shared/components/Modals/TeacherMultiSelectModal";
import ToastAlert from "pianofunclub-shared/components/NativeBaseExtended/ToastAlert";
import { ChevronLeftIcon } from "pianofunclub-shared/components/Other/Icons";

import type {
    SendBulkCommunicationMutation,
    SendBulkCommunicationMutation$data,
} from "pianofunclub-shared/relay/graphql/accounts/__generated__/SendBulkCommunicationMutation.graphql";
import { send_bulk_communication } from "pianofunclub-shared/relay/graphql/accounts/SendBulkCommunication";

import {
    listToStringWithCommasConverter,
    listWithAndConverter,
    messagePreviewConverter,
} from "pianofunclub-shared/utils/converters";
import { getErroredBulkCommunicationLabel } from "pianofunclub-shared/utils/extractors";
import { createReducer } from "pianofunclub-shared/utils/reducers";

interface Props {
    hideModal: () => void;
    onSendFinish?: (success: boolean) => void;
    onSendStart?: () => void;
    showModal: boolean;
    teacherId?: string;
    teacherName?: string;
}

type ReducerValues = {
    hasSelectedAllTeachers: boolean;
    messageString: string;
    selectedTeacherIds: string[];
    selectedTeacherNames: string[];
};

type ReducerTypes = string | boolean | string[] | undefined;

const MESSAGES_TO_SEND_IN_BULK_COMMUNICATION_BATCH = 20;

const getMessageString = (
    teacherId: string | undefined,
    teacherName: string | undefined,
) => {
    const encodedTeacherId = teacherId ? encodeURIComponent(teacherId) : "";
    const encodedCurrentDateTime = encodeURIComponent(
        format(new Date(), "dd/MM/yyyy HH:mm"),
    );
    const whatsappLink = `https://pianofunclub.co.uk/register-confirm?id=${encodedTeacherId}&t=${encodedCurrentDateTime}`;

    return `Hi ${teacherName ?? "{{firstName}}"},\n\nYour register has just been updated so please review the latest version on google drive.\n\nPlease confirm you've seen this update by tapping the link below and sending the message:\n${whatsappLink}\n\nDo not reply directly to this text as this number is not monitored.\n\nThanks,\n\nThe Piano Fun Club Team`;
};

const SendRegisterChangeNotificationModal = (
    props: Props,
): ReactElement | null => {
    const {
        hideModal,
        onSendFinish,
        onSendStart,
        showModal,
        teacherId,
        teacherName,
    } = props;

    const initialState = useMemo(() => {
        return {
            values: {
                hasSelectedAllTeachers: true,
                messageString: getMessageString(teacherId, teacherName),
                selectedTeacherIds: [],
                selectedTeacherNames: [],
            },
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reducer = createReducer<ReducerValues, ReducerTypes>(initialState);
    const [state, dispatchState] = useReducer(reducer, initialState);

    const [currentPageIndex, setCurrentPageIndex] = useState(teacherId ? 1 : 0);

    const messageStringInputRef = useRef<{ focus: () => void }>(null);

    const toast = useToast();

    const [commitSendBulkCommunication] =
        useMutation<SendBulkCommunicationMutation>(send_bulk_communication);

    const sendRegisterChangeNotification = useCallback(
        (variables: { messageString: string; teacherIds?: string[] }) => {
            let hasErrored = false;
            const failedMutationItems: string[] = [];

            const sendBulkCommunicationConfig = (
                numberSent: number | undefined,
                totalToSend: number | undefined,
            ) => {
                return {
                    variables: {
                        input: {
                            ...variables,
                            sendToTeachers: true,
                            // always send as SMS
                            sendType: "SMS",
                            totalToSend: totalToSend,
                            numberSent: numberSent,
                            numberInBatch:
                                MESSAGES_TO_SEND_IN_BULK_COMMUNICATION_BATCH,
                        },
                    },
                    onCompleted: (
                        response: SendBulkCommunicationMutation$data,
                    ) => {
                        if (response.sendBulkCommunication?.erroredProfile) {
                            failedMutationItems.push(
                                getErroredBulkCommunicationLabel(
                                    response.sendBulkCommunication
                                        .erroredProfile?.user.firstName,
                                    response.sendBulkCommunication
                                        .erroredProfile?.user.lastName,
                                    response.sendBulkCommunication
                                        .erroredProfile?.user.email,
                                ),
                            );

                            toast.show({
                                render: ({ id }: { id: string }) => (
                                    <ToastAlert
                                        description={failedMutationItems.join(
                                            "\n",
                                        )}
                                        id={id}
                                        status="error"
                                        title={"Failed to send to:"}
                                        toast={toast}
                                    />
                                ),
                                // never auto-dismiss
                                duration: null,
                            });
                        }
                        if (response?.sendBulkCommunication?.success) {
                            if (
                                response.sendBulkCommunication.numberSent !==
                                    response.sendBulkCommunication
                                        .totalToSend &&
                                response.sendBulkCommunication.profile
                                    ?.profileGroup
                                    ?.sendBulkCommunicationProgress != 100 &&
                                !hasErrored
                            ) {
                                numberSent =
                                    response.sendBulkCommunication.numberSent ??
                                    undefined;
                                totalToSend =
                                    response.sendBulkCommunication
                                        .totalToSend ?? undefined;
                                commitSendBulkCommunication(
                                    sendBulkCommunicationConfig(
                                        numberSent,
                                        totalToSend,
                                    ),
                                );
                            } else {
                                onSendFinish?.(true);
                            }
                        } else {
                            hasErrored = true;
                            toast.show({
                                render: ({ id }: { id: string }) => (
                                    <ToastAlert
                                        description={
                                            "Please get in touch with one of the team"
                                        }
                                        id={id}
                                        status="error"
                                        title={
                                            "Error sending register change notifications"
                                        }
                                        toast={toast}
                                    />
                                ),
                                // never auto-dismiss
                                duration: null,
                            });
                            onSendFinish?.(false);
                        }
                    },
                    // this happens if the API gateway times out for a batch
                    // in this case, just fire off another mutation and continue
                    onError: () => {
                        // wait for 30 seconds to try and let the previous batch complete
                        setTimeout(() => {
                            if (
                                typeof numberSent !== "undefined" &&
                                typeof totalToSend !== "undefined" &&
                                numberSent +
                                    MESSAGES_TO_SEND_IN_BULK_COMMUNICATION_BATCH <
                                    totalToSend
                            ) {
                                commitSendBulkCommunication(
                                    sendBulkCommunicationConfig(
                                        numberSent +
                                            MESSAGES_TO_SEND_IN_BULK_COMMUNICATION_BATCH,
                                        totalToSend,
                                    ),
                                );
                            } else {
                                onSendFinish?.(false);
                            }
                        }, 30000);
                    },
                };
            };

            // to avoid hitting the max AWS API Gateway execution time (29s)
            // split up the reminders to be sent into batches and recursively
            // fire the mutation, keeping a track of overall progress
            const numberOfSent = 0 as number | undefined;
            const totalToSend = undefined as number | undefined;

            commitSendBulkCommunication(
                sendBulkCommunicationConfig(numberOfSent, totalToSend),
            );
            onSendStart?.();
        },
        [commitSendBulkCommunication, onSendFinish, onSendStart, toast],
    );

    const messageFinishEditingHandler = useCallback(
        (
            inputIdentifier?: string | number,
            inputValue?: string | undefined,
        ) => {
            if (
                typeof inputValue === "string" &&
                typeof inputIdentifier === "string"
            ) {
                dispatchState({ input: inputIdentifier, value: inputValue });
            }
        },
        [],
    );

    const renderSelectTeachers = useMemo(() => {
        if (teacherId) {
            return null;
        }

        return (
            <TeacherMultiSelectModal
                buttonText="Continue"
                doNotHideOnSave
                hideCountFromButton
                hideModal={() => {
                    setCurrentPageIndex(0);
                    hideModal();
                }}
                initiallySelectAllTeachers
                initiallySelectedTeacherIds={state.values.selectedTeacherIds}
                modalBodyProps={{
                    _scrollview: {
                        contentContainerStyle: {
                            justifyContent: "center",
                            flexGrow: 1,
                        },
                    },
                }}
                modalContentProps={{
                    height: teacherId ? "500px" : "600px",
                    width: "800px",
                    maxWidth: "800px",
                }}
                onSave={({
                    hasSelectedAllTeachers,
                    teacherIds,
                    teacherNames,
                }) => {
                    dispatchState({
                        input: "selectedTeacherIds",
                        value: teacherIds,
                    });
                    dispatchState({
                        input: "selectedTeacherNames",
                        value: teacherNames,
                    });
                    dispatchState({
                        input: "hasSelectedAllTeachers",
                        value: hasSelectedAllTeachers,
                    });
                    setCurrentPageIndex(
                        (currentPageIndex) => currentPageIndex + 1,
                    );
                }}
                showModal={showModal && currentPageIndex === 0}
                title="Select Teachers for Register Change Notification"
                width={600}
            />
        );
    }, [
        teacherId,
        state.values.selectedTeacherIds,
        showModal,
        currentPageIndex,
        hideModal,
    ]);

    const renderMessageInput = useMemo(() => {
        return (
            <>
                <VStack alignItems="center" flex={1} space="6" width="95%">
                    {!teacherId ? (
                        <Text color="surface.600" mx="6" textAlign="center">
                            {
                                "You can use {{firstName}}, {{fullName}} and {{accountNumber}} to personalise the message."
                            }
                        </Text>
                    ) : null}
                    <TextInput
                        ref={messageStringInputRef}
                        _focus={{ bg: "white" }}
                        _hover={{ bg: "white" }}
                        autoCapitalize="sentences"
                        bg="white"
                        borderRadius="xl"
                        fontFamily="Poppins-Regular"
                        height="250px"
                        id="messageString"
                        initialValue={state.values.messageString}
                        keyboardType="default"
                        multiline
                        onFinishEditing={messageFinishEditingHandler}
                        size="lg"
                    />
                </VStack>
                <ButtonDebounced
                    _text={{ fontSize: "xl" }}
                    height="56px"
                    isDisabled={!state.values.messageString}
                    mb="4"
                    mt="10"
                    onPress={() => {
                        if (teacherId) {
                            sendRegisterChangeNotification({
                                messageString: state.values.messageString,
                                teacherIds: teacherId
                                    ? [teacherId]
                                    : !state.values.hasSelectedAllTeachers
                                      ? state.values.selectedTeacherIds
                                      : undefined,
                            });
                            hideModal();
                        } else {
                            setCurrentPageIndex(
                                (currentPageIndex) => (currentPageIndex += 1),
                            );
                        }
                    }}
                    px="6">
                    {teacherId ? "Send" : "Preview"}
                </ButtonDebounced>
            </>
        );
    }, [
        hideModal,
        messageFinishEditingHandler,
        sendRegisterChangeNotification,
        state.values.hasSelectedAllTeachers,
        state.values.messageString,
        state.values.selectedTeacherIds,
        teacherId,
    ]);

    const renderMessagePreview = useMemo(() => {
        if (teacherId) {
            return null;
        }

        const selectedTeachersText = state.values.hasSelectedAllTeachers
            ? "all teachers"
            : `${listWithAndConverter(
                  listToStringWithCommasConverter(
                      state.values.selectedTeacherNames,
                  ),
              )}`;

        return (
            <>
                <VStack alignItems="center" flex={1} space="6" width="95%">
                    <VStack space="1" width="100%">
                        <Text color="surface.700">Message</Text>
                        <ScrollView
                            borderColor="surface.200"
                            borderRadius="xl"
                            borderWidth={1}
                            height="250px"
                            mx="-1"
                            px="2"
                            py="1.5">
                            <Text fontFamily="Poppins-Regular" fontSize="md">
                                {messagePreviewConverter(
                                    state.values.messageString,
                                    state.values.selectedTeacherNames[0],
                                )}
                            </Text>
                        </ScrollView>
                    </VStack>
                    {!teacherId ? (
                        <VStack width="100%">
                            <Text
                                color="surface.700"
                                fontFamily="Poppins-Regular"
                                fontSize="md">{`Sending to ${selectedTeachersText}`}</Text>
                        </VStack>
                    ) : null}
                </VStack>
                <ButtonDebounced
                    _text={{ fontSize: "xl" }}
                    height="56px"
                    mb="4"
                    mt="8"
                    onPress={() => {
                        sendRegisterChangeNotification({
                            messageString: state.values.messageString,
                            teacherIds: teacherId
                                ? [teacherId]
                                : !state.values.hasSelectedAllTeachers
                                  ? state.values.selectedTeacherIds
                                  : undefined,
                        });
                        hideModal();
                    }}
                    px="6">
                    Send
                </ButtonDebounced>
            </>
        );
    }, [
        state.values.hasSelectedAllTeachers,
        state.values.selectedTeacherNames,
        state.values.messageString,
        state.values.selectedTeacherIds,
        sendRegisterChangeNotification,
        teacherId,
        hideModal,
    ]);

    return (
        <>
            {renderSelectTeachers}
            <Modal
                closeOnOverlayClick={false}
                isOpen={showModal && currentPageIndex > 0}
                onClose={hideModal}
                size="xl">
                <Modal.Content
                    height={teacherId ? "500px" : "600px"}
                    maxWidth="800px"
                    width="800px">
                    {currentPageIndex > 1 || !teacherId ? (
                        <Button
                            _hover={{ opacity: 0.8, bg: "transparent" }}
                            _pressed={{ opacity: 0.7, bg: "transparent" }}
                            bg="transparent"
                            left="3"
                            leftIcon={<ChevronLeftIcon ml="0" p="0" size="5" />}
                            onPress={() =>
                                setCurrentPageIndex(
                                    (currentPageIndex) => currentPageIndex - 1,
                                )
                            }
                            p="1"
                            position="absolute"
                            top="4"
                            variant="ghost"
                            zIndex="1"
                        />
                    ) : null}
                    <Modal.CloseButton
                        _hover={{ bg: "transparent", opacity: 0.7 }}
                        _pressed={{ bg: "transparent", opacity: 0.7 }}
                    />
                    <Modal.Header>{`${
                        currentPageIndex === 2 ? "Preview of" : "Message for"
                    } Register Change SMS`}</Modal.Header>
                    <Modal.Body
                        _scrollview={{
                            contentContainerStyle: {
                                justifyContent: "center",
                                flexGrow: 1,
                            },
                        }}
                        alignItems="center"
                        mb="4"
                        px="6">
                        {currentPageIndex === 2
                            ? renderMessagePreview
                            : renderMessageInput}
                    </Modal.Body>
                </Modal.Content>
            </Modal>
        </>
    );
};

export default React.memo(SendRegisterChangeNotificationModal);
