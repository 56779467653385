import React, { useCallback, useMemo } from "react";
import type { ReactElement } from "react";

import { Button, HStack, Modal, Text, VStack, useToast } from "native-base";
import { useMutation } from "react-relay";
import type { RecordSourceSelectorProxy } from "relay-runtime";

import type {
    UpdateLessonBlockDetailsMutation,
    UpdateLessonBlockDetailsMutation$data,
} from "pianofunclub-shared/relay/graphql/registers/__generated__/UpdateLessonBlockDetailsMutation.graphql";
import { update_lesson_block_details } from "pianofunclub-shared/relay/graphql/registers/UpdateLessonBlockDetails";
import { lessonBlockConnectionUpdater } from "pianofunclub-shared/relay/updaters";

import { TEACHING_DAYS } from "../../utils/constants";
import SelectList from "../ListItems/SelectList";
import ToastAlert from "../NativeBaseExtended/ToastAlert";

interface Props {
    hideModal: () => void;
    initialLessonDay?: number;
    isAfterAssignTeacherStep?: boolean;
    lessonBlockIds: string[];
    lessonBlocksConnectionId?: string;
    onUpdate?: () => void;
    selectedBlock?: number;
    selectedProfileId?: string;
    selectedStartingYear?: number;
    showModal: boolean;
    updateScheduledLessonBlockTime: (
        lessonBlockIds: string[],
        dayIndex: number,
        hours?: number,
        minutes?: number,
        onComplete?: () => void,
    ) => void;
    updateScheduledLessonBlockTimeInFlight: boolean;
}

const DayTimePickerModal = (props: Props): ReactElement | null => {
    const {
        hideModal,
        initialLessonDay,
        lessonBlockIds,
        lessonBlocksConnectionId,
        onUpdate,
        selectedBlock,
        selectedStartingYear,
        showModal,
        updateScheduledLessonBlockTime,
        updateScheduledLessonBlockTimeInFlight,
    } = props;

    const [selectedDay, setSelectedDay] = React.useState<number>(
        initialLessonDay ?? 0,
    );

    const [commitUpdateLessonBlockDetails, updateLessonBlockDetailsInFlight] =
        useMutation<UpdateLessonBlockDetailsMutation>(
            update_lesson_block_details,
        );

    const toast = useToast();

    const renderDayPicker = useMemo(() => {
        return (
            <SelectList
                flexDirection="row"
                initialSelectedItems={initialLessonDay ?? 0}
                onSelect={(selectedItem) => setSelectedDay(selectedItem)}
                px="4">
                {TEACHING_DAYS.map((item) => {
                    return (
                        <SelectList.Item
                            key={item.value}
                            labelHeading={item.label.slice(0, 3)}
                            mr="4"
                            value={item.value}
                        />
                    );
                })}
            </SelectList>
        );
    }, [initialLessonDay]);

    // TODO: This should really be passed in and not defined again here
    // To achieve this we could use the onComplete callback
    const updateLessonBlockDetails = useCallback(
        (
            variables: {
                instrument?: string;
                keepFree?: boolean;
                lessonBlockIds: string[];
                pupilId?: string | null;
                staffNoteForTeacher?: string;
                stageId?: string;
                teacherId?: string | null;
                teacherNoteForStaff?: string;
            },
            lessonBlockCursor?: string,
            onComplete?: () => void,
        ) => {
            if (
                !selectedStartingYear ||
                !selectedBlock ||
                !props.lessonBlockIds ||
                props.lessonBlockIds.length == 0
            )
                return;

            const updateLessonBlockDetailsConfig = {
                variables: {
                    connections: lessonBlocksConnectionId
                        ? [lessonBlocksConnectionId]
                        : [],
                    input: variables,
                    startingYear: selectedStartingYear,
                    block: selectedBlock,
                },
                updater: (store: RecordSourceSelectorProxy) =>
                    lessonBlockConnectionUpdater(
                        store,
                        lessonBlocksConnectionId,
                        lessonBlockCursor,
                    ),
                onCompleted: (
                    response: UpdateLessonBlockDetailsMutation$data,
                ) => {
                    if (response?.updateLessonBlockDetails?.success) {
                        if (response.updateLessonBlockDetails.lessonBlocks) {
                            const newLessonBlockIds =
                                response.updateLessonBlockDetails.lessonBlocks.map(
                                    (lessonBlock) => {
                                        if (lessonBlock) return lessonBlock.id;
                                        return "";
                                    },
                                );

                            updateScheduledLessonBlockTime(
                                newLessonBlockIds,
                                selectedDay,
                                undefined,
                                undefined,
                                () => {
                                    onUpdate?.();
                                    hideModal();

                                    toast.show({
                                        render: ({ id }: { id: string }) => (
                                            <ToastAlert
                                                id={id}
                                                status="success"
                                                title={`Updated lesson block${
                                                    variables.lessonBlockIds
                                                        .length > 1
                                                        ? "s' teacher and scheduled day"
                                                        : " teacher and scheduled day"
                                                }`}
                                                toast={toast}
                                            />
                                        ),
                                    });
                                },
                            );
                        } else {
                            toast.show({
                                render: ({ id }: { id: string }) => (
                                    <ToastAlert
                                        description={
                                            "Please get in touch with one of the team"
                                        }
                                        id={id}
                                        status="error"
                                        title={`Couldn't update lesson block${
                                            variables.lessonBlockIds.length > 1
                                                ? "s scheduled day"
                                                : " scheduled day"
                                        }`}
                                        toast={toast}
                                    />
                                ),
                            });
                        }

                        onComplete?.();
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        "Please get in touch with one of the team"
                                    }
                                    id={id}
                                    status="error"
                                    title={`Couldn't update lesson block${
                                        variables.lessonBlockIds.length > 1
                                            ? "s"
                                            : ""
                                    }`}
                                    toast={toast}
                                />
                            ),
                        });
                    }
                },
            };

            commitUpdateLessonBlockDetails(updateLessonBlockDetailsConfig);
        },
        [
            selectedStartingYear,
            selectedBlock,
            props.lessonBlockIds,
            lessonBlocksConnectionId,
            commitUpdateLessonBlockDetails,
            updateScheduledLessonBlockTime,
            selectedDay,
            onUpdate,
            hideModal,
            toast,
        ],
    );

    const updateLessonBlockDetailsHandler = useCallback(
        (variables: {
            instrument?: string;
            keepFree?: boolean;
            pupilId?: string | null;
            stageId?: string;
            teacherId?: string | null;
        }) => {
            if (lessonBlockIds && lessonBlockIds.length > 0) {
                updateLessonBlockDetails(
                    {
                        ...variables,
                        lessonBlockIds: lessonBlockIds,
                    },
                    undefined,
                    () => {
                        hideModal();
                        onUpdate?.();
                    },
                );
            }
        },
        [hideModal, lessonBlockIds, onUpdate, updateLessonBlockDetails],
    );

    return (
        <Modal
            isOpen={showModal}
            onClose={() => {
                hideModal();
            }}
            size="xl">
            <Modal.Content bg="surface.100">
                <Modal.CloseButton
                    _hover={{ bg: "transparent", opacity: 0.7 }}
                    _icon={{ color: "surface.100" }}
                    _pressed={{ bg: "transparent", opacity: 0.7 }}
                    left={2}
                />
                <Modal.Header bg="primary.800" />
                <HStack
                    bg="primary.800"
                    justifyContent="space-between"
                    pb="4"
                    pt="8"
                    px="4">
                    <VStack space="1">
                        <Text color="surface.200" letterSpacing={1}>
                            Update Lesson Block Day
                        </Text>
                        <Text
                            color="surface.200"
                            fontSize="2xl"
                            letterSpacing={1}>
                            {
                                TEACHING_DAYS.find(
                                    (item) =>
                                        Number(item.value) === selectedDay,
                                )?.label
                            }
                        </Text>
                    </VStack>
                    <Button
                        _hover={{
                            bg: "transparent",
                            opacity: 0.8,
                        }}
                        _pressed={{
                            bg: "transparent",
                            opacity: 0.7,
                        }}
                        _spinner={{ mr: "2" }}
                        _text={{
                            fontSize: "2xl",
                            color: "surface.200",
                            letterSpacing: 1,
                        }}
                        colorScheme="surface"
                        isLoading={
                            updateScheduledLessonBlockTimeInFlight ||
                            updateLessonBlockDetailsInFlight
                        }
                        isLoadingText="Update"
                        onPress={() => {
                            if (!props.isAfterAssignTeacherStep) {
                                updateScheduledLessonBlockTime(
                                    lessonBlockIds,
                                    selectedDay,
                                    undefined,
                                    undefined,
                                    () => {
                                        onUpdate?.();
                                        hideModal();
                                    },
                                );
                            } else {
                                updateLessonBlockDetailsHandler({
                                    teacherId: props.selectedProfileId,
                                    keepFree: false,
                                });
                            }
                        }}
                        variant="ghost">
                        Update
                    </Button>
                </HStack>
                <Modal.Body alignItems="center" bg="surface.100" my="16" px="6">
                    {renderDayPicker}
                </Modal.Body>
            </Modal.Content>
        </Modal>
    );
};

export default React.memo(DayTimePickerModal);
