import React, {
    useRef,
    useCallback,
    useMemo,
    useReducer,
    useEffect,
    Suspense,
    useState,
} from "react";
import type { ReactElement, ComponentProps } from "react";

import emoji from "emoji-dictionary";
import {
    Modal,
    VStack,
    Text,
    Box,
    Center,
    PresenceTransition,
    HStack,
    Checkbox,
} from "native-base";
import type { Mutable } from "pianofunclub-shared/types";
import {
    usePreloadedQuery,
    useQueryLoader,
    useRelayEnvironment,
    useSubscribeToInvalidationState,
} from "react-relay";
import type {
    PreloadedQuery,
    UseQueryLoaderLoadQueryOptions,
} from "react-relay";
import {
    RecyclerListView,
    DataProvider,
    LayoutProvider,
} from "recyclerlistview";
import { fetchQuery } from "relay-runtime";
import type { Subscription } from "relay-runtime";

import type {
    LoadSchoolsForDropdownsQuery,
    LoadSchoolsForDropdownsQuery$data,
    LoadSchoolsForDropdownsQuery$variables,
} from "pianofunclub-shared/relay/graphql/schools/__generated__/LoadSchoolsForDropdownsQuery.graphql";
import { load_schools_for_dropdowns } from "pianofunclub-shared/relay/graphql/schools/LoadSchoolsForDropdowns";

import { addOrRemoveFromSet } from "../../utils/helpers";
import { useDebounceFunction } from "../../utils/hooks";
import LoadingBlobs from "../Animations/LoadingBlobs";
import ButtonDebounced from "../Buttons/ButtonDebounced";
import MultiSelectRow from "../ListItems/MultiSelectRow";

import ListEmptyBanner from "pianofunclub-shared/components/ListItems/ListEmptyBanner";
import CustomFlatListSpinner from "pianofunclub-shared/components/Other/CustomFlatListSpinner";
import SearchBar from "pianofunclub-shared/components/Other/SearchBar";

import { createReducer } from "pianofunclub-shared/utils/reducers";

interface WrapperProps {
    buttonText: string;
    doNotHideOnSave?: boolean;
    hideCountFromButton?: boolean;
    hideModal: () => void;
    initiallySelectAllSchools?: boolean;
    initiallySelectedSchoolNames?: string[];
    modalBodyProps?: ComponentProps<typeof Modal.Body>;
    modalContentProps?: ComponentProps<typeof Modal.Content>;
    modalProps?: ComponentProps<typeof Modal>;
    onSave: (variables: {
        hasSelectedAllSchools?: boolean;
        schoolIds: string[];
        schoolNames?: string[];
    }) => void;
    showModal: boolean;
    termInWords?: string;
    title: string;
    width?: number;
}

interface ContentProps extends WrapperProps {
    loadSchoolsQuery: (
        variables: LoadSchoolsForDropdownsQuery$variables,
        options?: UseQueryLoaderLoadQueryOptions | undefined,
    ) => void;
    loadSchoolsQueryReference: PreloadedQuery<
        LoadSchoolsForDropdownsQuery,
        Record<string, unknown>
    >;
}

type ReducerValues = {
    contentIsRendered: boolean;
    dataProvider: DataProvider;
    isRefetching: boolean;
    searchTerm: string;
    subscription?: Subscription;
};

type ReducerTypes =
    | string
    | boolean
    | Subscription
    | DataProvider
    | string[]
    | undefined;

const SchoolMultiSelectModal = (props: ContentProps): ReactElement | null => {
    const {
        buttonText,
        doNotHideOnSave,
        // school names - should really use ids everywhere but hey ho
        hideCountFromButton,
        hideModal,
        initiallySelectAllSchools,
        initiallySelectedSchoolNames,
        loadSchoolsQuery,
        loadSchoolsQueryReference,
        modalBodyProps,
        modalContentProps,
        modalProps,
        onSave,
        showModal,
        termInWords,
        title,
        width,
    } = props;

    const schoolsData = usePreloadedQuery(
        load_schools_for_dropdowns,
        loadSchoolsQueryReference,
    );

    const allSchools = useMemo(() => {
        return {
            ids:
                schoolsData.schools?.edges.map(
                    (item) => item?.node?.id ?? "",
                ) ?? [],
            names:
                schoolsData.schools?.edges.map(
                    (item) => item?.node?.name ?? "",
                ) ?? [],
        };
    }, [schoolsData.schools?.edges]);

    useSubscribeToInvalidationState(allSchools.ids, () => {
        loadSchoolsQuery(
            {
                orderBy: "name",
                isActive: true,
            },
            { fetchPolicy: "network-only" },
        );
    });

    const [selectedSchools, setSelectedSchools] = useState(
        initiallySelectedSchoolNames && initiallySelectedSchoolNames.length > 0
            ? // add school ids
              {
                  ids: new Set(
                      initiallySelectedSchoolNames
                          .map(
                              (name) =>
                                  schoolsData.schools?.edges.find(
                                      (item) => item?.node?.name === name,
                                  )?.node?.id ?? "",
                          )
                          .filter((id) => id) as string[],
                  ),
                  names: new Set(initiallySelectedSchoolNames),
              }
            : initiallySelectAllSchools
              ? {
                    ids: new Set(allSchools.ids),
                    names: new Set(allSchools.names),
                }
              : { ids: new Set([]), names: new Set([]) },
    );

    const initialState = useMemo(() => {
        return {
            values: {
                searchTerm: "",
                isRefetching: false,
                subscription: undefined,
                dataProvider: new DataProvider((r1, r2) => {
                    return r1 !== r2;
                }),
                contentIsRendered: false,
            },
        };
    }, []);

    const reducer = createReducer<ReducerValues, ReducerTypes>(initialState);
    const [state, dispatchState] = useReducer(reducer, initialState);

    useEffect(() => {
        rowRefs.current = new Map();
        if (
            schoolsData.schools?.edges &&
            schoolsData.schools.edges.length > 0
        ) {
            dispatchState({
                input: "dataProvider",
                value: state.values.dataProvider.cloneWithRows(
                    schoolsData.schools.edges as Mutable<
                        typeof schoolsData.schools.edges
                    >,
                ),
            });
            // if there is no data, create a fresh DataProvider
        } else {
            dispatchState({
                input: "dataProvider",
                value: new DataProvider((r1, r2) => {
                    return r1 !== r2;
                }),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schoolsData.schools?.edges]);

    const layoutProvider = useMemo(() => {
        return new LayoutProvider(
            () => "VSEL",
            (type, dim) => {
                switch (type) {
                    case "VSEL":
                        dim.width = width ?? 450;
                        dim.height = 43;
                        break;
                    default:
                        dim.width = 0;
                        dim.height = 0;
                }
            },
        );
    }, [width]);

    const rowRefs = useRef(new Map());
    // stop refetch useEffects firing on first render
    const renderCount = useRef(0);
    const environment = useRelayEnvironment();

    useDebounceFunction(
        () => {
            if (renderCount.current < 1) {
                renderCount.current += 1;
                return;
            }

            state.values.subscription?.unsubscribe();
            dispatchState({ input: "isRefetching", value: true });

            const variables = {
                searchTerm:
                    state.values.searchTerm.trim() !== ""
                        ? state.values.searchTerm.trim()
                        : undefined,
                orderBy: "name",
                isActive: true,
            };

            dispatchState({
                input: "subscription",
                value: fetchQuery<LoadSchoolsForDropdownsQuery>(
                    environment,
                    load_schools_for_dropdowns,
                    variables,
                    {
                        fetchPolicy: "store-or-network",
                    },
                ).subscribe({
                    complete: () => {
                        dispatchState({ input: "isRefetching", value: false });
                        loadSchoolsQuery(variables, {
                            // @ts-expect-error relay typing deficiency
                            fetchPolicy: "store-only",
                        });
                    },
                    error: () => {
                        dispatchState({ input: "isRefetching", value: false });
                    },
                }),
            });
        }, // no delay if clearing text
        state.values.searchTerm !== "" ? 750 : 0,

        [state.values.searchTerm],
    );

    const inputChangeHandler = useCallback(
        (_: unknown, inputValue?: string) => {
            dispatchState({
                input: "searchTerm",
                value: inputValue,
            });
        },
        [dispatchState],
    );

    const inputClearHandler = useCallback(() => {
        if (state.values.searchTerm !== "") {
            dispatchState({
                input: "searchTerm",
                value: "",
            });
        }
    }, [dispatchState, state.values.searchTerm]);

    const itemPressHandler = useCallback(
        (variables: { id: string; label?: string }) => {
            setSelectedSchools((currentlySelectedSchools) => {
                const currentlySelectedSchoolsClone = {
                    ids: new Set(currentlySelectedSchools.ids),
                    names: new Set(currentlySelectedSchools.names),
                };
                return {
                    ids: addOrRemoveFromSet(
                        currentlySelectedSchoolsClone.ids,
                        variables.id,
                    ),
                    names: addOrRemoveFromSet(
                        currentlySelectedSchoolsClone.names,
                        variables.label as string,
                    ),
                };
            });
        },
        [],
    );

    const selectAllCheckboxChangeHandler = useCallback(
        (isSelected: boolean) => {
            rowRefs.current.forEach((ref) => {
                ref.setIsChecked(isSelected);
            });
            setSelectedSchools(
                isSelected
                    ? {
                          ids: new Set(allSchools.ids),
                          names: new Set(allSchools.names),
                      }
                    : {
                          ids: new Set([]),
                          names: new Set([]),
                      },
            );
        },
        [allSchools],
    );

    const rowRenderer = useCallback(
        (
            _: unknown,
            item: NonNullable<
                LoadSchoolsForDropdownsQuery$data["schools"]
            >["edges"][0],
            index: number,
        ): ReactElement | null => {
            if (item?.node) {
                return (
                    <MultiSelectRow
                        key={item.node.id}
                        ref={(ref) => {
                            if (
                                ref &&
                                item.node?.id &&
                                !rowRefs.current.get(item.node.id)
                            ) {
                                rowRefs.current.set(item.node.id, ref);
                            }
                        }}
                        id={item.node.id}
                        index={index}
                        initiallySelected={selectedSchools.ids.has(
                            item.node.id,
                        )}
                        itemPressHandler={itemPressHandler}
                        label={item.node.name}
                    />
                );
            } else {
                return null;
            }
        },
        [itemPressHandler, selectedSchools.ids],
    );

    const renderRefetchIndicator = useMemo(() => {
        return state.values.isRefetching ? (
            <CustomFlatListSpinner top="4" />
        ) : null;
    }, [state.values.isRefetching]);

    const renderSearchResults = useMemo(() => {
        return state.values.dataProvider.getSize() > 0 ? (
            <RecyclerListView
                dataProvider={state.values.dataProvider}
                layoutProvider={layoutProvider}
                rowRenderer={rowRenderer}
                scrollViewProps={{
                    contentContainerStyle: { marginBottom: 16 },
                }}
                style={{
                    flex: 1,
                    minHeight: 1,
                }}
                suppressBoundedSizeException
                useWindowScroll
            />
        ) : !state.values.contentIsRendered ? (
            <LoadingBlobs bg="transparent" textProps={{ color: "surface.900" }}>
                Loading...
            </LoadingBlobs>
        ) : !state.values.isRefetching ? (
            <Center flex={1} flexGrow={1}>
                <PresenceTransition
                    animate={{
                        opacity: 1,
                        transition: {
                            delay: 500,
                            duration: 300,
                        },
                    }}
                    initial={{
                        opacity: 0,
                    }}
                    visible={true}>
                    <ListEmptyBanner explainer={"No schools found..."}>
                        <Text fontSize="6xl">
                            {emoji.getUnicode("neutral_face")}
                        </Text>
                    </ListEmptyBanner>
                </PresenceTransition>
            </Center>
        ) : null;
    }, [
        state.values.dataProvider,
        state.values.isRefetching,
        state.values.contentIsRendered,
        rowRenderer,
        layoutProvider,
    ]);

    const searchBarRef = useRef(null);

    const renderContent = useMemo(() => {
        return (
            <>
                <VStack flex={1} space="6" width="85%">
                    <SearchBar
                        ref={searchBarRef}
                        inputClearHandler={inputClearHandler}
                        inputOnChangeHandler={inputChangeHandler}
                        inputSearchHandler={inputChangeHandler}
                        placeholderText={"Search for a school"}
                        searchText={state.values.searchTerm}
                        showSearchIcon
                    />
                    <Box
                        bg="surface.100"
                        borderColor="surface.200"
                        borderRadius="2xl"
                        borderWidth={1}
                        height="300px"
                        overflow="hidden"
                        width="100%">
                        <HStack
                            alignItems="center"
                            bg="primary.50"
                            borderBottomWidth={1}
                            borderColor="surface.200"
                            pb="2"
                            pt="3"
                            px="4"
                            space="3">
                            <Checkbox
                                isChecked={
                                    allSchools.ids.length > 0 &&
                                    allSchools.ids.length <=
                                        selectedSchools.ids.size &&
                                    allSchools.ids.every((id) =>
                                        selectedSchools.ids.has(id),
                                    )
                                }
                                onChange={selectAllCheckboxChangeHandler}
                                value="SELECT_ALL"
                            />
                            <Text fontSize="md">Select All</Text>
                        </HStack>
                        {renderSearchResults}
                        {renderRefetchIndicator}
                    </Box>
                </VStack>
                <ButtonDebounced
                    _text={{ fontSize: "xl" }}
                    debounceTime={5000}
                    height="56px"
                    isDisabled={selectedSchools.ids.size === 0}
                    mb="4"
                    mt="8"
                    onPress={() => {
                        onSave({
                            schoolIds: Array.from(selectedSchools.ids),
                            schoolNames: Array.from(selectedSchools.names),
                            hasSelectedAllSchools:
                                selectedSchools.ids.size ===
                                allSchools.ids.length,
                        });
                        if (!doNotHideOnSave) {
                            hideModal();
                        }
                    }}
                    px="6">
                    {`${buttonText}${
                        !hideCountFromButton
                            ? ` (${selectedSchools.ids.size})`
                            : ""
                    }`}
                </ButtonDebounced>
            </>
        );
    }, [
        allSchools.ids,
        buttonText,
        doNotHideOnSave,
        hideCountFromButton,
        hideModal,
        inputChangeHandler,
        inputClearHandler,
        onSave,
        renderRefetchIndicator,
        renderSearchResults,
        selectAllCheckboxChangeHandler,
        selectedSchools.ids,
        selectedSchools.names,
        state.values.searchTerm,
    ]);

    useEffect(() => {
        setTimeout(() => {
            dispatchState({ input: "contentIsRendered", value: true });
        }, 1000);
        // unsubscribe from refetch on unmount
        return () => state.values.subscription?.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    console.log("school multi select modal", showModal);

    return (
        <Modal
            initialFocusRef={searchBarRef}
            size="xl"
            {...modalProps}
            isOpen={showModal}
            onClose={() => {
                hideModal();
                dispatchState({ input: "searchTerm", value: "" });
            }}>
            <Modal.Content {...modalContentProps}>
                <Modal.CloseButton
                    _hover={{ bg: "transparent", opacity: 0.7 }}
                    _pressed={{ bg: "transparent", opacity: 0.7 }}
                />
                <Modal.Header>{title}</Modal.Header>
                {termInWords ? (
                    <Text alignSelf="center" fontSize="md" pb="5">
                        {termInWords}
                    </Text>
                ) : null}
                <Modal.Body
                    alignItems="center"
                    mb="4"
                    px="6"
                    {...modalBodyProps}>
                    {renderContent}
                </Modal.Body>
            </Modal.Content>
        </Modal>
    );
};

const SchoolMultiSelectModalWrapper = (props: WrapperProps): ReactElement => {
    const [loadSchoolsQueryReference, loadSchoolsQuery] =
        useQueryLoader<LoadSchoolsForDropdownsQuery>(
            load_schools_for_dropdowns,
        );

    useEffect(() => {
        if (!loadSchoolsQueryReference) {
            loadSchoolsQuery(
                {
                    orderBy: "name",
                    isActive: true,
                },
                { fetchPolicy: "store-or-network" },
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadSchoolsQuery]);

    return loadSchoolsQueryReference ? (
        <Suspense fallback={<></>}>
            <SchoolMultiSelectModal
                loadSchoolsQuery={loadSchoolsQuery}
                loadSchoolsQueryReference={loadSchoolsQueryReference}
                {...props}
            />
        </Suspense>
    ) : (
        <></>
    );
};

export default React.memo(SchoolMultiSelectModalWrapper);
