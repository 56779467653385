/**
 * @generated SignedSource<<9f775bbab2f31207422ebc9c6aab98aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SendBulkCommunicationInput = {
  clientMutationId?: string | null;
  excludePupilsWhoHaveBook?: boolean | null;
  excludePupilsWhoHaveInstrument?: boolean | null;
  excludePupilsWhoHaveInstrumentSuppliedByUs?: boolean | null;
  excludePupilsWhoHaveReturnedInstrument?: boolean | null;
  messageString: string;
  messageSubject?: string | null;
  numberInBatch: number;
  numberSent?: number | null;
  schoolIds?: ReadonlyArray<string | null> | null;
  schoolYears?: ReadonlyArray<number | null> | null;
  sendToTeachers?: boolean | null;
  sendType: string;
  teacherIds?: ReadonlyArray<string | null> | null;
  totalToSend?: number | null;
};
export type SendBulkCommunicationMutation$variables = {
  input: SendBulkCommunicationInput;
};
export type SendBulkCommunicationMutation$data = {
  readonly sendBulkCommunication: {
    readonly erroredProfile: {
      readonly user: {
        readonly email: string;
        readonly firstName: string;
        readonly lastName: string;
      };
    } | null;
    readonly errors: any | null;
    readonly numberSent: number | null;
    readonly profile: {
      readonly profileGroup: {
        readonly sendBulkCommunicationProgress: number | null;
        readonly sendTeacherBulkCommunicationProgress: number | null;
      } | null;
    } | null;
    readonly success: boolean | null;
    readonly totalToSend: number | null;
  } | null;
};
export type SendBulkCommunicationMutation = {
  response: SendBulkCommunicationMutation$data;
  variables: SendBulkCommunicationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberSent",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalToSend",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sendBulkCommunicationProgress",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sendTeacherBulkCommunicationProgress",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SendBulkCommunicationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SendBulkCommunicationPayload",
        "kind": "LinkedField",
        "name": "sendBulkCommunication",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "erroredProfile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DjangoUserNode",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileGroupNode",
                "kind": "LinkedField",
                "name": "profileGroup",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SendBulkCommunicationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SendBulkCommunicationPayload",
        "kind": "LinkedField",
        "name": "sendBulkCommunication",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "erroredProfile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DjangoUserNode",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProfileNode",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProfileGroupNode",
                "kind": "LinkedField",
                "name": "profileGroup",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cb9b676dea7502f4c9651763cfe63bfe",
    "id": null,
    "metadata": {},
    "name": "SendBulkCommunicationMutation",
    "operationKind": "mutation",
    "text": "mutation SendBulkCommunicationMutation(\n  $input: SendBulkCommunicationInput!\n) {\n  sendBulkCommunication(input: $input) {\n    success\n    errors\n    numberSent\n    totalToSend\n    erroredProfile {\n      user {\n        firstName\n        lastName\n        email\n        id\n      }\n      id\n    }\n    profile {\n      profileGroup {\n        sendBulkCommunicationProgress\n        sendTeacherBulkCommunicationProgress\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f5fb3b3bd7cc0c2666a9b3bf012e26b8";

export default node;
