/**
 * @generated SignedSource<<18a2526687b226a3782defb443d592de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InvoiceModelStatus = "CANCELLED" | "DRAFT" | "OVERPAID" | "PAID" | "PENDING" | "REFUND" | "SENT" | "%future added value";
export type LineItemModelItemType = "ACCOUNT_OTHER_FEE_OR_DISCOUNT" | "BLOCK_OTHER_FEE_OR_DISCOUNT" | "EXAM_FEE" | "INSTRUMENT_DEPOSIT" | "LESSONS" | "MISSED_LESSON_DISCOUNT" | "%future added value";
export type AddLineItemInput = {
  clientMutationId?: string | null;
  instrument?: string | null;
  invoiceId: string;
  lineItemType: string;
  pupilId?: string | null;
};
export type AddLineItemMutation$variables = {
  connections: ReadonlyArray<string>;
  input: AddLineItemInput;
};
export type AddLineItemMutation$data = {
  readonly addLineItem: {
    readonly errors: any | null;
    readonly invoice: {
      readonly id: string;
      readonly lastModified: string;
      readonly status: InvoiceModelStatus;
      readonly totalAmount: number | null;
    } | null;
    readonly lineItem: {
      readonly id: string;
      readonly instrumentDescription: string | null;
      readonly itemType: LineItemModelItemType;
      readonly pupil: {
        readonly id: string;
      } | null;
      readonly pupilDescription: string | null;
      readonly pupilDescriptionId: number | null;
      readonly quantity: number | null;
      readonly timePeriodDescription: string | null;
      readonly typeDescription: string | null;
      readonly unitAmount: number | null;
    } | null;
    readonly success: boolean | null;
  } | null;
};
export type AddLineItemMutation = {
  response: AddLineItemMutation$data;
  variables: AddLineItemMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "LineItemNode",
  "kind": "LinkedField",
  "name": "lineItem",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "itemType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unitAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timePeriodDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pupilDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pupilDescriptionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "instrumentDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "typeDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileNode",
      "kind": "LinkedField",
      "name": "pupil",
      "plural": false,
      "selections": [
        (v4/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "InvoiceNode",
  "kind": "LinkedField",
  "name": "invoice",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastModified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddLineItemMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddLineItemPayload",
        "kind": "LinkedField",
        "name": "addLineItem",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddLineItemMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddLineItemPayload",
        "kind": "LinkedField",
        "name": "addLineItem",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "lineItem",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "HomeworkNodeEdge"
              }
            ]
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "304f5cee7b0b4b82e1f6f1789355bbf0",
    "id": null,
    "metadata": {},
    "name": "AddLineItemMutation",
    "operationKind": "mutation",
    "text": "mutation AddLineItemMutation(\n  $input: AddLineItemInput!\n) {\n  addLineItem(input: $input) {\n    success\n    errors\n    lineItem {\n      id\n      itemType\n      quantity\n      unitAmount\n      timePeriodDescription\n      pupilDescription\n      pupilDescriptionId\n      instrumentDescription\n      typeDescription\n      pupil {\n        id\n      }\n    }\n    invoice {\n      id\n      lastModified\n      totalAmount\n      status\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0038941ec202a0ec4c0657dc1dc61662";

export default node;
