import React, {
    Suspense,
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useRef,
} from "react";
import type { FC, ReactElement, Dispatch } from "react";

import emoji from "emoji-dictionary";
import * as Clipboard from "expo-clipboard";
import {
    Box,
    Heading,
    VStack,
    HStack,
    Button,
    Text,
    useTheme,
    useToast,
} from "native-base";
import { useWindowDimensions } from "react-native";
import {
    useQueryLoader,
    usePreloadedQuery,
    usePaginationFragment,
    useRelayEnvironment,
    useMutation,
} from "react-relay";
import type { PreloadedQuery } from "react-relay";
import {
    RecyclerListView,
    DataProvider,
    LayoutProvider,
} from "recyclerlistview";
import { fetchQuery } from "relay-runtime";
import type { Subscription } from "relay-runtime";

import { useAuth } from "pianofunclub-shared/providers/AuthProvider";
import { useData } from "pianofunclub-shared/providers/DataProvider";

import LoadingBlobs from "pianofunclub-shared/components/Animations/LoadingBlobs";
import Row from "pianofunclub-shared/components/Base/Row";
import ButtonDebounced from "pianofunclub-shared/components/Buttons/ButtonDebounced";
import TextInput from "pianofunclub-shared/components/Inputs/TextInput";
import ListEmptyBanner from "pianofunclub-shared/components/ListItems/ListEmptyBanner";
import AlertPopup from "pianofunclub-shared/components/NativeBaseExtended/AlertPopup";
import Select from "pianofunclub-shared/components/NativeBaseExtended/Select";
import ToastAlert from "pianofunclub-shared/components/NativeBaseExtended/ToastAlert";
import CustomFlatListSpinner from "pianofunclub-shared/components/Other/CustomFlatListSpinner";
import {
    CopyIcon,
    RestartIcon,
    WaitingListIcon,
} from "pianofunclub-shared/components/Other/Icons";
import SearchBar from "pianofunclub-shared/components/Other/SearchBar";

import type {
    BulkUploadNewEnrolmentsMutation,
    BulkUploadNewEnrolmentsMutation$data,
} from "pianofunclub-shared/relay/graphql/accounts/__generated__/BulkUploadNewEnrolmentsMutation.graphql";
import type {
    LoadEnrolments_query_enrolments$data,
    LoadEnrolments_query_enrolments$key,
} from "pianofunclub-shared/relay/graphql/accounts/__generated__/LoadEnrolments_query_enrolments.graphql";
import type { LoadEnrolmentsPaginationQuery } from "pianofunclub-shared/relay/graphql/accounts/__generated__/LoadEnrolmentsPaginationQuery.graphql";
import type { LoadEnrolmentsQuery } from "pianofunclub-shared/relay/graphql/accounts/__generated__/LoadEnrolmentsQuery.graphql";
import { bulk_upload_new_enrolments } from "pianofunclub-shared/relay/graphql/accounts/BulkUploadNewEnrolments";
import {
    load_enrolments,
    load_enrolments_pagination,
} from "pianofunclub-shared/relay/graphql/accounts/LoadEnrolments";
import type { LoadSchoolsForDropdownsQuery } from "pianofunclub-shared/relay/graphql/schools/__generated__/LoadSchoolsForDropdownsQuery.graphql";
import { load_schools_for_dropdowns } from "pianofunclub-shared/relay/graphql/schools/LoadSchoolsForDropdowns";

import type { Mutable } from "pianofunclub-shared/types";
import {
    INSTRUMENTS,
    LESSON_STAGES,
    SCHOOL_YEARS,
} from "pianofunclub-shared/utils/constants";
import {
    getScaledWindowDimension,
    titleCaseConverter,
} from "pianofunclub-shared/utils/converters";
import {
    getOrderBy,
    sortIconExtractor,
} from "pianofunclub-shared/utils/extractors";
import { useDebounceFunction } from "pianofunclub-shared/utils/hooks";
import { createReducer } from "pianofunclub-shared/utils/reducers";
import type { State, Action } from "pianofunclub-shared/utils/reducers";

import type {
    WaitingListStackNavigatorProps,
    WaitingListStackRouteProps,
} from "pianofunclub-crm/navigation/WaitingListNavigator";

import WaitingListTableRow from "pianofunclub-crm/components/ListItems/WaitingListTableRow";

import { waitingListHubTableCsvConverter } from "pianofunclub-crm/utils/tableCsvConverters";

type NavigationProps = WaitingListStackNavigatorProps<"WaitingListHub">;

type RouteProps = WaitingListStackRouteProps<"WaitingListHub">;

interface ScreenProps {
    navigation: NavigationProps;
    route: RouteProps;
}

const LOAD_X_ENROLMENTS = 50;
const TABLE_BORDER_COLOR = "surface.400";
const TABLE_BORDER_WIDTH = 1;
const TABLE_BORDER_RADIUS = 10;
const FLEX_ARRAY = [0.3, 0.5, 0.5, 0.3, 0.3, 0.3, 0.3, 0.9];

type ReducerValues = {
    bulkUploadEnrolmentsModalIsOpen: boolean;
    contentIsRendered: boolean;
    copyDataToClipboard?: () => void;
    dataProvider: DataProvider;
    googleDriveNewBulkEnrolmentSheetUrl: string;
    instrument?: string;
    isRefetching: boolean;
    layoutProvider?: LayoutProvider;
    lessonStageIndex?: string;
    orderBy: string;
    refreshHandler?: () => void;
    school?: string;
    schoolYear?: string;
    schoolsConnectionId?: string;
    searchTerm: string;
    showCreateSchoolModal: boolean;
    subscription?: Subscription;
};

type ReducerTypes =
    | string
    | number
    | boolean
    | Subscription
    | DataProvider
    | LayoutProvider
    | (() => void)
    | undefined;

interface ContentProps {
    dispatchState: Dispatch<Action<ReducerValues, ReducerTypes>>;
    loadEnrolmentsQueryReference: PreloadedQuery<
        LoadEnrolmentsQuery,
        Record<string, unknown>
    >;
    loadSchoolsForDropdownsQueryReference: PreloadedQuery<
        LoadSchoolsForDropdownsQuery,
        Record<string, unknown>
    >;
    navigation: NavigationProps;
    state: State<ReducerValues>;
}

const WaitingListContent: FC<ContentProps> = (props) => {
    const {
        dispatchState,
        loadEnrolmentsQueryReference,
        loadSchoolsForDropdownsQueryReference,
        navigation,
        state,
    } = props;

    const data = usePreloadedQuery(
        load_enrolments,
        loadEnrolmentsQueryReference,
    );

    const schoolsData = usePreloadedQuery<LoadSchoolsForDropdownsQuery>(
        load_schools_for_dropdowns,
        loadSchoolsForDropdownsQueryReference,
    );

    const {
        data: enrolmentsData,
        hasNext,
        isLoadingNext,
        loadNext,
        refetch: refresh,
    } = usePaginationFragment<
        LoadEnrolmentsPaginationQuery,
        LoadEnrolments_query_enrolments$key
    >(load_enrolments_pagination, data);

    const toast = useToast();

    useEffect(() => {
        if (
            enrolmentsData.enrolments?.edges &&
            enrolmentsData.enrolments.edges.length > 0
        ) {
            dispatchState({
                input: "dataProvider",
                value: state.values.dataProvider.cloneWithRows(
                    enrolmentsData.enrolments.edges as Mutable<
                        typeof enrolmentsData.enrolments.edges
                    >,
                ),
            });
        } else {
            dispatchState({
                input: "dataProvider",
                value: new DataProvider((r1, r2) => {
                    return r1 !== r2;
                }),
            });
        }

        dispatchState({
            input: "refreshHandler",
            value: () => {
                dispatchState({ input: "contentIsRendered", value: false });
                refresh(
                    {
                        first: LOAD_X_ENROLMENTS,
                        searchTerm: state.values.searchTerm.trim(),
                        orderBy: state.values.orderBy,
                    },
                    {
                        fetchPolicy: "network-only",
                    },
                );
            },
        });

        dispatchState({
            input: "copyDataToClipboard",
            value: () => {
                Clipboard.setStringAsync(
                    waitingListHubTableCsvConverter(
                        enrolmentsData.enrolments?.edges ?? [],
                    ),
                );
                toast.show({
                    render: ({ id }: { id: string }) => (
                        <ToastAlert
                            id={id}
                            status="success"
                            title={"Copied Data to Clipboard"}
                            toast={toast}
                        />
                    ),
                });
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enrolmentsData]);

    const onPressEnrolment = useCallback(
        (enrolmentId: string) => {
            navigation.navigate("Enrolment", {
                enrolmentId: enrolmentId,
            });
        },
        [navigation],
    );

    const onPressSchool = useCallback(
        (schoolName: string | null) => {
            if (!schoolName) {
                return;
            }

            const schoolId = schoolsData.schools?.edges.find(
                (school) => school?.node?.name === schoolName,
            )?.node?.id;

            if (!schoolId) {
                return;
            }

            navigation.navigate("School", {
                schoolId: schoolId,
            });
        },
        [navigation, schoolsData.schools?.edges],
    );

    const rowRenderer = useCallback(
        (
            _: unknown,
            data: NonNullable<
                LoadEnrolments_query_enrolments$data["enrolments"]
            >["edges"][0],
            index: number,
        ): ReactElement | null => {
            if (data?.node) {
                return (
                    <Box
                        borderColor="surface.400"
                        borderLeftWidth={TABLE_BORDER_WIDTH}>
                        <WaitingListTableRow
                            cellProps={{
                                hideTopBorder: index === 0,
                                pressableTextProps: {
                                    fontFamily: "Poppins-Regular",
                                },
                                textProps: {
                                    fontSize: "md",
                                    textAlign: "center",
                                    fontFamily: "Poppins-Light",
                                },
                                px: 2,
                            }}
                            data={data.node}
                            flexArray={FLEX_ARRAY}
                            onPressEnrolment={onPressEnrolment}
                            onPressSchool={onPressSchool}
                            rowHeight={10}
                            tableBorderColor={TABLE_BORDER_COLOR}
                            tableBorderWidth={TABLE_BORDER_WIDTH}
                        />
                    </Box>
                );
            } else {
                return null;
            }
        },
        [onPressEnrolment, onPressSchool],
    );

    const renderFooter = useCallback(() => {
        return isLoadingNext ? (
            <CustomFlatListSpinner
                borderColor={TABLE_BORDER_COLOR}
                borderTopWidth={1}
                position="relative"
                pt="8"
                top="0"
            />
        ) : null;
    }, [isLoadingNext]);

    useEffect(() => {
        dispatchState({ input: "contentIsRendered", value: true });
        // unsubscribe from refetch on unmount
        return () => state.values.subscription?.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadEnrolmentsQueryReference]);

    const renderCount = useRef(0);
    const environment = useRelayEnvironment();
    const { colors } = useTheme();

    const refetchEnrolments = useCallback(
        (variables: {
            first: number;
            instrument?: string;
            lessonStage?: string;
            orderBy?: string;
            school?: string;
            schoolYear?: string;
            searchTerm?: string;
        }): Subscription | undefined => {
            dispatchState({ input: "isRefetching", value: true });

            const subscription = fetchQuery<LoadEnrolmentsQuery>(
                environment,
                load_enrolments,
                variables,
                { fetchPolicy: "store-or-network" },
            ).subscribe({
                complete: () => {
                    dispatchState({ input: "isRefetching", value: false });
                    refresh(variables, { fetchPolicy: "store-only" });
                },
                error: () => {
                    dispatchState({ input: "isRefetching", value: false });
                },
            });
            return subscription;
        },
        [dispatchState, environment, refresh],
    );

    useEffect(() => {
        if (renderCount.current < 2) {
            renderCount.current += 1;
            return;
        }

        dispatchState({ input: "isRefetching", value: false });
        state.values.subscription?.unsubscribe();

        // fire refetch immediately when orderBy or filters change
        dispatchState({
            input: "subscription",
            value: refetchEnrolments({
                first: LOAD_X_ENROLMENTS,
                searchTerm: state.values.searchTerm.trim(),
                orderBy: state.values.orderBy,
                school: state.values.school,
                lessonStage: state.values.lessonStageIndex ?? "",
                schoolYear: state.values.schoolYear ?? undefined,
                instrument: state.values.instrument ?? "",
            }),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        state.values.orderBy,
        state.values.school,
        state.values.lessonStageIndex,
        state.values.schoolYear,
        state.values.instrument,
    ]);

    useDebounceFunction(
        () => {
            if (renderCount.current < 2) {
                renderCount.current += 1;
                return;
            }

            dispatchState({ input: "isRefetching", value: false });
            state.values.subscription?.unsubscribe();

            // remove any applied sorting when we search (sort by relevance instead)
            // and reapply sorting if search text is blank
            const orderBy =
                state.values.searchTerm.trim() === ""
                    ? "pupilFirstName"
                    : undefined;

            dispatchState({ input: "orderBy", value: orderBy });
            navigation.setParams({ orderBy: orderBy });
            dispatchState({
                input: "subscription",
                value: refetchEnrolments({
                    first: LOAD_X_ENROLMENTS,
                    searchTerm: state.values.searchTerm.trim(),
                    orderBy: orderBy,
                    school: state.values.school,
                    lessonStage: state.values.lessonStageIndex ?? "",
                    schoolYear: state.values.schoolYear ?? undefined,
                    instrument: state.values.instrument ?? "",
                }),
            });
        }, // no delay if clearing text
        state.values.searchTerm !== "" ? 750 : 0,
        [state.values.searchTerm],
    );

    const { scale, width: windowWidth } = useWindowDimensions();

    useEffect(() => {
        // this allows the RLV to resize when the window resizes
        dispatchState({
            input: "layoutProvider",
            value: new LayoutProvider(
                () => "VSEL",
                (type, dim) => {
                    switch (type) {
                        case "VSEL":
                            dim.width = getScaledWindowDimension(
                                windowWidth,
                                scale,
                            );
                            dim.height = 40;
                            break;
                        default:
                            dim.width = 0;
                            dim.height = 0;
                    }
                },
            ),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowWidth, scale]);

    const onEndReached = useCallback(() => {
        if (hasNext && !isLoadingNext) {
            loadNext(LOAD_X_ENROLMENTS);
        }
    }, [hasNext, isLoadingNext, loadNext]);

    if (!state.values.contentIsRendered) {
        return <LoadingBlobs>Loading Enrolments...</LoadingBlobs>;
    }

    return (
        <Box flex={1} mx="30px">
            {state.values.dataProvider.getSize() > 0 &&
            state.values.layoutProvider ? (
                <RecyclerListView
                    canChangeSize
                    dataProvider={state.values.dataProvider}
                    layoutProvider={state.values.layoutProvider}
                    onEndReached={onEndReached}
                    onEndReachedThreshold={2000}
                    renderAheadOffset={800}
                    renderFooter={renderFooter}
                    rowRenderer={rowRenderer}
                    scrollViewProps={{
                        contentContainerStyle: {
                            borderColor:
                                // @ts-expect-error can't index with variable
                                colors?.[TABLE_BORDER_COLOR.split(".")[0]][
                                    TABLE_BORDER_COLOR.split(".")[1]
                                ],
                            borderBottomWidth:
                                (enrolmentsData.enrolments?.edges.length ??
                                    0 > 0) &&
                                !isLoadingNext
                                    ? TABLE_BORDER_WIDTH
                                    : 0,
                            marginBottom: 40,
                            overflow: "hidden",
                            borderBottomRadius: TABLE_BORDER_RADIUS,
                        },
                        showsVerticalScrollIndicator: false,
                    }}
                    style={{
                        flex: 1,
                        minHeight: 1,
                    }}
                    suppressBoundedSizeException
                    useWindowScroll
                />
            ) : (
                <ListEmptyBanner explainer={"No enrolments found..."}>
                    <Text fontSize="6xl">
                        {emoji.getUnicode("neutral_face")}
                    </Text>
                </ListEmptyBanner>
            )}
        </Box>
    );
};

const WaitingListHubScreen: FC<ScreenProps> = (props) => {
    const { navigation, route } = props;

    const { user } = useAuth();

    const { dataState } = useData();

    const toast = useToast();

    const initialState = useMemo(() => {
        return {
            values: {
                searchTerm: route.params?.searchTerm ?? "",
                orderBy: route.params?.orderBy ?? "positionInWaitingList,schoolName",
                contentIsRendered: false,
                isRefetching: false,
                bulkUploadEnrolmentsModalIsOpen: false,
                googleDriveNewBulkEnrolmentSheetUrl: "",
                showCreateSchoolModal: false,
                subscription: undefined,
                dataProvider: new DataProvider((r1, r2) => {
                    return r1 !== r2;
                }),
                layoutProvider: undefined,
                refreshHandler: undefined,
                school: route.params?.school,
                lessonStageIndex: route.params?.lessonStageIndex,
                schoolYear: route.params?.schoolYear,
                parentEmail: route.params?.parentEmail,
            },
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reducer = createReducer<ReducerValues, ReducerTypes>(initialState);
    const [state, dispatchState] = useReducer(reducer, initialState);

    const [loadEnrolmentsQueryReference, loadEnrolmentsQuery] =
        useQueryLoader<LoadEnrolmentsQuery>(load_enrolments);

    const [
        loadSchoolsForDropdownsQueryReference,
        loadSchoolsForDropdownsQuery,
    ] = useQueryLoader<LoadSchoolsForDropdownsQuery>(
        load_schools_for_dropdowns,
    );

    const [commitBulkUploadNewEnrolments] =
        useMutation<BulkUploadNewEnrolmentsMutation>(
            bulk_upload_new_enrolments,
        );

    useEffect(() => {
        loadSchoolsForDropdownsQuery(
            { orderBy: "name" },
            { fetchPolicy: "network-only" },
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!state.values.contentIsRendered) {
            loadEnrolmentsQuery(
                {
                    first: LOAD_X_ENROLMENTS,
                    searchTerm: state.values.searchTerm.trim(),
                    orderBy: state.values.orderBy,
                    school: state.values.school,
                    lessonStage: state.values.lessonStageIndex,
                    instrument: state.values.instrument,
                    schoolYear: state.values.schoolYear,
                },
                { fetchPolicy: "store-or-network" },
            );
        }
    }, [
        loadEnrolmentsQuery,
        state.values.contentIsRendered,
        state.values.orderBy,
        state.values.searchTerm,
        state.values.school,
        state.values.lessonStageIndex,
        state.values.schoolYear,
        state.values.instrument,
    ]);

    const inputChangeHandler = useCallback(
        (_: unknown, inputValue?: string) => {
            dispatchState({
                input: "searchTerm",
                value: inputValue,
            });
            navigation.setParams({ searchTerm: inputValue });
        },
        [navigation],
    );

    const inputClearHandler = useCallback(() => {
        if (state.values.searchTerm !== "") {
            dispatchState({
                input: "searchTerm",
                value: "",
            });
            navigation.setParams({ searchTerm: undefined });
        }
    }, [navigation, state.values.searchTerm]);

    const sortHandler = useCallback(
        (column: string) => {
            const orderBy = getOrderBy(column, state.values.orderBy, {
                onlyReverseOrderOfPrimarySort: true
            });
            dispatchState({
                input: "orderBy",
                value: orderBy,
            });
            navigation.setParams({
                orderBy: orderBy,
            });
        },
        [navigation, state.values.orderBy],
    );

    const tableHeaders = useMemo(() => {
        // these headers are used by all account types
        return [
            {
                data: "Position",
                onPress: () => sortHandler("positionInWaitingList,schoolName"),
                icon: sortIconExtractor(
                    "positionInWaitingList,schoolName",
                    state.values.orderBy,
                ),
            },
            {
                data: "Pupil Name",
                onPress: () => sortHandler("pupilFirstName,pupilLastName"),
                icon: sortIconExtractor(
                    "pupilFirstName,pupilLastName",
                    state.values.orderBy,
                ),
            },
            {
                data: "School Name",
                onPress: () => sortHandler("schoolName,positionInWaitingList"),
                icon: sortIconExtractor("schoolName,positionInWaitingList", state.values.orderBy),
            },
            {
                data: "Year Group",
            },
            {
                data: "Class",
            },
            {
                data: "Instrument",
            },
            {
                data: "Stage",
            },
            {
                data: "Notes",
            },
        ];
    }, [sortHandler, state.values.orderBy]);

    const schools = useMemo(() => {
        return dataState.values.schools?.edges ?? [];
    }, [dataState.values.schools?.edges]);

    const bulkUploadNewEnrolments = useCallback(
        (variables: { googleDriveNewBulkEnrolmentSheetUrl: string }) => {
            const bulkUploadNewPupilEnrolmentsConfig = {
                variables: {
                    input: {
                        googleDriveNewBulkEnrolmentsSheetUrl:
                            variables.googleDriveNewBulkEnrolmentSheetUrl,
                    },
                },
                onCompleted: (
                    response: BulkUploadNewEnrolmentsMutation$data,
                ) => {
                    if (response?.bulkUploadNewEnrolments?.success) {
                        // refresh on complete
                        dispatchState({
                            input: "contentIsRendered",
                            value: false,
                        });

                        state.values.refreshHandler?.();
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    id={id}
                                    status="success"
                                    title={
                                        "Successfuly uploaded enrolments to waiting list"
                                    }
                                    toast={toast}
                                />
                            ),
                        });
                    } else {
                        toast.show({
                            render: ({ id }: { id: string }) => (
                                <ToastAlert
                                    description={
                                        response?.bulkUploadNewEnrolments
                                            ?.errors[0].message
                                    }
                                    id={id}
                                    status="error"
                                    title={
                                        "Failed to upload enrolments to waiting list"
                                    }
                                    toast={toast}
                                />
                            ),
                            duration: null,
                        });
                    }
                },
            };

            commitBulkUploadNewEnrolments(bulkUploadNewPupilEnrolmentsConfig);
        },
        [commitBulkUploadNewEnrolments, state.values, toast],
    );

    const bulkUploadNewEnrolmentsHandler = useCallback(
        (googleDriveNewBulkEnrolmentSheetUrl?: string) => {
            if (
                googleDriveNewBulkEnrolmentSheetUrl ||
                state.values.googleDriveNewBulkEnrolmentSheetUrl
            ) {
                bulkUploadNewEnrolments({
                    googleDriveNewBulkEnrolmentSheetUrl:
                        (googleDriveNewBulkEnrolmentSheetUrl ??
                            state.values
                                .googleDriveNewBulkEnrolmentSheetUrl) as string,
                });
            }
            dispatchState({
                input: "bulkUploadEnrolmentsModalIsOpen",
                value: false,
            });
        },
        [
            bulkUploadNewEnrolments,
            state.values.googleDriveNewBulkEnrolmentSheetUrl,
        ],
    );

    const renderAlerts = useMemo(() => {
        return (
            <>
                <AlertPopup
                    alertIsOpen={state.values.bulkUploadEnrolmentsModalIsOpen}
                    body={
                        "Please enter the Google Drive URL of the new bulk enrolment V2 Google Sheet below. Ensure that it conforms to the bulk upload template format."
                    }
                    bodyStyle={{ _text: { fontSize: "md" }, mt: -5 }}
                    header="Bulk upload new pupil enrolments to waiting list"
                    setAlertIsOpen={() => {
                        dispatchState({
                            input: "bulkUploadEnrolmentsModalIsOpen",
                            value: false,
                        });
                        dispatchState({
                            input: "googleDriveCsvUrl",
                            value: undefined,
                        });
                    }}>
                    <VStack alignItems="center" space="8">
                        <Text fontSize="md" mt="-4" textAlign="center">
                            {
                                "How to prepare the bulk upload in Google Drive?\n"
                            }
                            <Text fontFamily="Poppins-Regular">
                                {"1. Make a copy of the "}
                                <Text
                                    color="primary.600"
                                    onPress={() =>
                                        window.open(
                                            "https://docs.google.com/spreadsheets/d/18bBzq38M8--Hf2bsQqZvg9uAPrdsfNXHltbbJjmNRwo/edit#gid=454040445",
                                            "_blank",
                                        )
                                    }
                                    textDecorationLine="underline">
                                    bulk upload template
                                </Text>
                                {
                                    ".\n2. Fill in the data.\n3. Copy the URL from the address bar into the box below."
                                }
                            </Text>
                        </Text>
                        <TextInput
                            autoCapitalize="none"
                            borderRadius="2xl"
                            fontFamily="Poppins-Regular"
                            fontSize="md"
                            id="googleDriveNewBulkEnrolmentSheetUrl"
                            keyboardType="default"
                            onFinishEditing={(_, inputValue) => {
                                dispatchState({
                                    input: "googleDriveNewBulkEnrolmentSheetUrl",
                                    value: inputValue,
                                });
                            }}
                            onSubmit={(_, inputValue) =>
                                bulkUploadNewEnrolmentsHandler(inputValue)
                            }
                            placeholder="Enter Google Drive URL"
                            textAlign="center"
                        />
                        <Button.Group
                            alignItems="center"
                            flex={1}
                            flexDirection="row"
                            justifyContent="center"
                            mb="4"
                            p="0"
                            size="lg"
                            space={4}>
                            <ButtonDebounced
                                _text={{ fontSize: "lg" }}
                                height="50"
                                isDisabled={
                                    !state.values
                                        .googleDriveNewBulkEnrolmentSheetUrl
                                }
                                minWidth="56"
                                onPress={() => bulkUploadNewEnrolmentsHandler()}
                                width="25%">
                                Bulk Upload
                            </ButtonDebounced>
                        </Button.Group>
                    </VStack>
                </AlertPopup>
            </>
        );
    }, [
        bulkUploadNewEnrolmentsHandler,
        state.values.bulkUploadEnrolmentsModalIsOpen,
        state.values.googleDriveNewBulkEnrolmentSheetUrl,
    ]);

    const renderHeader = useMemo(() => {
        return (
            <VStack mx="30px" pt="6" space="6">
                <HStack ml="1" space="5">
                    <WaitingListIcon color="primary.600" size="2xl" />
                    <Heading color="primary.600" fontSize="xl" ml="-2">
                        Waiting List
                    </Heading>
                </HStack>
                <VStack space="4">
                    <HStack justifyContent="space-between">
                        <VStack space="4">
                            <HStack alignItems="center" space="4">
                                <Box width="500px">
                                    <SearchBar
                                        inputClearHandler={inputClearHandler}
                                        inputOnChangeHandler={
                                            inputChangeHandler
                                        }
                                        inputSearchHandler={inputChangeHandler}
                                        placeholderText={
                                            "Search by pupil name, parent email or phone #"
                                        }
                                        searchText={state.values.searchTerm}
                                        showSearchIcon
                                    />
                                </Box>
                            </HStack>
                            <HStack alignItems="center" space="4">
                                <Select
                                    borderRadius="2xl"
                                    color={
                                        state.values.school
                                            ? "surface.900"
                                            : "muted.400"
                                    }
                                    fontSize="md"
                                    onValueChange={(itemValue) => {
                                        const parsedValue =
                                            itemValue !== "ANY"
                                                ? itemValue
                                                : undefined;
                                        dispatchState({
                                            input: "school",
                                            value: parsedValue,
                                        });
                                        navigation.setParams({
                                            school: parsedValue,
                                        });
                                    }}
                                    placeholder="Select school"
                                    py="2.5"
                                    selectedValue={state.values.school ?? "ANY"}
                                    width="260px">
                                    <Select.Item
                                        key={"ANY"}
                                        actionSheetLabel={"Any school"}
                                        value={"ANY"}
                                    />
                                    {schools.map((item) => {
                                        return (
                                            <Select.Item
                                                key={item?.node?.name}
                                                actionSheetLabel={
                                                    titleCaseConverter(
                                                        item?.node?.name,
                                                    ) ?? ""
                                                }
                                                value={item?.node?.name ?? ""}
                                            />
                                        );
                                    })}
                                </Select>
                                <Select
                                    bg="surface.100"
                                    borderRadius="2xl"
                                    color={
                                        state.values.lessonStageIndex
                                            ? "surface.900"
                                            : "muted.400"
                                    }
                                    fontSize="md"
                                    onValueChange={(itemValue) => {
                                        dispatchState({
                                            input: "lessonStageIndex",
                                            value: itemValue,
                                        });
                                        navigation.setParams({
                                            lessonStageIndex: itemValue,
                                        });
                                    }}
                                    placeholder="Select lesson stage"
                                    py="2.5"
                                    selectedValue={String(
                                        state.values.lessonStageIndex ?? "",
                                    )}
                                    width="160px">
                                    <Select.Item
                                        key={""}
                                        actionSheetLabel={"All stages"}
                                        value={""}
                                    />
                                    {LESSON_STAGES.map((item) => {
                                        return (
                                            <Select.Item
                                                key={item.value}
                                                actionSheetLabel={item.label}
                                                value={item.value}
                                            />
                                        );
                                    })}
                                </Select>
                                <Select
                                    bg="surface.100"
                                    borderRadius="2xl"
                                    color={
                                        state.values.schoolYear
                                            ? "surface.900"
                                            : "muted.400"
                                    }
                                    fontSize="md"
                                    onValueChange={(itemValue) => {
                                        dispatchState({
                                            input: "schoolYear",
                                            value: itemValue,
                                        });
                                        navigation.setParams({
                                            schoolYear: itemValue,
                                        });
                                    }}
                                    placeholder="Select school year"
                                    py="2.5"
                                    selectedValue={
                                        state.values.schoolYear?.toString() ??
                                        ""
                                    }
                                    width="184px">
                                    <Select.Item
                                        key={""}
                                        actionSheetLabel={"Any school year"}
                                        value={""}
                                    />
                                    {SCHOOL_YEARS.map((item) => {
                                        return (
                                            <Select.Item
                                                key={item.label}
                                                actionSheetLabel={item.label}
                                                value={item.value}
                                            />
                                        );
                                    })}
                                </Select>
                                <Select
                                    bg="surface.100"
                                    borderRadius="2xl"
                                    color={
                                        state.values.instrument
                                            ? "surface.900"
                                            : "muted.400"
                                    }
                                    fontSize="md"
                                    onValueChange={(itemValue) => {
                                        dispatchState({
                                            input: "instrument",
                                            value: itemValue,
                                        });
                                        navigation.setParams({
                                            instrument: itemValue,
                                        });
                                    }}
                                    placeholder="Select instrument"
                                    py="2.5"
                                    selectedValue={
                                        state.values.instrument ?? ""
                                    }
                                    width="180px">
                                    <Select.Item
                                        key={""}
                                        actionSheetLabel={"All instruments"}
                                        value={""}
                                    />
                                    {INSTRUMENTS.map((item) => {
                                        return (
                                            <Select.Item
                                                key={item.label}
                                                actionSheetLabel={item.label}
                                                value={item.value}
                                            />
                                        );
                                    })}
                                </Select>
                                <Button
                                    bg="surface.400"
                                    colorScheme="surface"
                                    leftIcon={<RestartIcon size="5" />}
                                    onPress={state.values.refreshHandler}
                                    p="3"
                                />
                            </HStack>
                        </VStack>
                        <VStack alignItems="flex-end" space="4">
                            <Button
                                _hover={{ bg: "primary.500" }}
                                _pressed={{ bg: "primary.600" }}
                                _text={{ fontSize: "17" }}
                                bg="primary.400"
                                leftIcon={<CopyIcon size="md" />}
                                onPress={() => {
                                    dispatchState({
                                        input: "bulkUploadEnrolmentsModalIsOpen",
                                        value: true,
                                    });
                                }}
                                px="4"
                                shadow={1}>
                                {"Bulk Upload Pupils To Waiting List"}
                            </Button>
                            <Button
                                _hover={{ bg: "primary.500" }}
                                _pressed={{ bg: "primary.600" }}
                                _text={{ fontSize: "17" }}
                                bg="primary.400"
                                leftIcon={<CopyIcon size="md" />}
                                onPress={state.values.copyDataToClipboard}
                                px="4"
                                shadow={1}>
                                Copy Table to Clipboard
                            </Button>
                        </VStack>
                    </HStack>
                </VStack>
                <Box bg="surface.100">
                    <Box
                        bg="primary.50"
                        borderColor={TABLE_BORDER_COLOR}
                        borderTopRadius={TABLE_BORDER_RADIUS}
                        borderWidth={TABLE_BORDER_WIDTH}>
                        <Row
                            cellProps={{
                                px: 2,
                                hideTopBorder: true,
                                hideOuterSideBorder: true,
                                textProps: {
                                    fontSize: "md",
                                    textAlign: "center",
                                    isTruncated: true,
                                },
                            }}
                            data={tableHeaders}
                            flexArray={FLEX_ARRAY}
                            rowHeight={10}
                            rowIndex={0}
                            tableBorderColor={TABLE_BORDER_COLOR}
                            tableBorderWidth={TABLE_BORDER_WIDTH}
                        />
                    </Box>
                </Box>
            </VStack>
        );
    }, [
        inputClearHandler,
        inputChangeHandler,
        state.values.searchTerm,
        state.values.school,
        state.values.lessonStageIndex,
        state.values.schoolYear,
        state.values.instrument,
        state.values.refreshHandler,
        state.values.copyDataToClipboard,
        schools,
        tableHeaders,
        navigation,
    ]);

    const renderRefetchIndicator = useMemo(() => {
        return state.values.isRefetching ? (
            <CustomFlatListSpinner top="270" />
        ) : null;
    }, [state.values.isRefetching]);

    if (!user?.profile?.id) {
        return null;
    }

    return (
        <Box bg="surface.100" flex={1} pt="70">
            {renderHeader}
            {loadEnrolmentsQueryReference &&
            loadSchoolsForDropdownsQueryReference ? (
                <Suspense
                    fallback={
                        <LoadingBlobs>Loading Enrolments...</LoadingBlobs>
                    }>
                    <WaitingListContent
                        dispatchState={dispatchState}
                        loadEnrolmentsQueryReference={
                            loadEnrolmentsQueryReference
                        }
                        loadSchoolsForDropdownsQueryReference={
                            loadSchoolsForDropdownsQueryReference
                        }
                        navigation={navigation}
                        state={state}
                    />
                </Suspense>
            ) : (
                <LoadingBlobs>Loading Enrolments...</LoadingBlobs>
            )}
            {renderAlerts}
            {renderRefetchIndicator}
        </Box>
    );
};

export const screenOptions = {
    headerTitle: "Waiting List",
};

export default WaitingListHubScreen;
