import { graphql } from "react-relay";

export const dismiss_mutation_progress_indicator = graphql`
    mutation DismissMutationProgressIndicatorMutation(
        $input: DismissMutationProgressIndicatorInput!
    ) {
        dismissMutationProgressIndicator(input: $input) {
            success
            errors
            profileGroup {
                id
                generateDraftsProgress
                sendDraftsProgress
                sendRemindersProgress
                matchPaymentsProgress
                generateGoogleRegistersProgress
                readInRegistersProgress
                bulkUploadNewRegistersProgress
                generateRegistersForNewBlockProgress
                sendBulkCommunicationProgress
                sendTeacherBulkCommunicationProgress
            }
        }
    }
`;
