import { graphql } from "react-relay";

export const load_mutation_progress = graphql`
    query LoadMutationProgressQuery(
        $profileId: ID!
        $startingYear: Int!
        $block: Int!
        $skipInvoiceTotals: Boolean!
    ) {
        profile(id: $profileId) {
            id @skip(if: $skipInvoiceTotals)
            totalInvoicesSent(startingYear: $startingYear, block: $block)
                @skip(if: $skipInvoiceTotals)
            totalAmountPaid(startingYear: $startingYear, block: $block)
                @skip(if: $skipInvoiceTotals)
            totalInvoicesUnpaid(startingYear: $startingYear, block: $block)
            profileGroup {
                id
                generateDraftsProgress
                sendDraftsProgress
                sendRemindersProgress
                matchPaymentsProgress
                generateGoogleRegistersProgress
                readInRegistersProgress
                bulkUploadNewRegistersProgress
                generateRegistersForNewBlockProgress
                sendBulkCommunicationProgress
                sendTeacherBulkCommunicationProgress
            }
        }
    }
`;
