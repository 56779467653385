import React, { useMemo } from "react";
import type { ReactElement } from "react";

import type { CellProps } from "pianofunclub-shared/components/Base/Cell";
import Row from "pianofunclub-shared/components/Base/Row";

import type { LoadEnrolments_query_enrolments$data } from "pianofunclub-shared/relay/graphql/accounts/__generated__/LoadEnrolments_query_enrolments.graphql";

import {
    schoolYearConverter,
    combineWaitingListNotes,
} from "pianofunclub-shared/utils/converters";
import { getFullName } from "pianofunclub-shared/utils/extractors";

interface Props {
    cellProps?: CellProps;
    data: NonNullable<
        NonNullable<
            NonNullable<
                LoadEnrolments_query_enrolments$data["enrolments"]
            >["edges"][0]
        >["node"]
    >;
    flexArray: number[];
    onPressEnrolment: (enrolmentId: string) => void;
    onPressSchool: (enrolmentId: string | null) => void;
    rowHeight?: number;
    tableBorderColor?: string;
    tableBorderWidth?: number;
}

const WaitingListTableRow = (props: Props): ReactElement => {
    const {
        cellProps,
        data,
        flexArray,
        onPressEnrolment,
        onPressSchool,
        rowHeight,
        tableBorderColor,
        tableBorderWidth,
    } = props;

    const rowData = useMemo(() => {
        return [
            {
                data: data.positionInWaitingList ?? "",
            },
            {
                data: getFullName(data.pupilFirstName, data.pupilLastName),
                onPress: () => onPressEnrolment(data.id),
                tooltipLabel: `Parent Info:\n${[
                    data.parentName,
                    data.parentEmail,
                    data.parentPhoneNumber,
                ]
                    .filter(Boolean)
                    .join("\n")}`,
            },
            {
                data: data.schoolName ?? "",
                onPress: () => onPressSchool(data.schoolName),
            },
            {
                data: schoolYearConverter(data.schoolYear),
            },
            {
                data: data.schoolClass ?? "",
            },
            {
                data: data.instrument ?? "",
            },
            {
                data:
                    data.lessonStage == "-1"
                        ? "Any Stage"
                        : (data.lessonStage ?? ""),
            },
            {
                data: data.pupilNotes
                    ? data.pupilNotes
                    : data.adminNotes
                      ? data.adminNotes
                      : data.lessonBlockNotes
                        ? data.lessonBlockNotes
                        : "",
                tooltipLabel: combineWaitingListNotes(
                    data.pupilNotes,
                    data.adminNotes,
                    data.lessonBlockNotes,
                ),
            },
        ];
    }, [
        data.adminNotes,
        data.id,
        data.instrument,
        data.lessonBlockNotes,
        data.lessonStage,
        data.parentEmail,
        data.parentName,
        data.parentPhoneNumber,
        data.positionInWaitingList,
        data.pupilFirstName,
        data.pupilLastName,
        data.pupilNotes,
        data.schoolClass,
        data.schoolName,
        data.schoolYear,
        onPressEnrolment,
        onPressSchool,
    ]);

    return (
        <Row
            cellProps={cellProps}
            data={rowData}
            flexArray={flexArray}
            rowHeight={rowHeight}
            rowIndex={0}
            tableBorderColor={tableBorderColor}
            tableBorderWidth={tableBorderWidth}
        />
    );
};

export default WaitingListTableRow;
