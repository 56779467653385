import React, {
    useCallback,
    useState,
    useEffect,
    useRef,
    useImperativeHandle,
    forwardRef,
} from "react";
import type { ReactElement, Dispatch } from "react";

import {
    Text,
    Pressable,
    VStack,
    Spinner,
    Center,
    Button,
    PresenceTransition,
    Box,
} from "native-base";
import {
    usePreloadedQuery,
    useMutation,
    useRelayEnvironment,
    fetchQuery,
} from "react-relay";
import type {
    PreloadedQuery,
    UseQueryLoaderLoadQueryOptions,
} from "react-relay";

import { useAuth } from "pianofunclub-shared/providers/AuthProvider";

import { CloseIcon } from "pianofunclub-shared/components/Other/Icons";

import type { DismissMutationProgressIndicatorMutation } from "pianofunclub-shared/relay/graphql/general/__generated__/DismissMutationProgressIndicatorMutation.graphql";
import type {
    LoadMutationProgressQuery,
    LoadMutationProgressQuery$variables,
} from "pianofunclub-shared/relay/graphql/general/__generated__/LoadMutationProgressQuery.graphql";
import { dismiss_mutation_progress_indicator } from "pianofunclub-shared/relay/graphql/general/DismissMutationProgressIndicator";
import { load_mutation_progress } from "pianofunclub-shared/relay/graphql/general/LoadMutationProgress";

import type { Action, State } from "pianofunclub-shared/utils/reducers";

import type {
    ReducerValues as RegistersHubReducerValues,
    ReducerTypes as RegistersHubReducerTypes,
} from "pianofunclub-crm/screens/registers/RegistersHubScreen";

export interface RegisterMutationProgressIndicatorsRef {
    refreshMutationProgress: () => void;
}

interface Props {
    dispatchState: Dispatch<
        Action<RegistersHubReducerValues, RegistersHubReducerTypes>
    >;
    loadMutationProgressQuery: (
        variables: LoadMutationProgressQuery$variables,
        options?: UseQueryLoaderLoadQueryOptions | undefined,
    ) => void;
    loadMutationProgressQueryReference: PreloadedQuery<
        LoadMutationProgressQuery,
        Record<string, unknown>
    >;
    state: State<RegistersHubReducerValues>;
}

const RegisterMutationProgressIndicators = forwardRef<
    RegisterMutationProgressIndicatorsRef,
    Props
>((props, ref): ReactElement => {
    const {
        dispatchState,
        loadMutationProgressQuery,
        loadMutationProgressQueryReference,
        state,
    } = props;

    const { user } = useAuth();

    const mutationProgressData = usePreloadedQuery(
        load_mutation_progress,
        loadMutationProgressQueryReference,
    );

    const [isRefetching, setIsRefetching] = useState(false);

    const environment = useRelayEnvironment();

    const refreshMutationProgress = useCallback(
        (options?: { doNotShowLoadingIndicator?: boolean }) => {
            if (isRefetching) {
                return;
            }

            if (!options?.doNotShowLoadingIndicator) {
                setIsRefetching(true);
            }

            if (user?.profile?.id) {
                fetchQuery<LoadMutationProgressQuery>(
                    environment,
                    load_mutation_progress,
                    {
                        profileId: user.profile.id,
                        startingYear: state.values.startingYear,
                        block: state.values.block,
                        skipInvoiceTotals: true,
                    },
                    { fetchPolicy: "network-only" },
                ).subscribe({
                    complete: () => {
                        setIsRefetching(false);
                        if (user?.profile?.id) {
                            loadMutationProgressQuery(
                                {
                                    profileId: user.profile.id,
                                    startingYear: state.values.startingYear,
                                    block: state.values.block,
                                    skipInvoiceTotals: true,
                                },
                                {
                                    // @ts-expect-error relay typing error
                                    fetchPolicy: "store-only",
                                },
                            );
                        }
                    },
                    error: () => {
                        setIsRefetching(false);
                    },
                });
            }
        },
        [
            environment,
            isRefetching,
            loadMutationProgressQuery,
            state.values.block,
            state.values.startingYear,
            user?.profile?.id,
        ],
    );

    useImperativeHandle(ref, () => ({
        refreshMutationProgress: () => {
            refreshMutationProgress({ doNotShowLoadingIndicator: true });
        },
    }));

    const commitDismissMutationProgressIndicator =
        useMutation<DismissMutationProgressIndicatorMutation>(
            dismiss_mutation_progress_indicator,
        )[0];

    const dismissMutationProgressIndicator = useCallback(
        (variables: {
            dismissBulkUploadNewRegistersIndicator?: boolean;
            dismissGenerateGoogleRegistersIndicator?: boolean;
            dismissGenerateRegistersForNewBlockIndicator?: boolean;
            dismissReadInRegistersIndicator?: boolean;
            dismissTeacherBulkCommunicationProgressIndicator?: boolean;
        }) => {
            const dismissMutationProgressIndicatorConfig = {
                variables: {
                    input: variables,
                },
                optimisticResponse: {
                    dismissMutationProgressIndicator: {
                        success: true,
                        errors: null,
                        profileGroup: {
                            id: user?.profile?.profileGroup?.id,
                            generateGoogleRegistersProgress:
                                variables.dismissGenerateGoogleRegistersIndicator
                                    ? null
                                    : mutationProgressData.profile?.profileGroup
                                          ?.generateGoogleRegistersProgress,
                            readInRegistersProgress:
                                variables.dismissReadInRegistersIndicator
                                    ? null
                                    : mutationProgressData.profile?.profileGroup
                                          ?.readInRegistersProgress,
                            bulkUploadNewRegistersProgress:
                                variables.dismissBulkUploadNewRegistersIndicator
                                    ? null
                                    : mutationProgressData.profile?.profileGroup
                                          ?.bulkUploadNewRegistersProgress,
                            generateRegistersForNewBlockProgress:
                                variables.dismissGenerateRegistersForNewBlockIndicator
                                    ? null
                                    : mutationProgressData.profile?.profileGroup
                                          ?.generateRegistersForNewBlockProgress,
                            sendTeacherBulkCommunicationProgress:
                                variables.dismissTeacherBulkCommunicationProgressIndicator
                                    ? null
                                    : mutationProgressData.profile?.profileGroup
                                          ?.sendTeacherBulkCommunicationProgress,
                        },
                    },
                },
            };
            commitDismissMutationProgressIndicator(
                dismissMutationProgressIndicatorConfig,
            );
        },
        [
            commitDismissMutationProgressIndicator,
            mutationProgressData.profile?.profileGroup
                ?.bulkUploadNewRegistersProgress,
            mutationProgressData.profile?.profileGroup
                ?.generateGoogleRegistersProgress,
            mutationProgressData.profile?.profileGroup
                ?.generateRegistersForNewBlockProgress,
            mutationProgressData.profile?.profileGroup?.readInRegistersProgress,
            mutationProgressData.profile?.profileGroup
                ?.sendTeacherBulkCommunicationProgress,
            user?.profile?.profileGroup?.id,
        ],
    );

    const renderIndicator = useCallback(
        ({
            errorMessage,
            loadingMessage,
            onDismiss,
            progress,
            showIndicatorState,
            successMessage,
        }: {
            errorMessage: string;
            loadingMessage: string;
            onDismiss: () => void;
            progress: number | null | undefined;
            showIndicatorState: boolean;
            successMessage: string;
        }) => {
            if (typeof progress === "number" || showIndicatorState) {
                return (
                    <PresenceTransition
                        animate={{
                            opacity: 1,
                            scale: 1,
                            transition: {
                                duration: 250,
                            },
                        }}
                        initial={{
                            opacity: 0,
                            scale: 0,
                        }}
                        visible>
                        <Pressable
                            bg={
                                progress === 100
                                    ? "primary.700"
                                    : // progress of -1 signifies error
                                      (progress ?? 0) < 0
                                      ? "red.500"
                                      : "primary.500"
                            }
                            borderRadius="xl"
                            flexDirection="row"
                            onPress={() => refreshMutationProgress()}
                            shadow={1}>
                            <Text color="surface.100" pl="3" py="2">
                                {progress === 100
                                    ? successMessage
                                    : (progress ?? 0) < 0
                                      ? errorMessage
                                      : loadingMessage}
                            </Text>
                            {(progress ?? 0) >= 0 ? (
                                <Center pl="1" pr="3" py="2" width="65px">
                                    {isRefetching ? (
                                        <Spinner color="surface.100" />
                                    ) : (
                                        <Text color="surface.100">
                                            {"(" + (progress ?? 0) + "%)"}
                                        </Text>
                                    )}
                                </Center>
                            ) : (
                                <Box pr="3" />
                            )}
                        </Pressable>
                        <Button
                            bg="surface.900"
                            borderRadius="full"
                            leftIcon={
                                <CloseIcon color="surface.100" size="3" />
                            }
                            onPress={onDismiss}
                            p="1"
                            position="absolute"
                            right="-7"
                            shadow={1}
                            top="-7"
                            variant="dark"
                            zIndex={2}
                        />
                    </PresenceTransition>
                );
            } else {
                return null;
            }
        },
        [isRefetching, refreshMutationProgress],
    );

    // stop effects firing on first render
    const renderCount = useRef(0);

    // this series of effects closes loading indicator when progress finishes
    useEffect(() => {
        if (renderCount.current < 4) {
            renderCount.current += 1;
            return;
        }

        if (
            mutationProgressData.profile?.profileGroup
                ?.generateGoogleRegistersProgress === null
        ) {
            dispatchState({
                input: "showMutationProgressIndicators",
                value: {
                    ...state.values.showMutationProgressIndicators,
                    generateGoogleRegisters: false,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        mutationProgressData.profile?.profileGroup
            ?.generateGoogleRegistersProgress,
    ]);

    useEffect(() => {
        if (renderCount.current < 4) {
            renderCount.current += 1;
            return;
        }

        if (
            mutationProgressData.profile?.profileGroup
                ?.readInRegistersProgress === null
        ) {
            dispatchState({
                input: "showMutationProgressIndicators",
                value: {
                    ...state.values.showMutationProgressIndicators,
                    readInRegisters: false,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mutationProgressData.profile?.profileGroup?.readInRegistersProgress]);

    useEffect(() => {
        if (renderCount.current < 4) {
            renderCount.current += 1;
            return;
        }

        if (
            mutationProgressData.profile?.profileGroup
                ?.bulkUploadNewRegistersProgress === null
        ) {
            dispatchState({
                input: "showMutationProgressIndicators",
                value: {
                    ...state.values.showMutationProgressIndicators,
                    bulkUploadNewRegisters: false,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        mutationProgressData.profile?.profileGroup
            ?.bulkUploadNewRegistersProgress,
    ]);

    useEffect(() => {
        if (renderCount.current < 4) {
            renderCount.current += 1;
            return;
        }

        if (
            mutationProgressData.profile?.profileGroup
                ?.generateRegistersForNewBlockProgress === null
        ) {
            dispatchState({
                input: "showMutationProgressIndicators",
                value: {
                    ...state.values.showMutationProgressIndicators,
                    generateRegistersForNewBlock: false,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        mutationProgressData.profile?.profileGroup
            ?.generateRegistersForNewBlockProgress,
    ]);

    return (
        <VStack bottom="5" position="absolute" right="5" space="4">
            {renderIndicator({
                progress:
                    mutationProgressData.profile?.profileGroup
                        ?.generateRegistersForNewBlockProgress,
                showIndicatorState:
                    state.values.showMutationProgressIndicators
                        .generateRegistersForNewBlock,
                successMessage: "Generated New Registers",
                errorMessage: "Error Generating New Registers",
                loadingMessage: "Generating New Registers",
                onDismiss: () => {
                    dismissMutationProgressIndicator({
                        dismissGenerateRegistersForNewBlockIndicator: true,
                    });
                    dispatchState({
                        input: "showMutationProgressIndicators",
                        value: {
                            ...state.values.showMutationProgressIndicators,
                            generateRegistersForNewBlock: false,
                        },
                    });
                },
            })}
            {renderIndicator({
                progress:
                    mutationProgressData.profile?.profileGroup
                        ?.bulkUploadNewRegistersProgress,
                showIndicatorState:
                    state.values.showMutationProgressIndicators
                        .bulkUploadNewRegisters,
                successMessage: "Uploaded New Registers",
                errorMessage: "Error Uploading New Registers",
                loadingMessage: "Uploading New Registers",
                onDismiss: () => {
                    dismissMutationProgressIndicator({
                        dismissBulkUploadNewRegistersIndicator: true,
                    });
                    dispatchState({
                        input: "showMutationProgressIndicators",
                        value: {
                            ...state.values.showMutationProgressIndicators,
                            bulkUploadNewRegisters: false,
                        },
                    });
                },
            })}
            {renderIndicator({
                progress:
                    mutationProgressData.profile?.profileGroup
                        ?.readInRegistersProgress,
                showIndicatorState:
                    state.values.showMutationProgressIndicators.readInRegisters,
                successMessage: "Read in Registers",
                errorMessage: "Error Reading In Registers",
                loadingMessage: "Reading In Registers",
                onDismiss: () => {
                    dismissMutationProgressIndicator({
                        dismissReadInRegistersIndicator: true,
                    });
                    dispatchState({
                        input: "showMutationProgressIndicators",
                        value: {
                            ...state.values.showMutationProgressIndicators,
                            readInRegisters: false,
                        },
                    });
                },
            })}
            {renderIndicator({
                progress:
                    mutationProgressData.profile?.profileGroup
                        ?.generateGoogleRegistersProgress,
                showIndicatorState:
                    state.values.showMutationProgressIndicators
                        .generateGoogleRegisters,
                successMessage: "Generated Registers",
                errorMessage: "Error Generating Registers",
                loadingMessage: "Generating Registers",
                onDismiss: () => {
                    dismissMutationProgressIndicator({
                        dismissGenerateGoogleRegistersIndicator: true,
                    });
                    dispatchState({
                        input: "showMutationProgressIndicators",
                        value: {
                            ...state.values.showMutationProgressIndicators,
                            generateGoogleRegisters: false,
                        },
                    });
                },
            })}
            {renderIndicator({
                progress:
                    mutationProgressData.profile?.profileGroup
                        ?.sendTeacherBulkCommunicationProgress,
                showIndicatorState:
                    state.values.showMutationProgressIndicators.sendTeacherBulkCommunication,
                successMessage: "Sent Teacher Messages",
                errorMessage: "Error Sending Teacher Messages",
                loadingMessage: "Sending Teacher Messages",
                onDismiss: () => {
                    dismissMutationProgressIndicator({
                        dismissTeacherBulkCommunicationProgressIndicator: true,
                    });
                    dispatchState({
                        input: "showMutationProgressIndicators",
                        value: {
                            ...state.values.showMutationProgressIndicators,
                            sendTeacherBulkCommunication: false,
                        },
                    });
                },
            })}
        </VStack>
    );
});

export default React.memo(RegisterMutationProgressIndicators);
