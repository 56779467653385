import { graphql } from "react-relay";

export const update_line_item_amount = graphql`
    mutation UpdateLineItemAmountMutation($input: UpdateLineItemAmountInput!) {
        updateLineItemAmount(input: $input) {
            success
            errors
            lineItem {
                id
                itemType
                quantity
                unitAmount
                timePeriodDescription
                pupilDescription
                pupilDescriptionId
                instrumentDescription
                typeDescription
                pupil {
                    id
                }
            }
            invoice {
                id
                lastModified
                totalAmount
                status
            }
            accountFeeOrCredit {
                id
                amount
            }
        }
    }
`;
